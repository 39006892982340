import { useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
//import 'dayjs/locale/en-au';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
//import Checkbox from '@mui/material/Checkbox';
import { FormContext } from "./ManageJobs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { checkPermission, getSessionItem } from '../../common';
import { NumericFormat } from 'react-number-format';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
//import * as moment from 'moment'

/* const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
 */
const JobForm = () => {

    const _role = getSessionItem("_role");

    const { loaderText, dialogOpen, handleDialogClose, serviceUsersOptions, jobStatusOptions, scheduleStatusOptions, formField, setFormField, onSubmit, jobData, lookUpsOptions, setFormChangeFlag } = useContext(FormContext);
    //const [startDate, setStartDate] = useState(dayjs(new Date()));
    //const [endDate, setEndDate] = useState(dayjs(new Date()));

    //const electriciansData = serviceUsersOptions ? serviceUsersOptions.filter(a => a.code === "E-TRADIE") : [];
    //const plumbersData = serviceUsersOptions ? serviceUsersOptions.filter(a => a.code === "P-TRADIE") : [];

    //const [technicianInput, setTechnicianInput] = useState(formField.technician_name);

    const actionFun = formField.action;

    const service_type = (formField.service_type === 'ESA' || formField.service_type === 'ES' || formField.service_type === 'SA') ? 'E-TRADIE' : 'P-TRADIE';

    const { reset, handleSubmit, register, formState: { errors }, control } = useForm();

    useEffect(() => {

        //if(formField.property_id){        
        reset(formField);
        //} 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            }
        }

    };

    const getSelectedItem = (label) => {
        // eslint-disable-next-line
        const item = jobStatusOptions.find((opt) => {
            if (opt && opt.label === label) {
                return opt;
            };

        })

        return item || null;
    }

    const getSelectedScheduleStatus = (label) => {
        // eslint-disable-next-line
        const item = scheduleStatusOptions.find((opt) => {
            if (opt && opt.label === label) {
                return opt;
            };

        })

        return item || null;
    }

    const getProductSelectedItem = (id) => {
        // eslint-disable-next-line
        const item = lookUpsOptions.PRODUCT.filter(a => id && a.id === parseInt(id)).find((opt) => {
            return opt;
        });

        return item || null;
    }

    const getSelectedTechnician = (id, code) => {
        // eslint-disable-next-line
        const item = serviceUsersOptions.filter(a => a.code === code).find((opt) => {

            if (opt && opt.id === id) {

                return opt;
            };

        });


        return item || null;
    }

    const filterStatus = (a) => {
        if (_role === 'SYS' || _role === 'MG') {
            if (/Approved/.test(formField.job_status) && /Approved|Closed|Lead/.test(a.label)) {
                return a
            } else if (/Unassigned|Cancelled|Reschedule/.test(formField.job_status) && /Unassigned|Assigned|Cancelled|Reschedule/.test(a.label)) {
                return a
            } else if (/Assigned/.test(formField.job_status) && /Unassigned|Assigned|Completed - Pending/.test(a.label)) {
                return a
            } else if (/Completed - Pending/.test(formField.job_status) && /Assigned|Completed/.test(a.label)) {
                return a
            } else if (/Closed|Lead/.test(formField.job_status) && /Closed|Lead/.test(a.label)) {
                return a
            }
        } else {
            if (/Unassigned/.test(formField.job_status) && /Assigned/.test(a.label)) {
                return a
            } else if (/Assigned|Completed - Pending/.test(formField.job_status) && /Completed - Pending|Assigned/.test(a.label)) {
                return a
            }
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={dialogOpen}
            onClose={handleDialogClose}
            scroll='body'
        >
            <DialogTitle id="scroll-dialog-title">{actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Job for <strong>{(jobData.service.property.address1 ? jobData.service.property.address1 : '') + ' ' + (jobData.service.property.address2 ? jobData.service.property.address2 : '')}</strong></DialogTitle>
            <Box component="form" id="job-form" name="job-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                <DialogContent dividers={false}>
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="mb-3" style={{ textAlign: 'left' }}>Job/Technician Info:</h6>
                            <div className='d-flex'>
                                <Controller
                                    control={control}
                                    name="job_date"
                                    defaultValue={dayjs(formField.job_date)}
                                    rules={{ required: 'Job Date is required' }}
                                    render={({ field, }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    disablePast
                                                    label="Job Date *"
                                                    format="DD/MM/YYYY"
                                                    value={dayjs(formField?.job_date)}
                                                    defaultValue={dayjs(formField?.job_date)}
                                                    onChange={(newValue) => { field.onChange(newValue); setFormField((prev) => ({ ...prev, job_date: newValue })); setFormChangeFlag(true); }}
                                                    sx={{ marginRight: '15px' }}
                                                    slotProps={{
                                                        textField: {
                                                            error: errors.job_date ? true : false,
                                                            helperText: errors.job_date?.message,

                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>);
                                    }}
                                />

                                <FormControl sx={{ width: '200px' }}>
                                    <InputLabel id="jobtime-select-label"> Select Job Time </InputLabel>
                                    <Select
                                        labelId="jobtime-select-label"
                                        className="text-start"
                                        id="job_time"
                                        name="job_time"
                                        error={errors.job_time ? true : false}
                                        value={formField.job_time ? formField.job_time : ''}
                                        label="Select Job Time *"
                                        {...register("job_time", { required: "Job Time is required" })}
                                        onChange={(e) => { setFormField((prev) => ({ ...prev, job_time: e.target.value })); setFormChangeFlag(true); }}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            (formField.job_time !== '07AM - 12PM' && formField.job_time !== '12PM - 05PM') && <MenuItem value={formField.job_time}>{formField.job_time}</MenuItem>
                                        }
                                        <MenuItem value='07AM - 12PM'>07AM - 12PM</MenuItem>
                                        <MenuItem value='12PM - 05PM'>12PM - 05PM</MenuItem>
                                    </Select>
                                    <label className="w-full error text-red text-sm text-start">{errors.job_time?.message}</label>

                                </FormControl>
                            </div>
                            {checkPermission('JOB', 'all') &&
                                <Controller
                                    name="technician_id"
                                    control={control}
                                    rules={{
                                        required: /Unassigned|Cancelled|Rescheduled/.test(formField.job_status) ? false : "Technician is required"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            readOnly={(_role === 'SYS' || _role === 'MG') ? false : true}
                                            id="technician_id"
                                            options={serviceUsersOptions.filter(a => (a.code === service_type || !formField.service_type))}
                                            getOptionLabel={(option) => option.label}

                                            sx={{ width: '100%', marginTop: '20px' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                            renderInput={(params) => <TextField {...params} label="Select Technician *" variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}

                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.label : '')
                                                setFormField((prev) => ({ ...prev, technician_id: data ? data.id : '', technician_name: data ? data.label : '' }));
                                                setFormChangeFlag(true);
                                            }}

                                            value={getSelectedTechnician(formField.technician_id, service_type)}
                                        />
                                    )}
                                />
                            }

                            {checkPermission('JOB', 'edit') &&
                                <Controller
                                    name="job_status"
                                    control={control}
                                    rules={{
                                        required: "Job Status is required"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="job_status"
                                            options={jobStatusOptions.filter(a => filterStatus(a))}
                                            getOptionLabel={(option) => option.label}
                                            sx={{ width: '100%', marginTop: '20px' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                            renderInput={(params) => <TextField {...params} label="Select Job Status *" variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}

                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.label : '')
                                                setFormField((prev) => ({ ...prev, job_status: data ? data.label : '' }));
                                                setFormChangeFlag(true);
                                            }}

                                            value={getSelectedItem(formField?.job_status)}
                                        />
                                    )}
                                />
                            }

                            {checkPermission('JOB', 'all') && <textarea
                                id="job_comments"
                                name="job_comments"
                                rows={2}
                                style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%', minHeight: '100px' }}
                                placeholder="Job Comments"
                                {...register("job_comments", { required: false })}
                                onChange={(e) => { setFormField((prev) => ({ ...prev, job_comments: e.target.value })); setFormChangeFlag(true); }}

                                defaultValue={formField.job_comments ? formField.job_comments : ''}

                            />}

                        </div>
                        <div className="col-md-6">
                            <h6 className="mb-3" style={{ textAlign: 'left' }}>Schedule / Product:</h6>
                            {checkPermission('JOB', 'all') && <>
                                <Controller
                                    name="schedule_status"
                                    control={control}
                                    rules={{
                                        required: "Schedule Status is required"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="schedule_status"
                                            options={scheduleStatusOptions}
                                            getOptionLabel={(option) => option.label}
                                            sx={{ width: '100%' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                            renderInput={(params) => <TextField {...params} label="Select Schedule Status *" variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}

                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.label : '')
                                                setFormField((prev) => ({ ...prev, schedule_status: data ? data.label : '' }));
                                                setFormChangeFlag(true);
                                            }}

                                            value={formField.schedule_status ? getSelectedScheduleStatus(formField?.schedule_status) : null}
                                        />
                                    )}
                                />

                                <textarea
                                    id="schedule_comments"
                                    name="schedule_comments"
                                    rows={2}
                                    style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%', minHeight: '100px' }}
                                    placeholder="Schedule Comments"
                                    {...register("schedule_comments", { required: false })}
                                    onChange={(e) => { setFormField((prev) => ({ ...prev, schedule_comments: e.target.value })); setFormChangeFlag(true); }}

                                    defaultValue={formField.schedule_comments ? formField.schedule_comments : ''}

                                />
                            </>
                            }
                            {checkPermission('JOB', 'all') ? <>
                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    disabled={/Assigned|Completed/.test(formField.job_status) ? false : true}
                                    id="product_code"
                                    name="product_code"
                                    value={formField.product_code ? getProductSelectedItem(formField.product_code) : null}

                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setFormField((prev) => ({ ...prev, product_code: newValue.id, service_charge: newValue.service_charge }));
                                        } else {
                                            setFormField((prev) => ({ ...prev, product_code: '', service_charge: '' }));
                                        }
                                        setFormChangeFlag(true);
                                    }}

                                    getOptionLabel={(option) => option.label ? option.label : ''}

                                    options={lookUpsOptions.PRODUCT}

                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.label}
                                        </Box>
                                    )}

                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Product *"
                                            {...register("product_code", { required: "Product is required" })}
                                            error={errors.product_code ? true : false}
                                            helperText={errors.product_code?.message}
                                        />
                                    )}

                                />

                                <NumericFormat
                                    customInput={TextField}
                                    sx={{ width: '100%' }}
                                    label="Service Charge"
                                    disabled={/Assigned|Completed/.test(formField.job_status) ? false : true}
                                    placeholder="Enter service charge in format 50.00 or 40.55"
                                    value={formField.service_charge ? formField.service_charge : 0.00}
                                    maxLength={10}
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    className="bg-white"
                                    onValueChange={(values, sourceInfo) => {
                                        //console.log(values);
                                        setFormField((prev) => ({ ...prev, service_charge: values.value }));
                                        setFormChangeFlag(true);
                                    }}
                                />

                                {jobData.service.property.propertyagent && jobData.service.property.propertyagent.agency &&
                                    <div className='text-theme mb-2 p-2 border bg-smoke2'>Agency Credit: ${jobData.service.property.propertyagent.agency.credit ? jobData.service.property.propertyagent.agency.credit.toFixed(2) : 0.00}</div>
                                }
                                <div className='text-theme mb-2 p-2 border'>Property Credit: ${jobData.service.property.credit ? jobData.service.property.credit.toFixed(2) : 0.00}</div>



                                <textarea
                                    id="comments"
                                    name="comments"
                                    rows={2}
                                    style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%', minHeight: '100px' }}
                                    placeholder="Service Comments"
                                    {...register("comments", { required: false })}
                                    onChange={(e) => { setFormField((prev) => ({ ...prev, comments: e.target.value })); setFormChangeFlag(true); }}

                                    defaultValue={formField.comments ? formField.comments : ''}

                                />

                            </> : <>
                                <div className='d-flex'>
                                    <label className='m-2 fw-bold'>Product: </label>
                                    <label className='m-2'>{jobData.service.product.description + ((_role !== 'E-TRADIE' && _role !== 'P-TRADIE') ? ' - $' + jobData.service.product.service_charge : '')}</label>
                                </div>
                                <div className='d-flex'>
                                    <label className='m-2 fw-bold'>Technician: </label>
                                    <label className='m-2'>{jobData.technician.prefix && jobData.technician.prefix + '. '} {jobData.technician.fullname}
                                    </label>
                                </div>

                                {(_role === 'E-TRADIE' || _role === 'P-TRADIE') && <>
                                    <div className='d-flex'>
                                        <label className='m-2 fw-bold'>Job Comments: </label>
                                        <label className='m-2'>{jobData.job_comments} </label>
                                    </div>
                                    <div className='d-flex'>
                                        <label className='m-2 fw-bold'>Schedule Comments: </label>
                                        <label className='m-2'>{jobData.schedule_comments} </label>
                                    </div>
                                </>}
                            </>
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button type="button" className="themeholy-btn btn-secondary" onClick={handleDialogClose}>Cancel</Button>
                    <Button type="submit" className="themeholy-btn btn-primary" disabled={loaderText}>{(loaderText) ? 'Submitting...' : 'Submit'}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

}

export default JobForm;