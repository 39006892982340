import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Alert, Collapse, Box, TextField, IconButton, Button, AppBar, Toolbar,
    Typography, FormControl, InputLabel, Select, MenuItem, Autocomplete,
    RadioGroup, Radio, FormLabel, FormHelperText
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { UserFormContext } from "./ManageUsers";
import { PatternFormat } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { getSessionItem } from "../../common";


export const UserForm = (props) => {
    const _role = getSessionItem("_role");
    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus, alertMessage, alertOpen, setAlertOpen, postcodeOptions, prefixOptions, userOptions, roleOptions } = useContext(UserFormContext);
    const [pcodeValue, setPcodeValue] = useState({ code: formField.postcode, suburb: formField.suburb, state_shortname: formField.state });
    const [showAlertCheck, setShowAlertCheck] = useState(formField.showalert === 'Y' ? true : false);
    const [isLandlord, setIsLandlord] = useState(formField.is_landlord === 'Y' ? true : false);
    const { register, control, handleSubmit, formState: { errors }, reset, clearErrors, setValue } = useForm();
    const [isAgAddr, setIsAgAddr] = useState(false);
    const [addressDetails, setAddressDetails] = useState({});

    const [sendInvoiceLandlord, setSendInvoiceLandlord] = useState(formField.send_invoice_landlord === 'Y' ? false : true);

    let uTypeArr = { 'ADMSTF': 'Admin Staff', 'AGENT': 'Agent', 'LDLORD': 'Landlord', 'TENANT': 'Tenant', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const userFormType = uTypeArr[props.formType];
    const actionFun = formField.action;


    useEffect(() => {
        if (formField.action === 'edit') {
            reset(formField);
        }
        if (isAgAddr) {
            setPcodeValue({ code: addressDetails.postcode, suburb: addressDetails.suburb, state_shortname: addressDetails.state });
            setFormField((prev) => ({
                ...prev, address1: addressDetails.address1,
                address2: addressDetails.address2, suburb: addressDetails.suburb,
                state: addressDetails.state, postcode: addressDetails.postcode
            }));
        } else if (formField.action === 'add') {
            setPcodeValue({ code: '', suburb: '', state_shortname: '' });
            setFormField((prev) => ({
                ...prev, address1: '',
                address2: '', suburb: '',
                state: '', postcode: ''
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formType, isAgAddr, setValue]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getSelectedItem = (prefix) => {
        // eslint-disable-next-line
        const item = prefixOptions.find((opt) => {
            if (opt && opt.description === prefix) {
                return opt;
            };

        })

        return item || null;
    }

    const getSelectedRoleItem = (role) => {
        // eslint-disable-next-line
        const item = roleOptions.find((opt) => {
            if (opt && opt.id === role) {
                return opt;
            };
        })
        return item || null;
    }

    const getSelectedUserItem = (agency) => {
        // eslint-disable-next-line
        const item = userOptions.find((opt) => {
            if (opt && opt.id === agency) {
                return opt;
            };
        });
        return item || null;
    }

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }


    return (
        <Box component="form" id="userform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                <Toolbar className="dflex">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        className="me-auto"
                    >
                        <CloseIcon />
                        <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                            {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {userFormType}
                        </Typography>
                    </IconButton>

                    <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                        {(loaderText) ? 'Saving...' : 'Save'}
                    </Button>
                </Toolbar>
            </AppBar>
            <div className="container" style={{ paddingTop: '90px' }}>
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }

                    >
                        {alertMessage}
                    </Alert>
                </Collapse>

                <div className="row">
                    <div className="col-md-12 d-block d-lg-none">
                        <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                            {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {userFormType}
                        </Typography>
                    </div>
                    {props.formType === 'AGENT' &&
                        <div className="col-md-12">
                            <FormControl>
                                <FormLabel>Agent Account Type</FormLabel>

                                <RadioGroup
                                    row
                                    name="acct_type"
                                    value={formField.acct_type}

                                    onChange={(e) => {
                                        setFormField((prev) => ({ ...prev, acct_type: e.target.value }))
                                    }}
                                >
                                    <FormControlLabel value="IND" control={<Radio />} label="Individual" />
                                    <FormControlLabel value="ORG" control={<Radio />} label="Organisation" />
                                </RadioGroup>

                                <FormHelperText>{errors.acct_type && (<div>{errors.acct_type?.message}</div>)}</FormHelperText>
                            </FormControl>

                        </div>
                    }
                    {formField.acct_type === 'ORG' &&
                        <>
                            <div className="col-md-6">
                                <TextField
                                    fullWidth
                                    id="fullname" name="fullname" variant="outlined" error={errors.fullname ? true : false} label="Agency Name *"
                                    helperText={errors.fullname?.message}

                                    inputProps={{ maxLength: 100, value: formField.fullname }}

                                    {...register("fullname", { required: formField.acct_type === 'ORG' ? "Agency Name is required" : false })}

                                    onChange={(e) => setFormField((prev) => ({ ...prev, fullname: e.target.value }))}

                                />
                            </div>
                            <div className="col-md-6">
                                <div className="text-start mt-3">
                                    <FormControlLabel control={<Checkbox name="is_landlord"
                                        checked={sendInvoiceLandlord}
                                        onChange={(event) => { setSendInvoiceLandlord(event.target.checked); setFormField((prev) => ({ ...prev, send_invoice_landlord: event.target.checked ? 'N' : 'Y' })) }} />}
                                        label="Don't send invoice to Landlord" labelPlacement="end" />
                                </div>
                            </div>
                        </>
                    }
                    {formField.acct_type === 'IND' &&
                        <div className="col-md-6">
                            <h5 className="mb-3" style={{ textAlign: 'left' }}>Personal Info:</h5>
                            <Controller
                                name="prefix"
                                control={control}
                                rules={{
                                    required: ((props.formType === "AGENT" || props.formType === "LDLORD") ? false : "Prefix is required")
                                }}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        id="prefix"
                                        options={prefixOptions}
                                        getOptionLabel={(option) => option.description ? option.description : ''}

                                        sx={{ width: '100%' }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={"prefix_" + option.id}>
                                                {option.description}
                                            </Box>
                                        )}
                                        //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                        renderInput={(params) => <TextField {...params} label="Prefix" variant="outlined"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        /* inputRef={input => {
                                            inputRef = input;
                                        }} */
                                        />}
                                        onChange={(_, data) => {
                                            field.onChange(data ? data.description : '')
                                            setFormField((prev) => ({ ...prev, prefix: data ? data.description : '' }))
                                        }}

                                        value={getSelectedItem(formField.prefix)}
                                    />
                                )}
                            />


                            <TextField
                                fullWidth
                                id="firstname" name="firstname" variant="outlined" error={errors.firstname ? true : false} label="Firstname *"
                                helperText={errors.firstname?.message}

                                inputProps={{ maxLength: 40, value: formField.firstname }}

                                {...register("firstname", { required: "Firstname is required" })}

                                onChange={(e) => setFormField((prev) => ({ ...prev, firstname: e.target.value }))}
                            />


                            <TextField
                                fullWidth
                                id="lastname" name="lastname" variant="outlined" error={errors.lastname ? true : false} label="Lastname *"
                                helperText={errors.lastname?.message}

                                inputProps={{ maxLength: 40, value: formField.lastname }}

                                {...register("lastname", { required: "Lastname is required" })}

                                onChange={(e) => setFormField((prev) => ({ ...prev, lastname: e.target.value }))}
                            />

                            {props.formType === 'AGENT' && formField.acct_type === "IND" &&
                                <Controller
                                    name="agency_name"
                                    control={control}
                                    rules={{
                                        required: (formField.acct_type === "ORG" ? false : "Agency Name is required")
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            id="agency_name"
                                            options={userOptions.filter(a => a.acct_type === "ORG")}
                                            getOptionLabel={(option) => option.label ? option.label : ''}

                                            sx={{ width: '100%' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={"user_" + option.id}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            renderInput={(params) => <TextField {...params} label={"Agency Name" + (formField.acct_type === "ORG" ? '' : ' *')} variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.id : '')
                                                setAddressDetails(data);
                                                setFormField((prev) => ({ ...prev, agency_name: data ? data.id : '' }))
                                                setIsAgAddr(false);
                                            }}

                                            value={getSelectedUserItem(formField.agency_name)}
                                        />
                                    )}
                                />
                            }


                            {(props.formType === 'E-TRADIE' || props.formType === 'P-TRADIE') && (
                                <>
                                    <TextField
                                        fullWidth
                                        id="company" name="company" variant="outlined" error={errors.company ? true : false} label="Company *"
                                        helperText={errors.company?.message}

                                        inputProps={{ maxLength: 100, value: formField.company ? formField.company : '' }}

                                        {...register("company", { required: "Company is required" })}

                                        onChange={(e) => setFormField((prev) => ({ ...prev, company: e.target.value }))}

                                    />
                                    <TextField
                                        fullWidth
                                        id="license_number" name="license_number" variant="outlined" error={errors.license_number ? true : false} label="License Number *"
                                        helperText={errors.license_number?.message}

                                        inputProps={{ maxLength: 50, value: formField.license_number ? formField.license_number : '' }}

                                        {...register("license_number", { required: "License Number is required" })}

                                        onChange={(e) => setFormField((prev) => ({ ...prev, license_number: e.target.value }))}

                                    /></>
                            )}

                            <TextField
                                fullWidth
                                id="email" name="email" variant="outlined" error={errors.email ? true : false} label="Email Address *"
                                helperText={errors.email?.message}

                                inputProps={{ maxLength: 60, value: formField.email }}

                                {...register("email", {
                                    required: "Email Address is required", pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    }
                                })}

                                onChange={(e) => setFormField((prev) => ({ ...prev, email: e.target.value }))}
                            />

                            <Controller
                                name="mobile"
                                control={control}
                                rules={{
                                    //required: "A valid mobile number is required",
                                    required: false,
                                    pattern: {
                                        value: /^04\d{8}$/,
                                        message: 'Please enter a valid mobile number',
                                    }
                                }}

                                render={({ field, fieldState }) => (
                                    <PatternFormat
                                        label="Mobile"
                                        customInput={TextField}
                                        type="tel"
                                        format="04########"
                                        allowEmptyFormatting
                                        fullWidth
                                        mask="_"
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        value={formField.mobile}
                                        onChange={(e) => { field.onChange(handleMobileChange(e)) }}
                                    />
                                )}
                            />

                            {props.formType === 'ADMSTF' && (

                                <Controller
                                    name="role_id"
                                    control={control}
                                    rules={{
                                        required: "Role is required"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            id="role"
                                            options={roleOptions}
                                            getOptionLabel={(option) => option.description ? option.description : ''}

                                            sx={{ width: '100%' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={"role_" + option.id}>
                                                    {option.description}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                            renderInput={(params) => <TextField {...params} label="User Role" variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            /* inputRef={input => {
                                                inputRef = input;
                                            }} */
                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.id : '')
                                                setFormField((prev) => ({ ...prev, role_id: data ? data.id : '' }))
                                            }}

                                            value={getSelectedRoleItem(formField.role_id)}
                                        />
                                    )}
                                />
                            )}

                            {(_role === 'SYS' || _role === 'MG') && isLandlord === true && (
                                <NumericFormat
                                    customInput={TextField}
                                    sx={{ width: '100%' }}
                                    label="Credit"
                                    placeholder="Enter credit in format 50.00 or 40.55"
                                    value={formField.credit ? formField.credit : ''}
                                    maxLength={10}
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    className="bg-white"
                                    onValueChange={(values, sourceInfo) => {
                                        //console.log(values);
                                        setFormField((prev) => ({ ...prev, credit: values.value }))
                                    }}
                                />
                            )}

                        </div>
                    }
                    <div className="col-md-6">
                        <h5 className="mb-3" style={{ textAlign: 'left' }}>{props.formType === 'LDLORD' ? 'Mailing Address' : 'Address Info'}:</h5>
                        {formField.agency_name && <FormControlLabel
                            label="Same as Agency Address"
                            control={
                                <Checkbox
                                    checked={isAgAddr}
                                    onChange={(e) => {
                                        setIsAgAddr(e.target.checked);
                                        if (e.target.checked) {
                                            setValue('address1', addressDetails.address1);
                                            setValue('suburb', addressDetails.suburb);
                                        } else {
                                            setValue('address1', '');
                                            setValue('suburb', '');
                                        }
                                        clearErrors(['address1', 'suburb']);

                                    }}
                                />
                            }
                        />}
                        <TextField
                            fullWidth
                            id="address1" name="address1" variant="outlined" error={errors.address1 ? true : false} label={(props.formType === 'ADMSTF') ? 'Address 1' : 'Address 1 *'}
                            helperText={errors.address1?.message}

                            inputProps={{ maxLength: 40, value: (formField.address1 ? formField.address1 : '') }}

                            {...register("address1", { required: (props.formType === 'ADMSTF') ? false : "Address 1 is required" })}

                            onChange={(e) => setFormField((prev) => ({ ...prev, address1: e.target.value }))}
                        />

                        <TextField
                            fullWidth
                            id="address2" name="address2" variant="outlined" error={errors.address2 ? true : false} label="Address 2"
                            helperText={errors.address2?.message}

                            inputProps={{ maxLength: 40, value: (formField.address2 ? formField.address2 : '') }}

                            {...register("address2", { required: false })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, address2: e.target.value }))}
                        />

                        <Autocomplete
                            sx={{ width: '100%' }}
                            id="suburb"
                            name="suburb"
                            value={pcodeValue}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                    setFormField((prev) => ({ ...prev, suburb: newValue?.suburb, state: newValue?.state_shortname, postcode: newValue?.code }));
                                    //setPcodeInput(newValue.suburb);
                                } else {
                                    setPcodeValue({ code: '', suburb: '', state_shortname: '' });
                                    setFormField((prev) => ({ ...prev, suburb: '', state: '', postcode: '' }));
                                }
                            }}

                            /*  inputValue={pcodeInput}
     
                             onInputChange={(event, newInputValue, eventType) => {
                                 if (eventType === 'reset') {
                                     setPcodeInput(pcodeInput);
                                 } else {
                                     setPcodeInput(newInputValue);
                                 }
                             }} */

                            getOptionLabel={(option) => option.suburb ? option.suburb : ''}

                            options={postcodeOptions}

                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={"suburb_" + option.id}>
                                    {option.suburb}, {option.state_shortname} - {option.code}
                                </Box>
                            )}

                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={(props.formType === 'ADMSTF') ? 'Search Suburb' : 'Search Suburb *'}
                                    error={errors.suburb ? true : false}
                                    helperText={errors.suburb?.message}
                                    {...register("suburb", { required: (props.formType === 'ADMSTF') ? false : "Suburb is required" })}
                                />
                            )}
                        />

                        <TextField fullWidth id="state" name="state" variant="outlined" error={errors.state ? true : false} label="State" helperText={errors.state?.message} {...register("state", { required: false })} inputProps={{ maxLength: 4, value: (formField.state ? formField.state : ''), readOnly: false }} onChange={(e) => { setFormField((prev) => ({ ...prev, state: e.target.value })) }} />

                        <TextField
                            fullWidth
                            id="postcode" name="postcode"
                            variant="outlined" error={errors.postcode ? true : false}
                            label="Postcode"
                            helperText={errors.postcode?.message}
                            inputProps={{ maxLength: 4, value: (formField.postcode ? formField.postcode : ''), readOnly: false }}

                            {...register("postcode", { required: false })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, postcode: e.target.value }))}
                        />
                        <NumericFormat
                            customInput={TextField}
                            sx={{ width: '100%' }}
                            label="Contact Number"
                            value={formField.phone ? formField.phone : ''}
                            inputProps={{ maxLength: 10 }}
                            className="bg-white"
                            onValueChange={(values, sourceInfo) => {
                                setFormField((prev) => ({ ...prev, phone: values.value }))
                            }}
                        />
                        {props.formType !== 'LDLORD' && formField.acct_type === 'IND' &&
                            <div className="text-start">
                                <FormControlLabel control={<Checkbox name="is_landlord"
                                    checked={isLandlord}
                                    onChange={(event) => { setIsLandlord(event.target.checked); setFormField((prev) => ({ ...prev, is_landlord: event.target.checked ? 'Y' : 'N' })) }} />}
                                    label="Act as Landlord too" labelPlacement="end" />
                            </div>
                        }
                        {actionFun === 'edit' && _role === 'SYS' && (
                            <FormControl fullWidth>
                                <InputLabel id="status-select-label"> Select Status </InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status"
                                    name="status"
                                    error={errors.status ? true : false}
                                    value={formField.status}
                                    label="Select Status"
                                    {...register("status", { required: false })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, status: e.target.value }))}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value='Y'>Active</MenuItem>
                                    <MenuItem value='N'>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    {formField.acct_type === 'ORG' && ['SYS', 'MG', 'STAFF'].includes(_role) &&

                        <div className="col-md-6">
                            <textarea
                                id="cc_email"
                                name="cc_email"
                                rows={2}
                                style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%', minHeight: '100px' }}
                                placeholder="CC Email"
                                {...register("cc_email", { required: false })}
                                onChange={(e) => { setFormField((prev) => ({ ...prev, cc_email: e.target.value })); }}

                                defaultValue={formField.cc_email ? formField.cc_email : ''}

                            />
                            <div className="text-light mb-3 text-sm">Format: example1@domain.com,example2@domain.com,example3@domain.com</div>

                            {formField.acct_type === 'ORG' &&
                                <NumericFormat
                                    customInput={TextField}
                                    sx={{ width: '100%' }}
                                    label="Credit"
                                    placeholder="Enter credit in format 50.00 or 40.55"
                                    value={formField.credit ? formField.credit : ''}
                                    maxLength={10}
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    className="bg-white"
                                    onValueChange={(values, sourceInfo) => {
                                        setFormField((prev) => ({ ...prev, credit: values.value }))
                                    }}
                                />
                            }

                        </div>

                    }

                    <div className="col-md-12">
                        <div className="text-end">
                            <FormControlLabel control={<Checkbox name="showalert"
                                checked={showAlertCheck}
                                onChange={(event) => { setShowAlertCheck(event.target.checked); setFormField((prev) => ({ ...prev, showalert: event.target.checked ? 'Y' : 'N' })) }} />}
                                label="Show Alert" labelPlacement="start" />
                        </div>
                        <textarea
                            id="alertnote"
                            name="alertnote"
                            rows={4}
                            style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
                            placeholder="Notes"

                            {...register("alertnote", { required: false })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, alertnote: e.target.value }))}

                            defaultValue={formField.alertnote ? formField.alertnote : ''}

                        />
                    </div>
                    <div className="col-md-12 mt-3 text-center">
                        <div className='row'>
                            <div className='col-12 col-md-4 col-lg-6 col-sm-2 col-xl-6 col-xxl-6'></div>
                            <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 mb-3 text-end'>
                                <Button type="button" className="themeholy-btn btn-secondary w-100" onClick={handleDialogClose}>Cancel</Button>

                            </div>
                            <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end'>
                                <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box >
    );
}