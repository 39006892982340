import { useEffect, useState, useRef, useContext, forwardRef, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { axiosFunction, clearSession } from '../../common';
import {
    Box, CircularProgress, Fade, Typography, Button, TextField,
    Collapse, Alert, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide
} from "@mui/material";
import { Close, Remove, Add } from '@mui/icons-material';
import { QuotationContext } from "./ManageJobs";
import { Base64 } from 'js-base64';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const JobQuotationForm = (props) => {
    const navigate = useNavigate(null);
    //const _role = getSessionItem("_role");
    const { formField, setFormField, handleQuotationDialogClose, invItems, formChangeFlag, setFormChangeFlag, quotationData, fetchData } = useContext(QuotationContext);

    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [loaderTextSend, setLoaderTextSend] = useState(false);
    const [quotationItems, setQuotationItems] = useState(invItems.items);
    const quotationFormRef = useRef();
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delItem, setDelItem] = useState('');
    const [saveOption, setSaveOption] = useState('');
    const [checklistData, setChecklistData] = useState([]);

    const { register, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            items: quotationItems
        }
    });
    const {
        fields,
        append,
        remove
    } = useFieldArray({
        control,
        name: "items"
    });
    // const curDate = new Date();
    const actionFun = formField.action;
    const CLfetchData = async () => {
        try {
            const reportData = await axiosFunction('servicereport/' + quotationData.id, 'get', {});
            if (reportData.status === 'success') {
                setChecklistData(Object.values(reportData.data));
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        setLoader(false);
        if (quotationData.service_type === 'GS') {
            CLfetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionFun]);

    const checkFormChange = () => {
        setFormChangeFlag(true);
    };

    const updateAmount = (newFormValues) => {
        var subtotal = 0;
        var tax_rate = 0;
        var total = 0;
        var creditAmt = 0;
        var balanceCredit = 0;

        if (newFormValues) {
            newFormValues.map(element => subtotal = (element.amount === '') ? parseFloat(subtotal) : parseFloat(subtotal) + parseFloat(element.amount));
        } else {
            quotationItems.map(element => subtotal = (element.amount === '') ? parseFloat(subtotal) : parseFloat(subtotal) + parseFloat(element.amount));
        }

        tax_rate = parseFloat(subtotal) * (10 / 100);
        total = parseFloat(subtotal) + parseFloat(tax_rate);

        setFormField((prev) => ({ ...prev, subtotal: subtotal, tax_rate: tax_rate, total: total, creditAmt: creditAmt, balanceCredit: balanceCredit }));
    }

    const handleFieldChange = (i, e, fld) => {
        let newFormValues = [...quotationItems];

        if (fld === 'quantity' || fld === 'charge') {
            if (parseInt(e.target.value) === 0 || e.target.value === '') e.target.value = 1;

            if (fld === 'quantity' && (parseInt(e.target.value) > 100 || !/^[0-9]+$/.test(e.target.value))) return false;
            if (fld === 'charge' && (e.target.value.length > 7 || !/^[0-9.]+$/.test(e.target.value))) return false;

            newFormValues[i][fld] = e.target.value;

            newFormValues[i]['amount'] = newFormValues[i]['quantity'] * newFormValues[i]['charge'];
            updateAmount();
            setQuotationItems(newFormValues);
            checkFormChange();
        } else {
            newFormValues[i][fld] = e.target.value;
            setQuotationItems(newFormValues);
            checkFormChange();
        }

    }


    const addQuotationItem = () => {
        append({ description: "", quantity: 1, charge: "", amount: "" });
        setQuotationItems([...quotationItems, { description: "", quantity: 1, charge: "", amount: "" }]);
        checkFormChange();
    }

    const removeItem = (i) => {
        setDelItem(i);
        if (actionFun === 'edit') {
            setConfirmOpen(true);
        } else {
            removeQuotationItem();
        }
    }

    const removeQuotationItem = () => {
        remove(delItem);
        let newFormValues = [...quotationItems];
        newFormValues.splice(delItem, 1);
        setQuotationItems(newFormValues);
        updateAmount(newFormValues);
        checkFormChange();
        handleConfirmClose();
    }

    const handleConfirmClose = () => {
        if (actionFun === 'edit') {
            setConfirmOpen(false);
        }
        setDelItem('');
    };

    const onQuotationSubmit = async (data) => {
        try {
            if (formChangeFlag || saveOption === 'send') {
                setLoaderTextSend(saveOption === 'send' ? true : false);
                setLoaderText(saveOption === 'save' ? true : false);
                let saveResponse = null;

                data.items = quotationItems;
                data.saveOption = saveOption;
                data.fields = formField;

                if (actionFun === 'edit') {
                    data._method = 'PUT';
                    data.quotation_id = formField.quotation_id;
                    saveResponse = await axiosFunction('quotations/' + quotationData.quotation.id, 'post', data);
                } else if (actionFun === 'add') {
                    data.service_id = quotationData.service_id;
                    data.subservice_id = quotationData.id;
                    data.property_id = quotationData.service.property_id;
                    saveResponse = await axiosFunction('quotations', 'post', data);
                }

                if (saveResponse.status === 'success') {
                    props.mainAlertOpen(true);
                    props.mainAlertStatus('success');
                    props.mainAlertMessage(saveResponse.message);
                    props.mainAlertAutoClose();
                    fetchData();
                    setLoaderTextSend(false);
                    setLoaderText(false);
                    handleQuotationDialogClose();
                    window.open('/generatequotation/' + Base64.encode(saveResponse.item.id, true), '_blank');
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(saveResponse.message);
                    document.querySelector('#quotationFrm').scrollIntoView({ behavior: 'smooth' });
                    setLoaderTextSend(false);
                    setLoaderText(false);
                }
            } else {
                handleQuotationDialogClose();
                setLoaderTextSend(false);
                setLoaderText(false);
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderTextSend(false);
            setLoaderText(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    };

    return (
        <>
            <div className='header-top text-white p-3 d-flex'>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleQuotationDialogClose}
                    aria-label="close"
                >
                    <Close />
                </IconButton>
                <Typography
                    variant='h5'
                    className='text-white'
                    sx={{ marginLeft: '30px' }}
                >
                    {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Quotation

                </Typography>
            </div>
            {loader && <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Fade
                    in={loader}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </Box>}
            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                sx={{ zIndex: 1400 }}
            >
                <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, the item will be deleted and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={() => handleConfirmClose()}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => removeQuotationItem()}>Yes</Button>
                </DialogActions>
            </Dialog>
            {!loader && <div className="container pt-3">
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
                <Box component="form" ref={quotationFormRef} id="quotationFrm" name="quotationFrm" noValidate autoComplete="off" onSubmit={handleSubmit(onQuotationSubmit)}>
                    <div className="row">
                        {actionFun === 'edit' ?? <div className="col-md-12 p-4">
                            <strong>Quotation Number:</strong> {formField.quotation_number ? formField.quotation_number : '#####'}
                        </div>}
                        <div className="col-md-11 m-auto mb-3">
                            <h5 className="sub-title2">Quotation To:</h5>

                            {quotationData.service.property.propertyowner ?
                                <p>
                                    <strong>Landlord:</strong><br />
                                    {quotationData.service.property.propertyowner.prefix ? quotationData.service.property.propertyowner.prefix + '. ' : ''}{quotationData.service.property.propertyowner.fullname}
                                </p>
                                : <p>
                                    <strong>Agent:</strong><br />
                                    {quotationData.service.property.propertyagent.prefix ? quotationData.service.property.propertyagent.prefix + '. ' : ''}{quotationData.service.property.propertyagent.fullname}
                                </p>}
                            <p>
                                <strong>Property:</strong><br />
                                {quotationData.service.property.address1 ? quotationData.service.property.address1 + ', ' : ''}{quotationData.service.property.address2 ? quotationData.service.property.address2 + ',' : ''}<br /> {quotationData.service.property.suburb}, {quotationData.service.property.state} - {quotationData.service.property.postcode}
                            </p>
                        </div>
                        {quotationData.service_type === 'GS' ?
                            <div className="col-md-11 m-auto">
                                <h5 className="sub-title2">Observations and Recommendations</h5>
                                <Typography>
                                    The following observations and recommendations are made:
                                </Typography>
                                {checklistData && checklistData.map((item, index) => <Fragment key={"gascont-" + index}>
                                    {item.checklist && item.checklist.map((chklist, cindex) => (
                                        chklist.code === 'GASCOMMENT' &&
                                        <div className="bg-smoke text-theme2 mb-1 p-2" key={'gas-' + index + '-' + cindex}>{chklist.comments}</div>
                                    ))}
                                </Fragment>)}
                            </div>
                            :
                            <div className="col-md-11 m-auto">
                                <h5 className="sub-title2">Observations and Recommendations</h5>
                                <Typography>
                                    The following observations and recommendations are made:
                                </Typography>
                                <div className="bg-smoke text-theme2 mb-3 p-2">{quotationData.recommendation ? quotationData.recommendation : 'Nil'}</div>

                                <Typography>
                                    The following work is required for compliance purposes:
                                </Typography>
                                <div className="bg-smoke text-theme2 p-2">{quotationData.compliance ? quotationData.compliance : 'Nil'}</div>
                            </div>
                        }
                        <div className="col-md-11 m-auto">
                            <div className="row">
                                <div className="col-md-11">
                                    <h5 className="pt-5">Add Products:</h5>
                                </div>
                            </div>
                            {fields.map((item, index) => (
                                <div className="row" key={index} style={{ border: '1px solid #ccc', borderTop: index ? '0px' : '1px solid #ccc' }}>
                                    <div className="col-6 col-md-4 m-auto d-flex">
                                        <Controller
                                            render={({ field }) => <TextField {...field}
                                                label="Description"
                                                fullWidth variant="outlined"
                                                {...register(`items.${index}.description`, { required: 'Description is required' })}
                                                onChange={(e) => handleFieldChange(index, e, 'description')}
                                                inputProps={{ value: quotationItems[index].description, maxLength: 100 }}
                                                error={errors.items && errors.items[index]?.description ? true : false}
                                                helperText={errors.items && errors.items[index]?.description?.message}
                                            />
                                            }
                                            name={`items.${index}.description`}
                                            control={control}
                                        />
                                    </div>
                                    <div className="col-6 col-md-3 m-auto d-flex align-items-center">
                                        <Remove color={parseInt(quotationItems[index]['quantity']) > 1 ? 'primary' : 'disabled'} style={{ border: '1px solid #ccc', borderRadius: '50%', margin: '10px', cursor: parseInt(quotationItems[index]['quantity']) > 1 ? 'pointer' : 'default' }} onClick={() => {
                                            let newFormValues = [...quotationItems];
                                            newFormValues[index]['quantity'] = parseInt(newFormValues[index]['quantity']) > 1 ? parseInt(newFormValues[index]['quantity']) - 1 : newFormValues[index]['quantity'];

                                            if (parseFloat(newFormValues[index]['charge']) > 0) {
                                                newFormValues[index]['amount'] = parseInt(newFormValues[index]['quantity']) * parseFloat(newFormValues[index]['charge']);
                                            }
                                            updateAmount();
                                            setQuotationItems(newFormValues);
                                            checkFormChange();
                                        }} />
                                        <Controller
                                            render={({ field, fieldState }) => <TextField {...field}
                                                type="number"
                                                label="Qty"
                                                fullWidth variant="outlined"
                                                {...register(`items.${index}.quantity`, { required: 'Quantity is required' })}
                                                onChange={(e) => handleFieldChange(index, e, 'quantity')}
                                                inputProps={{ value: quotationItems[index].quantity, step: 1 }}
                                                error={errors.items && errors.items[index]?.quantity ? true : false}
                                                helperText={errors.items && errors.items[index]?.quantity?.message}
                                            />
                                            }
                                            name={`items.${index}.quantity`}
                                            control={control}
                                        />
                                        <Add color={parseInt(quotationItems[index]['quantity']) < 100 ? 'primary' : 'disabled'} style={{ border: '1px solid #ccc', borderRadius: '50%', margin: '10px', cursor: parseInt(quotationItems[index]['quantity']) < 100 ? 'pointer' : 'default' }} onClick={() => {
                                            let newFormValues = [...quotationItems];

                                            newFormValues[index]['quantity'] = parseInt(newFormValues[index]['quantity']) < 100 ? parseInt(newFormValues[index]['quantity']) + 1 : newFormValues[index]['quantity'];

                                            if (parseFloat(newFormValues[index]['charge']) > 0) {
                                                newFormValues[index]['amount'] = parseInt(newFormValues[index]['quantity']) * parseFloat(newFormValues[index]['charge']);
                                            }

                                            updateAmount();
                                            setQuotationItems(newFormValues);
                                            checkFormChange();
                                        }} />
                                    </div>
                                    <div className="col-6 col-md-2 m-auto text-end">
                                        <Controller
                                            render={({ field, fieldState }) => <TextField {...field}
                                                type="number"
                                                label="Charge"
                                                fullWidth variant="outlined"
                                                {...register(`items.${index}.charge`, { required: 'Charge is required' })}
                                                onChange={(e) => handleFieldChange(index, e, 'charge')}
                                                value={quotationItems[index].charge}
                                                error={errors.items && errors.items[index]?.charge ? true : false}
                                                helperText={errors.items && errors.items[index]?.charge?.message}
                                            />
                                            }
                                            name={`items.${index}.charge`}
                                            control={control}
                                        />
                                    </div>
                                    <div className="col-6 col-md-3 d-flex gap-3 m-auto text-end">
                                        <TextField
                                            type="number"
                                            label="Amount"
                                            fullWidth variant="outlined"
                                            inputProps={{ maxLength: 15, readOnly: true }}
                                            value={parseFloat(quotationItems[index].amount).toFixed(2)}
                                        />
                                        {
                                            index ? <div className="d-flex gap-3 m-auto">{quotationItems.length <= (index + 1) && <Link to='#' onClick={() => addQuotationItem()}><i className="fa fa-circle-plus" ></i></Link>} <Link to='#' onClick={() => removeItem(index)}><i className="fa fa-trash" ></i></Link></div>
                                                : <div className="d-flex gap-3 m-auto">{quotationItems.length <= (index + 1) && <Link to='#' onClick={() => addQuotationItem()}><i className="fa fa-circle-plus" ></i></Link>} {quotationItems.length > 1 && <Link to='#' onClick={() => removeItem(index)}><i className="fa fa-trash" ></i></Link>}</div>
                                        }
                                    </div>
                                </div>
                            ))}

                            <div className="row fs-xxl fw-bold" style={{ border: '1px solid #ccc', borderTop: '0px' }}>
                                <div className="col-9 text-end p-3">
                                    Subtotal :
                                </div>
                                <div className="col-3 p-3">
                                    {formField.subtotal.toFixed(2)}
                                </div>
                                <div className="col-9 text-end p-3">
                                    GST 10% :
                                </div>
                                <div className="col-3 p-3">
                                    {formField.tax_rate.toFixed(2)}
                                </div>
                                <div className="col-9 text-end p-3">
                                    <h5>Total :</h5>
                                </div>
                                <div className="col-3 p-3">
                                    <h5>{formField.total.toFixed(2)}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-11 m-auto text-center mt-3 mb-5">
                            <div className="row">
                                <div className="col-12 col-md-4 m-auto p-1">
                                    <Button type="button" className="themeholy-btn btn-secondary bg-black w-100" onClick={handleQuotationDialogClose}>Close</Button>
                                </div>
                                <div className="col-12 col-md-4 m-auto p-1">
                                    <Button type="submit" className="col-11 col-md-4 themeholy-btn btn-secondary w-100" onClick={() => setSaveOption('save')} disabled={loaderText}>{loaderText ? 'Submitting' : 'Save & Exit'}</Button>
                                </div>
                                <div className="col-12 col-md-4 m-auto p-1">
                                    <Button type="submit" className="themeholy-btn btn-primary w-100" onClick={() => setSaveOption('send')} disabled={loaderTextSend}>{loaderTextSend ? 'Submitting' : 'Save & Send Quotation'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

            </div>}

        </>
    )
}

export default JobQuotationForm;