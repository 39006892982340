import { useRef, useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
    Typography, Table, TableBody, TableRow, TableHead, TableFooter, TableCell, Box, AppBar, Toolbar, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide
} from '@mui/material';
import dayjs from 'dayjs';
import { axiosFunction, clearSession } from '../../common';
import jsPDF from 'jspdf';
import Banner from "../../assets/img/Report_Invoice_Header.png";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Quotation = ({ upload, quotationData }) => {
    //const _token = getSessionItem("_token");
    const quotationRef = useRef(null);
    const navigate = useNavigate(null);
    const comp = useLocation();
    const compArr = comp.pathname.split('/');
    const invoiceid = compArr[2];
    const viewBy = compArr[3] ? compArr[3] : '';
    const maxWidth = '900px';

    const [quotationDetails, setQuotationDetails] = useState({});
    const [confirmopen, setConfirmopen] = useState(false);
    const [confirmLoader, setConfirmLoader] = useState(false);

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const quotationFetchData = async (invid) => {
        if (invid) {
            try {
                const quotationData = await axiosFunction('auth/getquotation', 'get', { invoice_id: invid, viewBy: viewBy });
                if (quotationData.status === 'success') {
                    setQuotationDetails(quotationData.data);
                    /* if (_token && upload === 'Y') {
                        setTimeout(() => {
                            doc.setProperties({
                                title: "quote_" + invid + ".pdf"
                            }).html(quotationRef.current, {
                                autoPaging: 'text',
                                margin: [20, 20, 20, 20],
                                callback: async function (doc) {
                                    doc.setFont('helvetica', 'normal');
                                    var out = doc.output('datauristring');
                                    //doc.output('dataurlnewwindow', { filename: "invoice_" + invid + ".pdf" });
                                    if (out) {
                                        const pdfData = { invoice_id: invid, pdfContent: out }
                                        const response = await axiosFunction('quotations/uploadpdf', 'post', pdfData);

                                        if (response.status !== 'success') {
                                            if (response?.response?.status === 401) {
                                                clearSession();
                                                navigate('/login');
                                            }
                                        }

                                    }
                                },
                                html2canvas: {
                                    allowTaint: true,
                                    letterRendering: true,
                                    logging: false,
                                    scale: 0.45, // Adjust the scale to fit content
                                }
                            });

                        }, 2000);
                    } */
                }
            } catch (e) {
                if (e?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } else {
                    console.log(e);
                }
            }

        }
    }

    useEffect(() => {
        if (quotationData) setQuotationDetails(quotationData)
        if (invoiceid) quotationFetchData(invoiceid);
        // eslint-disable-next-line
    }, [invoiceid]);

    const handleApprove = () => {
        setConfirmopen(true);
    }

    const quotationApprove = async () => {
        setConfirmLoader(true);
        try {
            const quotationData = await axiosFunction('auth/quotationapprove', 'post', { invoice_id: invoiceid });
            if (quotationData.status === 'success') {
                setQuotationDetails(quotationData.data);

                /* setTimeout(() => {
                    doc.setProperties({
                        title: "quote_" + invoiceid + ".pdf"
                    }).html(quotationRef.current, {
                        autoPaging: 'text',
                        margin: [20, 20, 20, 20],
                        callback: async function (doc) {
                            doc.setFont('helvetica', 'normal');
                            var out = doc.output('datauristring');
                            //doc.output('dataurlnewwindow', { filename: "invoice_" + invid + ".pdf" });
                            if (out) {
                                const pdfData = { invoice_id: invoiceid, pdfContent: out }
                                const response = await axiosFunction('auth/quotationUploadPDF', 'post', pdfData);

                                if (response.status !== 'success') {
                                    if (response?.response?.status === 401) {
                                        clearSession();
                                        navigate('/login');
                                    }
                                }

                            }
                        },
                        html2canvas: {
                            allowTaint: true,
                            letterRendering: true,
                            logging: false,
                            scale: 0.45, // Adjust the scale to fit content
                        }
                    });

                }, 2000); */
                handleConfirmClose();
                setConfirmLoader(false);
            }
        } catch (e) {
            handleConfirmClose();
            setConfirmLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    const handleDownloadPdf = () => {

        doc.setProperties({
            title: "quote_" + invoiceid + ".pdf"
        }).html(quotationRef.current, {
            callback: function (doc) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize('14px');
                doc.save("quote_" + invoiceid + ".pdf");
            },
            margin: [5, 4, 5, 4],
            autoPaging: 'text',
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.486,
                ignoreElements(el) {
                    return typeof el.shadowRoot === 'object' && el.shadowRoot !== null;
                },
            }
        });

    };


    const handleConfirmClose = () => {
        setConfirmopen(false);
    }

    return (
        <>
            {
                quotationDetails.id &&
                <Fragment>
                    {upload === 'N' &&
                        <AppBar sx={{ position: 'fixed' }} className="bg-theme">
                            <Toolbar className="d-flex flex-row-reverse">
                                {/* <Link to={apiBaseUrl.replace('api/', '') + "public/storage/quotations/quotation_" + invoiceid + ".pdf"}><Button type="button" className="themeholy-btn btn-secondary">
                                    Download
                                </Button></Link> */}
                                <Button type="button" className="themeholy-btn btn-secondary" onClick={handleDownloadPdf}>
                                    Download
                                </Button>
                                {(quotationDetails.quotation_status === null || quotationDetails.quotation_status === 'N') && <><Button type="button" className="themeholy-btn btn-secondary gr-bg4" sx={{ marginRight: '15px' }} onClick={() => handleApprove()}>
                                    Approve
                                </Button></>}
                            </Toolbar>
                        </AppBar>
                    }
                    <Box ref={quotationRef} style={{ backgroundColor: '#FFFFFF', fontSize: '16px', textAlign: 'left', padding: '15px', fontFamily: 'helvetica', width: maxWidth }} className="container" id='pdfcontent'>

                        <div className='text-center'><img alt="banner" src={Banner} width='100%' /></div>
                        <div className='container'>

                            <Typography
                                sx={{ display: 'block', margin: '15px 0px' }}
                                component="h3"
                                variant="subtitle-1"
                                className='text-theme'
                            >
                                Quotation
                            </Typography>
                            <div className='row'>
                                <div className='col-7 mb-3'>
                                    <div>
                                        <strong>Property Address:</strong>
                                        <Typography
                                            sx={{ display: 'block' }}
                                            variant="body"
                                        >

                                            {quotationDetails.property && <>
                                                {quotationDetails.property.address1 && quotationDetails.property.address1 + ', '}
                                                {quotationDetails.property.address2 && quotationDetails.property.address2 + ','}<br />
                                                {quotationDetails.property.suburb + ', '}
                                                {quotationDetails.property.state + ' - ' + quotationDetails.property.postcode}
                                            </>}

                                        </Typography>
                                    </div>
                                    <div className='mt-3'>
                                        <div className='col-md-7'>
                                            <strong>Landlord:</strong>
                                            {quotationDetails.property && quotationDetails.property.propertyowner && <>
                                                {quotationDetails.property.propertyowner.prefix ? quotationDetails.property.propertyowner.prefix + '. ' : ''}{quotationDetails.property.propertyowner.fullname}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-5'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 fw-bold text-md-end'>Quotation Date: </div>
                                        <div className='col-12 col-md-6 text-md-end'>{(quotationDetails.quotation_date.split('/')[0].length > 4) ? dayjs(quotationDetails.quotation_date).format('DD/MM/YYYY') : quotationDetails.quotation_date}</div>
                                        <div className='col-12 col-md-6 fw-bold text-md-end'>Quotation #: </div>
                                        <div className='col-12 col-md-6 text-md-end'> {quotationDetails.quotation_number}</div>
                                        {quotationDetails.services && <>
                                            <div className='col-12 col-md-6 fw-bold text-md-end'>Service Date: </div>
                                            <div className='col-12 col-md-6 text-md-end'>{quotationData ? dayjs(quotationData.services.service_date).format('DD/MM/YYYY') : dayjs(quotationDetails.services.service_date).format('DD/MM/YYYY')}</div>
                                        </>}
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6 fw-bold text-md-end'>Agent:</div>
                                        <div className='col-12 col-md-6 text-md-end'>
                                            {quotationDetails.property && quotationDetails.property.propertyagent && <>
                                                {quotationDetails.property.propertyagent.prefix ? quotationDetails.property.propertyagent.prefix + '. ' : ''}{quotationDetails.property.propertyagent.fullname}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 p-5 text-end'>
                                    <span style={{ borderRadius: '25px', textAlign: 'center', width: '100px', padding: '5px 15px', color: 'white', fontWeight: 'bold', backgroundColor: quotationDetails.quotation_status === 'Y' ? 'green' : (quotationDetails.quotation_status === 'C' ? 'red' : 'orange') }}>
                                        {quotationDetails.quotation_status === 'Y' ? 'APPROVED' : (quotationDetails.quotation_status === 'C' ? 'CANCELLED' : 'APPROVAL PENDING')}
                                    </span>
                                </div>
                                <div className='col-md-12'>
                                    <Table>
                                        {quotationDetails.items.length > 0 && <>
                                            <TableHead className="bg-smoke text-body fs-md fw-bold">
                                                <TableRow>
                                                    <TableCell sx={{ width: '60%' }}><b>Description</b></TableCell>
                                                    <TableCell><b>Qty</b></TableCell>
                                                    <TableCell><b>Charge</b></TableCell>
                                                    <TableCell sx={{ width: '15%' }}><b>Subtotal</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {quotationDetails.items.map((item, index) =>
                                                    <TableRow key={'invrow_' + index}>
                                                        <TableCell>{item.picture && <Link to={item.picture} target='_blank'><img src={item.picture} alt={"Image-" + index} width='100px' /></Link>} {item.description}</TableCell>
                                                        <TableCell>{item.quantity ? item.quantity : 1}</TableCell>
                                                        <TableCell>{item.charge.toFixed(2)}</TableCell>
                                                        <TableCell className='text-end'>{item.amount.toFixed(2)}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </>}

                                        <TableFooter>
                                            <TableRow>
                                                <TableCell rowSpan={5}>

                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} className='bg-smoke' style={{ height: '40px' }}><h6 style={{ padding: 0, margin: 0 }}>Quotation Summary</h6></TableCell>
                                            </TableRow>
                                            {quotationDetails.credit > 0 &&
                                                <TableRow>
                                                    <TableCell className='text-end' colSpan={2} style={{ height: '40px' }}><b>Credit</b></TableCell>
                                                    <TableCell className='fw-bold text-end'>{quotationDetails.credit ? quotationDetails.credit.toFixed(2) : '0.00'}</TableCell>
                                                </TableRow>
                                            }
                                            <TableRow>
                                                <TableCell className='text-end' colSpan={2} style={{ height: '40px' }}><b>Subtotal</b></TableCell>
                                                <TableCell className='text-end'>{(quotationDetails.amount - quotationDetails.credit).toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='text-end' colSpan={2} style={{ height: '40px' }}><b>GST 10%</b></TableCell>
                                                <TableCell className='text-end'>{quotationDetails.tax_rate ? quotationDetails.tax_rate.toFixed(2) : '0.00'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='text-end bg-smoke2' colSpan={2}><h6>Total Amount</h6></TableCell>
                                                <TableCell className='text-end bg-smoke2'><h6>{quotationDetails.total ? quotationDetails.total.toFixed(2) : '0.00'}</h6></TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>

                            </div>
                        </div>
                    </Box>
                </Fragment >
            }
            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
            >
                <DialogTitle>Are you sure you want to approve this Quotation?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, click the Approve button below to process your quotation further and our technician will be looking forward to serve you better. The service report / invoice will be sent to you after completing the services as per this quotation.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => quotationApprove()} disabled={confirmLoader}>{confirmLoader ? <i className='fa fa-spin fa-gear'></i> : 'Approve'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Quotation;