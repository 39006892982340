import { useState, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Button, Drawer, Collapse, Alert, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { axiosFunction, clearSession } from "../common";
import { Quotation } from "../services/PropertyData";
import QuotationForm from "../components/Jobs/QuotationForm";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Quotations = () => {
    const navigate = useNavigate(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [formField, setFormField] = useState({ subtotal: 0, creditAmt: 0, tax_rate: 0, total: 0, balanceCredit: 0 });
    const [userOptions, setUserOptions] = useState(null);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [saveOption, setSaveOption] = useState('');
    const [quotationFor, setQuotationFor] = useState({ owner_id: '', property_id: '' });
    const [updateMainList, setUpdateMainList] = useState(false);
    const [invItems, setInvItems] = useState({ items: [{ description: "", quantity: 1, charge: "", amount: "" }] });
    const [formChangeFlag, setFormChangeFlag] = useState(false);
    //const [serviceUsersOptions, setServiceUsersOptions] = useState(null);
    //const [jobStatusOptions, setJobStatusOptions] = useState(null);
    const [cancelQuotation, setCancelQuotation] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [cancelLoader, setCancelLoader] = useState(false);
    const [sendLoader, setSendLoader] = useState(false);
    const [quotationSent, setQuotationSent] = useState('N');
    const [quotationId, setQuotationId] = useState('');
    const [quotationConfirmOpen, setQuotationConfirmOpen] = useState(false);

    const [xeroRotate, setXeroRotate] = useState('');

    const loadData = async () => {
        try {
            const userDataList = await axiosFunction('auth/users', 'post', {});
            if (userDataList.status === 'success') {
                setUserOptions(userDataList.data);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(userDataList.message);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAdd = () => {
        setFormField({ subtotal: 0, tax_rate: 0, total: 0, action: 'add' });
        setQuotationFor({ owner_id: '', property_id: '' });
        setInvItems({ items: [{ description: '', quantity: 1, charge: '', amount: '', picture: '' }] });
        setDrawerOpen(true);
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleQuotationCancel = (id) => {
        var data = {
            id: id,
            _method: 'PUT',
            action: 'cancel'
        }
        setCancelQuotation(data);
        setConfirmOpen(true);
    }

    const handleCancel = async () => {
        try {
            setCancelLoader(true);
            const cancelResponse = await axiosFunction('quotations/' + cancelQuotation.id, 'post', cancelQuotation);
            setAlertOpen(true);
            if (cancelResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(cancelResponse.message);
                handleAutoClose();
                setConfirmOpen(false);
                setCancelQuotation({});
                setUpdateMainList(true);
                setCancelLoader(false);
            } else {
                setAlertStatus('error');
                setAlertMessage(cancelResponse.message);
                setConfirmOpen(false);
                setCancelQuotation({});
                setCancelLoader(false);
            }

        } catch (e) {
            setConfirmOpen(false);
            setCancelQuotation({});
            setCancelLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
        setCancelQuotation({});
    };

    const handleSendQuotation = (id, sent) => {
        setQuotationSent(sent);
        setQuotationId(id);
        setQuotationConfirmOpen(true);
    }

    const handleSend = async () => {
        try {
            setSendLoader(true);
            const sendResponse = await axiosFunction('auth/sendquotation', 'post', { quotation_id: quotationId });
            setAlertOpen(true);
            if (sendResponse.status === 'success') {
                handleQuotationConfirmClose();
                setAlertStatus('success');
                setAlertMessage(sendResponse.message);
                handleAutoClose();
                setUpdateMainList(true);
                setSendLoader(false);
            } else {
                handleQuotationConfirmClose();
                setAlertStatus('error');
                setAlertMessage(sendResponse.message);
                setSendLoader(false);
            }

        } catch (e) {
            handleQuotationConfirmClose();
            setSendLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    const handleQuotationConfirmClose = () => {
        setQuotationSent('N');
        setQuotationId('');
        setQuotationConfirmOpen(false);
    };

    const handleQuotationXero = (id, xero_quotation_id) => {
        setQuotationId(id);
    }

    const handleDialogClose = () => {
        setQuotationFor({ owner_id: '', property_id: '' });
        setFormField({ subtotal: 0, creditAmt: 0, tax_rate: 0, total: 0, balanceCredit: 0 });
        setSaveOption('');
        setUpdateMainList(formChangeFlag);
        setDrawerOpen(false);
    }

    return (
        <>
            <SiteHeader menu='quotations' />
            <div className="user-panel">
                <div className="title text-start">Quotations</div>
                <div className="container-fluid">
                    <Dialog
                        open={quotationConfirmOpen}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>Are you sure you want to send this quotation?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {quotationSent === 'Y' && <span className="text-warning">Quotation has been already sent. </span>}
                                If yes, the quotation will be sent{quotationSent === 'Y' && ' again'}.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleQuotationConfirmClose} disabled={sendLoader}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleSend()} disabled={sendLoader}>{sendLoader ? <i className="fa fa-spin fa-gear"></i> : 'Yes'}</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={confirmOpen}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>Are you sure you want to cancel this quotation?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                If yes, quotation will be cancelled and cannot be changed after.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose} disabled={cancelLoader}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleCancel()} disabled={cancelLoader}>{cancelLoader ? <i className="fa fa-spin fa-gear"></i> : 'Yes'}</Button>
                        </DialogActions>
                    </Dialog>
                    <div className="row">
                        <div className="col-md-12">
                            <Collapse in={alertOpen}>
                                <Alert variant="filled" severity={alertStatus}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setAlertOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2, id: "alertmessage" }}
                                >
                                    {alertMessage}
                                </Alert>
                            </Collapse>
                        </div>
                        <div className="col-md-12 text-end">
                            <Button className="themeholy-btn btn-primary mb-3" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Quotation</Button>
                        </div>
                        <div className="col-md-12">
                            <Quotation
                                updateTabList={updateMainList}
                                setUpdateTabList={setUpdateMainList}
                                setInvItems={setInvItems}
                                setFormField={setFormField}
                                setDrawerOpen={setDrawerOpen}
                                handleQuotationCancel={handleQuotationCancel}
                                handleSendQuotation={handleSendQuotation}
                                handleQuotationXero={handleQuotationXero}
                                xeroRotate={xeroRotate}
                                setXeroRotate={setXeroRotate}
                                setQuotationFor={setQuotationFor}
                            />
                        </div>
                    </div>

                </div>
            </div>

            {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} PaperProps={{ className: 'container p-0' }}>
                <QuotationForm
                    formField={formField}
                    setFormField={setFormField}
                    userOptions={userOptions}
                    handleDialogClose={handleDialogClose}
                    saveOption={saveOption}
                    setSaveOption={setSaveOption}
                    quotationFor={quotationFor}
                    setQuotationFor={setQuotationFor}
                    invItems={invItems}
                    formChangeFlag={formChangeFlag}
                    setFormChangeFlag={setFormChangeFlag}
                    mainAlertStatus={setAlertStatus}
                    mainAlertOpen={setAlertOpen}
                    mainAlertMessage={setAlertMessage}
                    mainAlertAutoClose={handleAutoClose} />
            </Drawer>}

            <Footer />
        </>
    );
}

export default Quotations;