import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Typography, Table, TableBody, TableRow, TableCell, TableHead, Box
} from '@mui/material';
import dayjs from 'dayjs';
import { axiosFunction, clearSession } from '../../common';
import HomeIcon from '../../assets/img/HomeIcon.png';
import UserIcon from '../../assets/img/UserIcon.png';
import CalendarIcon from '../../assets/img/CalendarIcon.png';
import HashtagIcon from '../../assets/img/HashtagIcon.png';
import AgencyIcon from '../../assets/img/AgencyIcon.png';
import RadioCheckedIcon from '../../assets/img/RadioCheckedIcon.png';
import RadioUncheckedIcon from '../../assets/img/RadioUncheckedIcon.png';
import CheckboxCheckedIcon from '../../assets/img/CheckboxCheckedIcon.png';
import CheckboxUncheckedIcon from '../../assets/img/CheckboxUncheckedIcon.png';
import { CloudDownload } from '@mui/icons-material';
import Banner from "../../assets/img/Report_Invoice_Header.png";
import jsPDF from 'jspdf';

const ServiceReport = ({ upload, jobData }) => {
    const serviceReportRef = useRef(null);
    const navigate = useNavigate(null);
    const comp = useLocation();
    const servChkLstId = comp.pathname.split('/')[2];
    const maxWidth = '900px';
    const [serviceDetails, setServiceDetails] = useState(jobData);
    const [checklist_title, setChecklistTitle] = useState('');
    const [checklist_name, setChecklistName] = useState('');
    const [nextInspectDt, setNextInspectDt] = useState('');

    let category = '';
    let num = 1;
    let secTitle = { 'MAIN': 'SUPPLY MAINS', 'SWITBOARD': 'SWITCHBOARD', 'WIRING': 'WIRING AND ACCESSORIES', 'ELECAPPL': 'FIXED ELECTRICAL APPLIANCES', 'DISTRIBUTION': 'DISTRIBUTION BOARDS', 'GENERATOR': 'SUPPLEMENTARY/STANDBY ENERGY SUPPLY', 'AUDITEST': 'Audit Tests', 'GSINSTALL': 'Installation Check For Application', 'GSSERVICE': 'Servicing For Application', 'GSSPILLAGE': 'Spillage And Combustion Test For Application' }

    const [checklistData, setChecklistData] = useState([]);

    const [invoiceItems, setInvoiceItems] = useState([]);
    //let checkedItems = { 'AD': 'Adequate', 'AT': 'Attention Recommended', 'US': 'Un Safe', 'NA': 'N/A', 'Y': 'Yes', 'N': 'No', 'P': 'Pass', 'F': 'Fail' }

    let inspectnextdt = '';

    const CLfetchData = async () => {
        try {
            const reportData = await axiosFunction('servicereport/' + jobData.id, 'get', {});
            if (reportData.status === 'success') {
                setChecklistData(Object.values(reportData.data));
                if (jobData && !jobData.quotation_id) {
                    if (jobData.service_type === 'ES') {
                        setChecklistTitle('Electrical Safety Check Only');
                        setChecklistName('Electrical');
                    } else if (jobData.service_type === 'SA') {
                        setChecklistTitle('Smoke Alarm Safety Check Only');
                        setChecklistName('Smoke Alarm');
                    } else if (jobData.service_type === 'ESA') {
                        setChecklistTitle('Electrical & Smoke Alarm Safety Check');
                        setChecklistName('Electrical');
                    } else if (jobData.service_type === 'GS') {
                        setChecklistTitle('Gas Safety Check');
                        setChecklistName('Gas');
                    }

                    if (jobData.service_type !== 'SA' && jobData.next_job_due_date) {
                        inspectnextdt = jobData.next_job_due_date.split('/');
                        let date = new Date(inspectnextdt[2] + '-' + inspectnextdt[1] + '-' + inspectnextdt[0]);
                        setNextInspectDt(dayjs(date).format('DD/MM/YYYY'));
                    }
                }
                setTimeout(() => {
                    // Iterate through all images you want to magnify
                    const zimages = document.querySelectorAll('.magnify-image');
                    Array.from(zimages).forEach((image) => {
                        magnify(image, 3);
                    });
                }, 1000);
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const CLfetchDataWithService = async (servChkLstId) => {
        try {
            const reportData = await axiosFunction('auth/getservicereport/' + servChkLstId, 'get', {});

            if (reportData.status === 'success') {
                setServiceDetails(reportData.data.subservice);
                setChecklistData(Object.values(reportData.data.checklistData));
                setInvoiceItems(Object.values(reportData.data.invoiceItems));

                if (!reportData.data.subservice.quotation_id) {
                    //let pdfFilename = '';
                    if (reportData.data.subservice.service_type === 'ES') {
                        setChecklistTitle('Electrical Safety Check Only');
                        setChecklistName('Electrical');
                        //pdfFilename = 'Electrical_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                    } else if (reportData.data.subservice.service_type === 'SA' || reportData.data.subservice.service.product.code === 'PROD5LMSM') {
                        setChecklistTitle('Smoke Alarm Safety Check Only');
                        setChecklistName('Smoke Alarm');
                        //pdfFilename = 'Smoke_Alarm_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                    } else if (reportData.data.subservice.service_type === 'ESA') {
                        setChecklistTitle('Electrical & Smoke Alarm Safety Check');
                        //pdfFilename = 'Electrical_Smoke_Alarm_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                        setChecklistName('Electrical');
                    } else if (reportData.data.subservice.service_type === 'GS') {
                        setChecklistTitle('Gas Safety Check');
                        setChecklistName('Gas');
                        //pdfFilename = 'Gas_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                    }

                    if (reportData.data.subservice.service_type !== 'SA' && reportData.data.subservice.next_job_due_date) {
                        inspectnextdt = reportData.data.subservice.next_job_due_date.split('/');
                        let date = new Date(inspectnextdt[2] + '-' + inspectnextdt[1] + '-' + inspectnextdt[0]);
                        setNextInspectDt(dayjs(date).format('DD/MM/YYYY'));
                    }
                }
                setTimeout(() => {
                    // Iterate through all images you want to magnify
                    const zimages = document.querySelectorAll('.magnify-image');
                    Array.from(zimages).forEach((image) => {
                        magnify(image, 3);
                    });
                }, 1000);
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        if (jobData) {
            CLfetchData();
        } else if (servChkLstId) {
            CLfetchDataWithService(servChkLstId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servChkLstId]);

    // magnify hover
    function magnify(img, zoom) {
        var glass, w, h, bw;
        if (!img.parentElement.querySelector('.img-magnifier-glass')) {
            /*create magnifier glass:*/
            glass = document.createElement("DIV");
            glass.setAttribute("class", "img-magnifier-glass");
            /*insert magnifier glass:*/
            img.parentElement.insertBefore(glass, img);
        } else {

            glass = img.parentElement.querySelector('.img-magnifier-glass');
        }
        if (glass) {

            /*set background properties for the magnifier glass:*/
            glass.style.backgroundImage = "url('" + img.src + "')";
            glass.style.backgroundRepeat = "no-repeat";
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
            bw = 3;
            w = glass.offsetWidth / 2;
            h = glass.offsetHeight / 2;
            /*execute a function when someone moves the magnifier glass over the image:*/
            glass.addEventListener("mousemove", moveMagnifier);
            img.addEventListener("mousemove", moveMagnifier);
            /*and also for touch screens:*/
            glass.addEventListener("touchmove", moveMagnifier);
            img.addEventListener("touchmove", moveMagnifier);
        }
        function moveMagnifier(e) {
            var pos, x, y;
            /*prevent any other actions that may occur when moving over the image*/
            e.preventDefault();
            /*get the cursor's x and y positions:*/
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;
            /*prevent the magnifier glass from being positioned outside the image:*/
            if (x > img.width - (w / zoom)) {
                x = img.width - (w / zoom);
            }
            if (x < w / zoom) {
                x = w / zoom;
            }
            if (y > img.height - (h / zoom)) {
                y = img.height - (h / zoom);
            }
            if (y < h / zoom) {
                y = h / zoom;
            }
            /*set the position of the magnifier glass:*/
            glass.style.left = (x - w) + "px";
            glass.style.top = (y - h) + "px";
            /*display what the magnifier glass "sees":*/
            glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
        }

        function getCursorPos(e) {
            var a, x = 0,
                y = 0;
            e = e || window.event;
            /*get the x and y positions of the image:*/
            a = img.getBoundingClientRect();
            /*calculate the cursor's x and y coordinates, relative to the image:*/
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /*consider any page scrolling:*/
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {
                x: x,
                y: y
            };
        }
    }

    const handleImgDownload = async (imageUrl) => {
        try {
            // Fetch the image as a blob using Axios
            /* const response = await axios.get(imageUrl, {
                 responseType: 'blob', // Ensure the response is a Blob
             });*/

            const imagename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            const response = await axiosFunction('auth/downloadimage/' + imagename, 'get', {});

            if (response.status === 'success') {
                // Create a temporary URL for the blob
                // const url = window.URL.createObjectURL(new Blob([response.data_url]));
                const url = response.data_url;

                // Create an anchor element
                const link = document.createElement('a');
                link.href = url;

                // Set the download attribute with the desired file name
                link.download = imagename;

                // Append the link to the body, click it, and remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Revoke the blob URL to free up memory
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error downloading the image');
            }
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <Box ref={serviceReportRef} style={{ backgroundColor: '#FFFFFF', fontSize: '16px', textAlign: 'left', padding: '15px', fontFamily: 'helvetica', width: maxWidth }} className="container-md" id='pdfcontent'>
            {serviceDetails && <>
                <div style={{ height: '300px' }}>
                    <img alt="banner" src={Banner} width='100%' />
                    {serviceDetails.declaration && <div className='container' style={{ position: 'relative', width: '420px', top: '-5px', right: '15px', float: 'right', padding: '4px', margin: '0px', border: '4px solid ' + (['Compliant', 'Safe'].includes(serviceDetails.declaration) ? 'green' : 'red'), borderRadius: '5px', }}>
                        <h2 style={{ border: '2px dashed white', borderRadius: '5px', textAlign: 'center', width: 'auto', color: 'white', fontWeight: 'bold', backgroundColor: (['Compliant', 'Safe'].includes(serviceDetails.declaration) ? 'green' : 'red'), textTransform: 'uppercase', opacity: '0.9', padding: '5px', margin: '0px', fontFamily: 'helvetica' }}>
                            {serviceDetails.declaration}
                        </h2>
                    </div>}
                </div>

                <div className="container">
                    <Typography
                        sx={{ display: 'block', marginTop: '15px', marginBottom: '15px', fontFamily: 'helvetica' }}
                        component="h3"
                        variant="subtitle-1"
                        className='text-theme'
                    >
                        Service Report
                    </Typography>
                    <div className='row'>
                        <div className='col-6 mb-3'>
                            <div className='row'>
                                <div className='col-2'>
                                    <img src={HomeIcon} alt='HomeIcon' />
                                </div>
                                <div className='col-10 mb-2'>
                                    <div className='fw-bold'>Property Address: </div>
                                    <div>{(serviceDetails.service.property.address1 ? serviceDetails.service.property.address1 + ', ' : '') + (serviceDetails.service.property.address2 ? serviceDetails.service.property.address2 + ', ' : '')} <br />{serviceDetails.service.property.suburb + ', ' + serviceDetails.service.property.state + ' - ' + serviceDetails.service.property.postcode}</div>
                                </div>
                                <div className='col-2'>
                                    <img src={UserIcon} alt='HomeIcon' />
                                </div>
                                <div className='col-10'>
                                    <div className='fw-bold'>Landlord: </div>
                                    <div className='mb-2'>{serviceDetails.service.property.propertyowner && serviceDetails.service.property.propertyowner.prefix ? serviceDetails.service.property.propertyowner.prefix + '. ' : ''}{serviceDetails.service.property.propertyowner && serviceDetails.service.property.propertyowner.fullname}</div>
                                </div>
                                {serviceDetails.service.property.propertyagent && <>
                                    <div className='col-2'>
                                        <img src={UserIcon} alt='HomeIcon' />
                                    </div>
                                    <div className='col-10'>
                                        <div className='fw-bold'>Agent:</div>
                                        <div>{serviceDetails.service.property.propertyagent.prefix ? serviceDetails.service.property.propertyagent.prefix + '. ' : ''}{serviceDetails.service.property.propertyagent.fullname}</div>
                                    </div></>
                                }
                            </div>
                        </div>
                        <div className='col-6 mb-3'>
                            <div className='row fs-xs'>
                                <div className='col-2 text-md-end'><img src={CalendarIcon} alt='CalenderIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Job Date:</div>
                                <div className='col-4 text-md-end'> {serviceDetails.job_date}</div>
                                <div className='col-2 text-md-end'><img src={HashtagIcon} alt='HashtagIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Service Number:</div>
                                <div className='col-4 text-md-end'>{serviceDetails.id.toString().padStart(4, "0")}</div>
                                <div className='col-2 text-md-end'><img src={UserIcon} alt='UserIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Technician Name:</div>
                                <div className='col-4 text-md-end'>{serviceDetails.technician.prefix ? serviceDetails.technician.prefix + '. ' : ''}{serviceDetails.technician.fullname}</div>
                                <div className='col-2 text-md-end'><img src={HashtagIcon} alt='HashtagIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Licence Number:</div>
                                <div className='col-4 text-md-end mb-2'>{serviceDetails.technician.license_number}</div>
                                {serviceDetails.service.property.propertyagent && serviceDetails.service.property.propertyagent.agency && <>
                                    <div className='col-2 text-md-end'>
                                        <img src={AgencyIcon} alt='HomeIcon' />
                                    </div>
                                    <div className='col-10'>
                                        <div className='fw-bold'>Agency</div>
                                        <div>{serviceDetails.service.property.propertyagent.agency.fullname}</div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='row'>
                        <div className='col-1'><img src={serviceDetails.vacant_property === 'Y' ? CheckboxCheckedIcon : CheckboxUncheckedIcon} alt='CheckboxIcon' /></div>
                        <div className='col-11'>
                            Vacant Property

                            <p>In the event of a vacant property, it is assumed that permission has been given for myself to carry out the inspection on behalf of the rental provider or agent.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {serviceDetails.quotation_id ? <>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography
                                            variant='h6'
                                            className='sub-title'
                                        >
                                            Rectification Items
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {invoiceItems && invoiceItems.map((item, index) => (
                                    <TableRow key={"controw-" + index}>
                                        <TableCell>
                                            <div className='row'>
                                                <div className='col-12 col-md-4'>
                                                    {item.description}
                                                </div>
                                                <div className='col-12 col-md-8 black-color'>
                                                    <ul className='row'>
                                                        <li className='col-6 col-md-3'>
                                                            <div className={(item.status === 'Y' ? 'active' : '')}>
                                                                <img src={item.status === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                            </div>
                                                        </li>
                                                        <li className=' col-6 col-md-3'>
                                                            <div className={(item.status === 'N' ? 'active' : '')}>
                                                                <img src={item.status === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> No
                                                            </div>
                                                        </li>
                                                        <li className='col-6 col-md-3'>
                                                            <div className={(item.status === 'NA' ? 'active' : '')}>
                                                                <img src={item.status === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>))}
                            </TableBody>
                        </Table>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography
                                            variant='h6'
                                            className='sub-title'
                                        >
                                            Support Pictures for Application
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className='container'>
                                        <div className='row'>
                                            {serviceDetails.pictures.length > 0 ? serviceDetails.pictures.map((image, index) => (
                                                /*  <img key={"pic_" + index} src={image.data_url} alt={"image" + index} className='m-2' style={{ width: '450px' }} /> */
                                                <div className='card col-10 col-md-5 m-1' key={"pic_" + index}>
                                                    <div className='card-body image-item text-center'>
                                                        <img style={{}} width='100%' border="0" className="cover pstrart magnify-image" alt={"image" + index} src={image.data_url} />
                                                        <CloudDownload onClick={() => handleImgDownload(image.data_url)} style={{ color: 'blue' }} />
                                                    </div>
                                                </div>
                                            )) : <div className='col-10'>No photos have been taken.</div>}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </> : <>

                        <Typography
                            sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                            component="h6"
                            variant="subtitle-1"
                            className='text-theme'
                        >
                            {checklist_title}
                        </Typography>
                        {(serviceDetails.service_type === 'ESA' || serviceDetails.service_type === 'ES') &&
                            <>
                                <Table>
                                    {checklistData && checklistData.map((item, index) => (
                                        (item.category !== 'GENSUPPLY' || (item.category === 'GENSUPPLY' && serviceDetails.generator_supply !== 'NA')) &&
                                        <TableBody key={"cont-" + index}>
                                            {
                                                (item.category !== 'SWITINSTAL' && item.category !== 'DISBOARD' && item.category !== 'GENSUPPLY' && category !== item.category) && (
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography
                                                                variant='h6'
                                                                className='sub-title'
                                                            >
                                                                Part {num++} - {secTitle[category = item.category]}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            <TableRow>
                                                <TableCell>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4'>
                                                            {item.description}
                                                        </div>
                                                        <div className='col-12 col-md-8 black-color'>

                                                            {item.lkp_code === 'GENSUPPLY' ? <div className='black-color'>{serviceDetails.generator_supply}</div>
                                                                :
                                                                (item.lkp_code === 'SWITINSTAL' || item.lkp_code === 'DISBOARD') ?
                                                                    <ul className='row mt-2'>
                                                                        <li className='col-3 col-md-3'>
                                                                            <div className={(item.checked === 'Y' ? 'active ' : '')}>
                                                                                <img src={item.checked === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />Yes
                                                                            </div></li>
                                                                        <li className='col-3 col-md-3'>
                                                                            <div className={(item.checked === 'N' ? 'active ' : '') + " d-flex align-item-center"}>
                                                                                <img src={item.checked === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />No
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                    : (
                                                                        (item.lkp_code === 'SAFSWITCH') ?
                                                                            <div className='row'>
                                                                                <div className='fs-md col-12 d-flex align-items-top align-items-md-center justify-content-start'>

                                                                                    <img src={!serviceDetails.safety_switches[0] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />

                                                                                    <div className='pl-5'>Installed On All Socket Outlet Circuits</div>
                                                                                </div>
                                                                                <div className='fs-md col-12 d-flex align-items-center justify-content-start'>
                                                                                    <img src={!serviceDetails.safety_switches[1] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />
                                                                                    <div className='pl-5'>Installed on all lighting circuits</div>
                                                                                </div>
                                                                                <div className='fs-md col-12 d-flex align-items-top align-items-md-center justify-content-start'>
                                                                                    <img src={!serviceDetails.safety_switches[2] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />
                                                                                    <div className='pl-5'>Installed on all fixed equipment circuits</div>
                                                                                </div>
                                                                                <div className='fs-md col-12 d-flex align-items-center justify-content-start'>
                                                                                    <img src={!serviceDetails.safety_switches[3] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />
                                                                                    <div className='pl-5'>Other</div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            (item.category === 'AUDITEST') ?
                                                                                <ul className='row'>
                                                                                    <li className='col-4 col-md-3'>
                                                                                        <div className={(item.checked === 'P' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'P' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />Pass
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='col-4 col-md-3'>
                                                                                        <div className={(item.checked === 'F' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'F' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />Fail
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='col-4 col-md-3'>
                                                                                        <div className={(item.checked === 'NA' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='col-12 mt-2 black-color'><b>Comments:</b> {item.comments}</li>

                                                                                </ul>
                                                                                :
                                                                                <ul className='row'>
                                                                                    <li className='col-6 col-md-3'>
                                                                                        <div className={(item.checked === 'AD' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'AD' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Adequate
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className=' col-6 col-md-3'>
                                                                                        <div className={(item.checked === 'AT' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'AT' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Attention
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='col-6 col-md-3'>
                                                                                        <div className={(item.checked === 'US' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'US' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> UnSafe
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='col-6 col-md-3'>
                                                                                        <div className={(item.checked === 'NA' ? 'active' : '')}>
                                                                                            <img src={item.checked === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                    )}

                                                        </div>
                                                    </div>

                                                </TableCell>

                                            </TableRow>
                                            {item.category === 'DISTRIBUTION' && item.lkp_code === 'DISBOARD' && item.checked === 'Y' && (
                                                <><TableRow>
                                                    <TableCell>
                                                        <div className='row'>
                                                            <div className='col-12 col-md-6'>Phase Configuration
                                                            </div>
                                                            <div className='col-12 col-md-6 black-color'>
                                                                {serviceDetails.phase_config}
                                                            </div>
                                                        </div></TableCell>

                                                </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography
                                                                variant='h6'
                                                                className='sub-title'
                                                            >
                                                                Distribution Boards Rating
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow></>
                                            )}
                                        </TableBody>

                                    ))}
                                </Table>
                                <Table>
                                    {serviceDetails.audit_test && serviceDetails.audit_test.map((element, aindex) => (
                                        element.test && <TableBody key={"audit-" + aindex}>
                                            {aindex === 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography
                                                            variant='h6'
                                                            className='sub-title'
                                                        >
                                                            Additional Optional Tests
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            <TableRow>
                                                <TableCell>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-4'>
                                                            {element.test}
                                                        </div>
                                                        <div className='col-12 col-md-8'>

                                                            <ul className='row'>
                                                                <li className='col-4 col-md-3'>
                                                                    <div className={(element.result === 'P' ? 'active' : '')}>
                                                                        <img src={element.result === 'P' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Pass
                                                                    </div>
                                                                </li>
                                                                <li className='col-4 col-md-3'>
                                                                    <div className={(element.result === 'F' ? 'active' : '')}>
                                                                        <img src={element.result === 'F' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Fail
                                                                    </div>
                                                                </li>
                                                                <li className='col-4 col-md-3'>
                                                                    <div className={(element.result === 'NA' ? 'active' : '')}>
                                                                        <img src={element.result === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                                    </div>
                                                                </li>
                                                                <li className='col-12 mt-2 black-color'><b>Comments:</b> {element.comments}</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>

                                    ))}
                                </Table>
                            </>
                        }

                        {(serviceDetails.service_type !== 'ES' && (serviceDetails.service_type === 'ESA' || serviceDetails.service_type === 'SA')) &&
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography
                                                variant='h6'
                                                className='sub-title'
                                            >
                                                Safety Alarm
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <div className='row'>
                                                <div className='col-12'>All smoke alarm are correctly installed and in working condition; and have been tested according to the manufacturer's instructions.</div>
                                                <div className='col-12 mt-3'>
                                                    <ul className='row'>
                                                        <li className='col-4 col-md-2'>
                                                            <div className={(serviceDetails.sa_check === 'Y' ? 'active ' : '')}>
                                                                <img src={serviceDetails.sa_check === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                            </div>
                                                        </li>
                                                        <li className='col-4 col-md-2'>
                                                            <div className={(serviceDetails.sa_check === 'N' ? 'active ' : '')}>
                                                                <img src={serviceDetails.sa_check === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> No
                                                            </div>
                                                        </li>
                                                        <li className='col-4 col-md-2'>
                                                            <div className={(serviceDetails.sa_check === 'NA' ? 'active ' : '')}>
                                                                <img src={serviceDetails.sa_check === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {serviceDetails.sa_check === 'Y' &&
                                        <TableRow>
                                            <TableCell className='fw-bold'>Next smoke alarms check is due by: <span className='black-color'>{serviceDetails.sa_next_due_date}</span></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        }

                        {(serviceDetails.service_type === 'ESA' || serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA') &&
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography
                                                variant='h6'
                                                className='sub-title'
                                            >
                                                Support Pictures for Application
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} className='container'>
                                            <div className='row'>
                                                {serviceDetails.pictures.length > 0 ? serviceDetails.pictures.map((image, index) => (
                                                    /*                                                 <img key={"pic_" + index} src={image.data_url} alt={"image" + index} className='m-2' style={{ width: '380px' }} />
                                                     */
                                                    <div className='card col-10 col-md-5 m-1' key={"pic_" + index}>
                                                        <div className='card-body image-item text-center'>
                                                            <img style={{}} width='100%' border="0" className="cover pstrart magnify-image" alt={"image" + index} src={image.data_url} />
                                                            <CloudDownload onClick={() => handleImgDownload(image.data_url)} style={{ color: 'blue' }} />
                                                        </div>
                                                    </div>
                                                )) : <div className='col-10'>No photos have been taken.</div>}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }

                        {serviceDetails.service_type === 'GS' &&
                            <>

                                <Table key="gascontenttable">
                                    {checklistData && checklistData.map((item, index) => <Fragment key={"gascont-" + index}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='text-center' colSpan={2}><h5>Gas Appliance {parseInt(index) + 1}</h5></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {item.checklist && item.checklist.map((chklist, cindex) => (
                                            <TableBody key={"gascont-" + index + "-" + cindex}>
                                                {(chklist.code === 'GASNAME' || chklist.category === 'GSQUESTION') ?
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className='row'>
                                                                <div className='col-12 col-md-5'>
                                                                    {chklist.description}
                                                                </div>
                                                                <div className='col-12 col-md-7 black-color'>
                                                                    {
                                                                        (chklist.code === 'GASNAME') ? chklist.comments :
                                                                            <ul className='row'>
                                                                                <li className='col-4 col-md-4'>
                                                                                    <div className={(chklist.checked === 'Y' ? 'active ' : '')}>
                                                                                        <img src={chklist.checked === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' className='m-auto' /> Yes
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-4 col-md-4'>
                                                                                    <div className={(chklist.checked === 'N' ? 'active ' : '')}>
                                                                                        <img src={chklist.checked === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' className='m-auto' /> No
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-4 col-md-4'>
                                                                                    <div className={(chklist.checked === 'NA' ? 'active ' : '')}>
                                                                                        <img src={chklist.checked === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' className='m-auto' />
                                                                                        N/A
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow> : <>
                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Typography
                                                                    component="div"
                                                                    className="sec-title fw-bold"
                                                                >
                                                                    {chklist.description}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={2} className='black-color container'>
                                                                <div className="row">
                                                                    {
                                                                        (chklist.code === 'GASCOMMENT') ? <div className='col-10'>{chklist.comments}</div> : chklist.pictures && chklist.pictures.map((image, mi) => (
                                                                            <div className='card col-md-5 p-0 m-1'>
                                                                                <div className="card-body image-item text-center">
                                                                                    <img style={{}} width='100%' border="0" className="cover pstrart magnify-image" alt={"image" + index} src={image.data_url} />
                                                                                    <CloudDownload onClick={() => handleImgDownload(image.data_url)} style={{ color: 'blue' }} />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        ))}
                                    </Fragment>)}
                                </Table>
                                <div className='container'>
                                    <div className="row mt-3">
                                        <div className='col-12'>
                                            Appliance servicing Regulation 12(4) of the Gas Safety (Gas Installation)
                                            Regulations 2018 prescribes AS4575 as the standard for Type A appliance servicing work on an appliance that is part of a standard gas installation
                                            <p className='fw-bold'>
                                                I have serviced all appliances in accordance with AS 4575
                                            </p>
                                        </div>
                                        <div className='col-12 mb-3'>
                                            <ul className='row'>
                                                <li className='col-34 col-md-2'>
                                                    <div className={(serviceDetails.all_service_done === 'Y' ? 'active ' : '')}>
                                                        <img src={serviceDetails.all_service_done === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                    </div>
                                                </li>
                                                <li className='col-4 col-md-2'>
                                                    <div className={(serviceDetails.all_service_done === 'N' ? 'active ' : '')}>
                                                        <img src={serviceDetails.all_service_done === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> No
                                                    </div>
                                                </li>
                                                <li className='col-4 col-md-2'>
                                                    <div className={(serviceDetails.all_service_done === 'NA' ? 'active ' : '')}>
                                                        <img src={serviceDetails.all_service_done === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-12'>
                                            <p>I have created a record (VBA online) under regulation 36(2) or 37(2) of
                                                the Gas Safety (Gas Installation) Regulations 2018 and provided a copy
                                                to the rental provider under the regulation 30 (1)(ab) of the Residential
                                                Tenancies Regulations 2021</p>
                                        </div>
                                        <div className='col-12'>
                                            <ul className='row'>
                                                <li className='col-4 col-md-2'>
                                                    <div className={(serviceDetails.created_record === 'Y' ? 'active ' : '')}>
                                                        <img src={serviceDetails.created_record === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                    </div>
                                                </li>
                                                <li className='col-4 col-md-2'>
                                                    <div className={(serviceDetails.created_record === 'N' ? 'active ' : '')}>
                                                        <img src={serviceDetails.created_record === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />  No
                                                    </div>
                                                </li>
                                                <li className='col-4 col-md-2'>
                                                    <div className={(serviceDetails.created_record === 'NA' ? 'active ' : '')}>
                                                        <img src={serviceDetails.created_record === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />  N/A
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {serviceDetails.service_type !== 'GS' &&
                            <Table>
                                <TableBody>

                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography
                                                variant='h6'
                                                className='sub-title'
                                            >
                                                Observations and Recommendations
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography>
                                                The following observations and recommendations are made:
                                            </Typography>
                                            <div className="fs-xs">{serviceDetails.recommendation ? serviceDetails.recommendation : 'Nil'}</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography>
                                                The following work is required for compliance purposes:
                                            </Typography>
                                            <div className="fs-xs">{serviceDetails.compliance ? serviceDetails.compliance : 'Nil'}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }

                        {serviceDetails.service_type === 'GS' &&
                            <Table sx={{ marginTop: '15px' }}>
                                <TableHead className="bg-smoke text-body">
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                className="sec-title fw-bold"
                                            >
                                                Dangerous Gas Installations
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Gas Safety (Gas Installation) Regulations 2018, Part 3, Division 3, Section 21)
                                            <ol>
                                                <li className="mb-2">If a person carrying out gas fitting work on a gas installation becomes
                                                    aware of a danger arising from a defect in the gas installation, the
                                                    person must without delay-
                                                    <br />a) take all steps that are necessary to make the installation safe; and
                                                    <br />b) notify the owner of the gas installation and the occupier of the
                                                    premises in which the installation is situated of the defect</li>
                                                <li className="mb-2">Sub regulation (1)(a) does not apply if the person is unable, or it is
                                                    unreasonable for the person, to take the necessary steps to make the
                                                    gas installation safe</li>
                                                <li className="mb-2">If the person carrying out the gas fitting work is unable, or it is
                                                    unreasonable for the person, to make the gas installation safe, he or
                                                    she must, without delay, notify Energy Safe Victoria and —
                                                    <br />a) if the gas installation uses natural gas, the gas distribution company
                                                    which supplies that gas to the gas installation of the defect; or
                                                    <br />b) if the gas installation uses LPG, the gas retailer which supplies that
                                                    gas to the gas installation of the defect.</li>
                                            </ol>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }
                    </>}

                    <Table sx={{ marginTop: '15px', width: '100%' }}>
                        <TableHead className="bg-smoke text-body">
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        component="div"
                                        className="sec-title fw-bold"
                                    >
                                        Declaration
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {(serviceDetails.service_type === 'ESA' || serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA') ? <>
                                        <p>I, being the person responsible for the audit of the {checklist_title} installation (as indicated by my approval below), particulars of which are described above, having exercised reasonable skill and care when carrying out the audit, hereby declare that the information in this report, including the observations, provides an accurate assessment of the condition of the {checklist_title} installation taking into account the stated extent of the audit and the limitations of the visual inspection and testing.</p>
                                        <div className='mb-3'>I further declare that in my judgment, the condition of the said installation was (below selection) at the time the audit was carried out. This does not preclude the possibility that other defects may exist.
                                        </div>
                                    </> : <>
                                        <p>I, being the person responsible for the inspection of the identified gas
                                            appliances or installations in the rental property or rooming house, particulars
                                            of which are described here, having exercised reasonable skill and care when
                                            carrying out the inspection, hereby declare on the date of inspection that the
                                            information in this report, including the observations and recommendations,
                                            provides an accurate assessment of the condition of the gas appliances or
                                            installations in the rental property or rooming house taking into account the
                                            stated extent of the installation and the limitations of the inspection and
                                            testing.</p>
                                        <div className='mb-3'>I further declare that in my judgment, the said appliance(s) and corresponding
                                            installation(s) is/are: (Select one of the below as applicable)
                                        </div>
                                    </>
                                    }

                                    <ul className='row mb-3'>
                                        {serviceDetails.service_type === 'GS' ? <>
                                            <li className='col-12'>
                                                <div className={(serviceDetails.declaration === 'Compliant' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'Compliant' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> <b>Compliant</b> (Gas appliance or gas installation complies with the criteria for a "gas safety check" in the residential tenancies regulations)
                                                </div>
                                            </li>
                                            <li className='col-12'>
                                                <div className={(serviceDetails.declaration === 'Non-Compliant' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'Non-Compliant' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> <b>Non-Compliant</b> (No immediate risk, however the customer should be advised that remedial work is required to be carried out to bring the gas appliance or its installation up to standard.)
                                                </div>
                                            </li>
                                            <li className='col-12'>
                                                <div className={(serviceDetails.declaration === 'UnSafe' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'UnSafe' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> <b>UnSafe</b> (Gas appliance or its installation is unsafe and requires disconnection and urgent work as the safety of persons may be at risk or there may be damage to property)
                                                </div>
                                            </li>
                                        </> : <>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.declaration === 'Safe' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'Safe' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Safe
                                                </div>
                                            </li>
                                            <li className='col-5 col-md-2'>
                                                <div className={(serviceDetails.declaration === 'UnSafe' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'UnSafe' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> UnSafe
                                                </div>
                                            </li></>}
                                    </ul>
                                </TableCell>
                            </TableRow>
                            {!serviceDetails.quotation_id && serviceDetails.service_type !== 'SA' &&
                                <TableRow>
                                    <TableCell className='fw-bold'>
                                        Next Inspection Due Date: <span className='black-color'>{nextInspectDt}</span>
                                    </TableCell>
                                </TableRow>}
                            <TableRow>
                                <TableCell>
                                    {(serviceDetails.service_type === 'ESA' || serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA') ? <div>
                                        I the above named licenced electrician have carried out an {checklist_name} safety check of this residential tenancies per the
                                        requirements of the Residential Tenancies Regulations 2021 and set out in the Australian/New Zealand Standard
                                        AS/NZS 3019, "{checklist_name} installations—Periodic verification, and have recorded my observations and
                                        recommendations.</div>
                                        : <div>I declare that I have provided all the information in this compliance check
                                            including the estimated quote of parts cost:</div>}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='fw-bold'>
                                    Signature of technician <br /><br />
                                    <img src={serviceDetails.signature && serviceDetails.signature[0]} alt="Technician Signature" className='m-3' style={{ height: '50px' }} />
                                    <div className='black-color'>
                                        {serviceDetails.technician.prefix ? serviceDetails.technician.prefix + '. ' : ''}{serviceDetails.technician.fullname}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </>}
        </Box >
    )
}
export default ServiceReport;