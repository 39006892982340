import { useState, useEffect, createContext } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Backdrop } from "@mui/material";
//import { UserData } from '../services/UsersData';
import { ServiceUsersData } from '../services/ServiceUsersData';
import { JobStatusData } from '../services/JobStatusData';
import { ScheduleStatusData } from '../services/ScheduleStatusData';
//import { Link } from "react-router-dom";
//import { useNavigate } from 'react-router-dom';

import { clearSession } from '../common';

//import Properties from "../components/Jobs/Properties";
//import Filters from "../components/Jobs/Filters";
import ManageJobs from "../components/Jobs/ManageJobs";

export const JobContext = createContext(null);

const Jobs = () => {
    //const navigate = useNavigate(null);
    const [backdropopen, setBackDropOpen] = useState(false);
    // const [userOptions, setUserOptions] = useState(null);
    const [serviceUsersOptions, setServiceUsersOptions] = useState([]);
    //const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [jobStatusOptions, setJobStatusOptions] = useState([]);
    const [scheduleStatusOptions, setScheduleStatusOptions] = useState([]);
    //const [showJobs, setshowJobs] = useState(true);
    //const [propertyId, setPropertyId] = useState(0);
    //const [pcodeValue, setPcodeValue] = useState();
    //const [ownerValue, setOwnerValue] = useState();
    //const [agentValue, setAgentValue] = useState();
    //const [doFilter, setDoFilter] = useState(false);

    const loadData = () => {
        /* PostcodeData.fetchData('job').then(postCodeDataList => {
            setPostcodeOptions(postCodeDataList);
            UserData.fetchData('job').then(userDataList => {
                setUserOptions(userDataList); */
        ServiceUsersData.fetchData().then(serviceUsersDataList => {
            setServiceUsersOptions(serviceUsersDataList);

            JobStatusData.fetchData().then(jobStatusDataList => {
                setJobStatusOptions(jobStatusDataList);
                //setshowJobs(true);  
                ScheduleStatusData.fetchData().then(scheduleStatusDataList => {
                    setScheduleStatusOptions(scheduleStatusDataList);
                    //setshowJobs(true);                        
                });
            });
        });
        /*  });
     });     */
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* const handleClose = (event, reason) => {
        //if (reason && reason === "backdropClick") return false;

        setBackDropOpen(false);
    };
 */
    const handleLogout = () => {
        clearSession();
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <SiteHeader menu='jobs' />
            <div className="user-panel">
                <div className="title text-start">Jobs</div>
                <div className="container-fluid">
                    <JobContext.Provider value={
                        {
                            setBackDropOpen,
                            handleLogout,
                            serviceUsersOptions,
                            jobStatusOptions,
                            scheduleStatusOptions
                        }
                    }>
                        <div className="row">
                            {/*<div className="col-md-12 pt-2 pb-5">
                                {showFilters && <Filters />}
                            </div>
                             <div className="col-md-3"><Properties filter={doFilter} /></div> */}
                            <div className="col-md-12">
                                <ManageJobs />
                            </div>
                        </div>
                    </JobContext.Provider>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Jobs;