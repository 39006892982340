import * as React from 'react';
import { Link } from "react-router-dom";
import { Card, Chip, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

export default function ListBox({ data, title, nodatamsg, listtype, linktext }) {
  return (
    <Card className='w-100 text-start'>
      <Typography variant="h6" className='p-3'>
        {title}
      </Typography>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {data.length > 0 ? data.map((value, index) => {
          const labelId = `label-${index}`;
          return (
            <ListItem
              key={index}
              alignItems="flex-start"
              className='list-box m-0 p-0'
            >
              <ListItemButton>
                <ListItemText id={labelId} primary={"Service Date: " + (value.job_date ? value.job_date : value.service_date)}
                  secondary={
                    <>
                      {listtype === 'properties' && <>{value.status === 'R' ? <Chip label="Ready to assign" color="error" size="small" sx={{ marginRight: '15px' }} /> : <Chip label="Pending" color="secondary" size="small" sx={{ marginRight: '15px' }} />}</>}
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {(value.address1 ? value.address1 + ', ' : '') + (value.address2 ? value.address2 + ', ' : '')}
                      </Typography>
                      {value.suburb + ', ' + value.state + ' - ' + value.postcode + '.'}
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        }) : (
          <div className='text-center'>
            {nodatamsg}
          </div>
        )}
        <div className='mt-5 text-center'><Link to={'/' + listtype}>{linktext}</Link></div>
      </List>
    </Card>
  );
}