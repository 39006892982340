import { axiosFunction, clearSession } from '../common';
export const ScheduleStatusData = {
    // eslint-disable-next-line
    async fetchData() {
        try {
            const response = await axiosFunction('auth/schedulestatus', 'post', {});

            if (response.status === 'success') {
                return response.data;
            } else {
                /* if (response?.response?.status === 401) {
                    clearSession();
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
            } else {
                console.log(e);
            }
        }
    }
};