import { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { checkPermission } from '../../common';
import { Quotation, Invoice, PaymentHistory, Activities, Appointments, ChangeLogs, CommunicationHistory, ECertificate, ServiceReport } from '../../services/PropertyData';

export const PropertyTabs = ({ propertyId, handlePay, updateTabList, setUpdateTabList }) => {
    const [value, setValue] = useState("quote");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabContext value={value}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: '1px solid #cccccc' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: "#000000"
                        }
                    }}
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs"

                >
                    {checkPermission('PROPERTY', 'viewquotation') && <Tab label="Quotation" value="quote" sx={{ fontWeight: 'bold' }} />}
                    {checkPermission('PROPERTY', 'viewinvoice') && <Tab label="Invoice" value="invoice" sx={{ fontWeight: 'bold' }} />}
                    {checkPermission('PROPERTY', 'viewpayment') && <Tab label="Payment History" value="payment" sx={{ fontWeight: 'bold' }} />}
                    {checkPermission('PROPERTY', 'viewactivity') && <Tab label="Activities" value="activity" sx={{ fontWeight: 'bold' }} />}
                    {checkPermission('PROPERTY', 'viewappointment') && <Tab label="Appointments" value="appointment" sx={{ fontWeight: 'bold' }} />}
                    {checkPermission('PROPERTY', 'viewlog') && <Tab label="Change Logs" value="logs" sx={{ fontWeight: 'bold' }} />}
                    {checkPermission('PROPERTY', 'viewreport') && <Tab label="Service Report" value="service" sx={{ fontWeight: 'bold' }} />}
                    {/* <Tab label="E-Certificate" value="certificate" sx={{ fontWeight: 'bold' }} /> */}
                    {checkPermission('PROPERTY', 'viewcommunication') && <Tab label="Communication History" value="communication" sx={{ fontWeight: 'bold' }} />}
                </Tabs>
            </Box>
            <TabPanel value="quote" className='tabpanel'>
                <Quotation propertyId={propertyId} updateTabList={updateTabList} setUpdateTabList={setUpdateTabList} />
            </TabPanel>
            <TabPanel value="invoice" className='tabpanel'>
                <Invoice propertyId={propertyId} handlePay={handlePay} updateTabList={updateTabList} setUpdateTabList={setUpdateTabList} />
            </TabPanel>
            <TabPanel value="payment" className='tabpanel'>
                <PaymentHistory propertyId={propertyId} updateTabList={updateTabList} />
            </TabPanel>
            <TabPanel value="activity" className='tabpanel'>
                <Activities propertyId={propertyId} />
            </TabPanel>
            <TabPanel value="appointment" className='tabpanel'>
                <Appointments propertyId={propertyId} />
            </TabPanel>
            <TabPanel value="logs" className='tabpanel'>
                <ChangeLogs propertyId={propertyId} />
            </TabPanel>
            <TabPanel value="service" className='tabpanel'>
                <ServiceReport propertyId={propertyId} />
            </TabPanel>
            <TabPanel value="certificate" className='tabpanel'>
                <ECertificate propertyId={propertyId} />
            </TabPanel>
            <TabPanel value="communication" className='tabpanel'>
                <CommunicationHistory propertyId={propertyId} updateTabList={updateTabList} />
            </TabPanel>
        </TabContext>
    );
}