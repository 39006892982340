import { useState, useEffect, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import DataTableList from '../components/DataTableList';
import { Chip, Button, Tooltip } from "@mui/material";
import ServiceReportDialog from "../components/Modal/ServiceReport";
import CertificateDialog from "../components/Modal/CertificateDialog";
import InvoiceDialog from "../components/Modal/InvoiceDialog";
import QuotationDialog from "../components/Modal/QuotationDialog";
import { axiosFunction, checkPermission, clearSession, getSessionItem } from '../common';
import dayjs from 'dayjs';
import { Shield, RemoveRedEyeRounded, Done, DoneAll } from '@mui/icons-material';
import XeroSync from "../assets/img/xerosync.png";
import XeroLogo from "../assets/img/xero.png";

export const Quotation = ({ propertyId, updateTabList, setUpdateTabList, setInvItems, setFormField, setDrawerOpen, handleQuotationCancel, handleSendQuotation, setQuotationFor }) => {
    const navigate = useNavigate(null);
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [quotationDialogOpen, setQuotationDialogOpen] = useState(false);
    const [quotationData, setQuotationData] = useState({});

    const fetchQuotationData = async () => {
        try {
            let params = {};
            if (propertyId) params.property_id = propertyId;
            const response = await axiosFunction('quotations', 'get', params);
            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
                setUpdateTabList(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchQuotationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const handleView = (quotationData) => {
        setQuotationData(quotationData);
        setQuotationDialogOpen(true);
    }

    const handleQuotationDialogClose = () => {
        setQuotationData({});
        setQuotationDialogOpen(false);
    }

    const handleEdit = (data) => {
        setFormField({ quotation_id: data.id, quotation_number: data.quotation_number, subtotal: data.amount, tax_rate: data.tax_rate, total: data.total, action: 'edit' });
        setQuotationFor({ owner_id: data.owner_id, agent_id: data.agent_id, property_id: data.property_id });

        setInvItems(data);
        setDrawerOpen(true);
    }

    const viewQuotationNumber = (invno, sent, view, quotation_status) => {
        return (
            <div className="d-flex align-items-center">
                {quotation_status === 'Y' && <div style={{ width: '18px' }}><Tooltip title="Approved" placement="top" arrow ><i className="fa fa-certificate text-success"></i></Tooltip></div>} <div className="m-2">{invno}</div> <div>{view === 'Y' ? <Tooltip title="Quotation Viewed" placement="top" arrow ><DoneAll fontSize="small" color="success" /></Tooltip> : (sent === 'Y' ? <Tooltip title="Quotation Sent" placement="top" arrow ><Done fontSize="small" color="primary" /></Tooltip> : <Tooltip title="Quotation Not Sent" placement="top" arrow ><Done fontSize="small" color="disabled" /></Tooltip>)}</div>
            </div>
        )
    }

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'quotation_number', headerName: 'Quote #', width: 150, filterable: true, renderCell: (params) => viewQuotationNumber(params.row.quotation_number, params.row.is_sent, params.row.is_viewed, params.row.quotation_status), valueGetter: (value, row) => row.quotation_number },
        {
            field: 'quotation_date', headerName: 'Quote Date', width: 125, filterable: true, type: 'date',
            valueFormatter: (value) => dayjs(value.split('/').reverse().join('/')).format('DD/MM/YYYY')
        },
        { field: 'description', headerName: 'Description', width: 250, filterable: true, renderCell: (params) => propertyId ? (params.row.description ? params.row.description : 'Rectification - Quotation') : (params.row.property ? <>{params.row.property.address1 ? params.row.property.address1 + ', ' : ''}{params.row.property.address2 ? params.row.property.address2 + ', ' : ''}{params.row.property.suburb}, {params.row.property.state} - {params.row.property.postcode}</> : <>{params.row.services.property.address1 ? params.row.services.property.address1 + ', ' : ''}{params.row.services.property.address2 ? params.row.services.property.address2 + ', ' : ''}, {params.row.services.property.suburb}, {params.row.services.property.state} - {params.row.services.property.postcode}</>), valueGetter: (value, row) => propertyId ? (row.description ? row.description : 'Rectification - Quotation') : (row.property ? (row.property.address1 ? row.property.address1 : '') + ' ' + (row.property.address2 ? row.property.address2 : '') + ' ' + row.property.suburb + ' ' + row.property.state + ' ' + row.property.postcode : ((row.services.property.address1 ? row.services.property.address1 : '') + ' ' + (row.services.property.address2 ? row.services.property.address2 : '') + ' ' + row.services.property.suburb + ' ' + row.services.property.state + ' ' + row.services.property.postcode)) },
        { field: 'amount', type: 'number', headerName: 'Amount', width: 100, filterable: true, renderCell: (params) => params.row.amount ? params.row.amount.toFixed(2) : '0.00' },
        { field: 'subtotal', type: 'number', headerName: 'Subtotal', width: 100, filterable: true, renderCell: (params) => params.row.amount ? (params.row.amount - params.row.credit).toFixed(2) : '0.00' },
        { field: 'tax_rate', type: 'number', headerName: 'Tax Rate', width: 100, filterable: true, renderCell: (params) => params.row.tax_rate ? params.row.tax_rate.toFixed(2) : '0.00' },
        { field: 'total', type: 'number', headerName: 'Total', width: 100, filterable: true, renderCell: (params) => params.row.total ? params.row.total.toFixed(2) : '0.00' },
        { field: 'quotation_status', headerName: 'Status', width: 150, sortable: false, filterable: false, renderCell: (params) => (params.row.quotation_status === 'Y') ? <Chip label="Approved" color="success" size="small" /> : (params.row.quotation_status === 'N' ? <Chip label="Pending" color="warning" size="small" /> : <Chip label="Cancelled" color="error" size="small" />), valueGetter: (value, row) => (row.quotation_status === 'Y') ? 'Approved' : (row.quotation_status === 'N' ? 'Pending' : 'Cancelled') },
        {
            field: 'actions', headerName: 'Actions', width: 180, sortable: false, renderCell: (params) => {
                return (<>
                    <Tooltip title="View Quotation" placement="top" arrow ><Button type="button" onClick={(e) => handleView(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-file-invoice"></i></Button></Tooltip>

                    {checkPermission('JOB', 'quotation') && !propertyId &&
                        <>
                            <Tooltip title="Send Quotation" placement="top" arrow ><Button type="button" onClick={(e) => handleSendQuotation(params.row.id, params.row.is_sent)} style={{ minWidth: '24px' }}><i className="fa fa-paper-plane"></i></Button></Tooltip>
                            {(params.row.quotation_status === 'N' || params.row.quotation_status === null) &&
                                <>
                                    <Tooltip title="Edit Quotation" placement="top" arrow ><Button type="button" onClick={() => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>
                                    <Tooltip title="Cancel Quotation" placement="top" arrow ><Button type="button" onClick={() => handleQuotationCancel(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-circle-xmark"></i></Button></Tooltip>
                                </>}
                        </>}

                </>)
            }
        }
    ];

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {quotationDialogOpen && <QuotationDialog quotationDialogOpen={quotationDialogOpen} handleQuotationDialogClose={handleQuotationDialogClose} quotationData={quotationData} />}
        </Fragment>
    );
}

export const Invoice = ({ propertyId, handlePay, updateTabList, setUpdateTabList, setInvItems, setFormField, setDrawerOpen, setOwnerCredit, handleInvoiceCancel, handleSendInvoice, handleInvoiceXero, xeroRotate, setXeroRotate }) => {

    const navigate = useNavigate(null);
    const _role = getSessionItem('_role');
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});

    const fetchInvoiceData = async () => {
        try {
            let params = {};
            if (propertyId) params.property_id = propertyId;
            const response = await axiosFunction('invoices', 'get', params);
            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
                setUpdateTabList(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchInvoiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const handleView = (invoiceData) => {
        setInvoiceData(invoiceData);
        setInvoiceDialogOpen(true);
    }


    const handleInvoiceDialogClose = () => {
        setInvoiceData({});
        setInvoiceDialogOpen(false);
    }

    const handleEdit = (data) => {
        setFormField({ invoice_id: data.id, xero_invoice_number: data.xero_invoice_number, invoice_number: data.invoice_number, subtotal: data.amount, creditAmt: data.credit, tax_rate: data.tax_rate, total: data.total, balanceCredit: data.property.propertyowner.credit, extCreditAmt: data.credit, extBalanceCredit: data.property.propertyowner.credit, action: 'edit' });
        setOwnerCredit(data.property.propertyowner.credit);
        setInvItems(data);
        setDrawerOpen(true);
    }

    const viewInvoiceNumber = (invno, sent, view, is_xero_update) => {
        return (
            <div className="d-flex align-items-center">
                {is_xero_update === 'Y' && <div style={{ width: '18px' }}><Tooltip title="Synchronized with XERO" placement="top" arrow ><img src={XeroLogo} alt='Synchronized with XERO' /></Tooltip></div>} <div className="m-auto">{invno}</div> {invno && <div>{view === 'Y' ? <Tooltip title="Invoice Viewed" placement="top" arrow ><DoneAll fontSize="small" color="success" /></Tooltip> : (sent === 'Y' ? <Tooltip title="Invoice Sent" placement="top" arrow ><Done fontSize="small" color="primary" /></Tooltip> : <Tooltip title="Invoice Not Sent" placement="top" arrow ><Done fontSize="small" color="disabled" /></Tooltip>)}</div>}
            </div>
        )
    }

    const personDetails = (params, ptype, isval) => {
        let propertyDetails = null;
        let personDetailsState = null;

        if (params.services) {
            personDetailsState = params.services.property;
        } else {
            personDetailsState = params.property;
        }

        if (ptype === 'agent') {
            if (isval) {
                propertyDetails = personDetailsState.propertyagent ? personDetailsState.propertyagent.agency && personDetailsState.propertyagent.agency.fullname + ' ' + (personDetailsState.propertyagent.prefix ? personDetailsState.propertyagent.prefix + '.' : '') + ' ' + personDetailsState.propertyagent.fullname + ' ' + (personDetailsState.propertyagent.individualuser ? personDetailsState.propertyagent.individualuser.email + ' ' + personDetailsState.propertyagent.individualuser.mobile : '') : '';
            } else {
                propertyDetails = personDetailsState.propertyagent ? <div>{personDetailsState.propertyagent.agency && <div>A: {personDetailsState.propertyagent.agency.fullname}</div>}<div>PM: {personDetailsState.propertyagent.prefix ? personDetailsState.propertyagent.prefix + '.' : ''} {personDetailsState.propertyagent.fullname}</div>{personDetailsState.propertyagent.individualuser && <><div>E: {personDetailsState.propertyagent.individualuser.email}</div><div>M: {personDetailsState.propertyagent.individualuser.mobile}</div></>}</div> : '';
            }
        } else {
            if (isval) {
                propertyDetails = personDetailsState.propertyowner ? (personDetailsState.propertyowner.prefix ? personDetailsState.propertyowner.prefix + '.' : '') + ' ' + personDetailsState.propertyowner.fullname + ' ' + (personDetailsState.propertyowner.individualuser ? personDetailsState.propertyowner.individualuser.email + ' ' + personDetailsState.propertyowner.individualuser.mobile : '') : '';
            } else {
                propertyDetails = personDetailsState.propertyowner ? <div><div>N: {personDetailsState.propertyowner.prefix ? personDetailsState.propertyowner.prefix + '.' : ''} {personDetailsState.propertyowner.fullname}</div>{personDetailsState.propertyowner.individualuser && <><div>E: {personDetailsState.propertyowner.individualuser.email}</div><div>M: {personDetailsState.propertyowner.individualuser.mobile}</div></>}</div> : '';
            }
        }
        return propertyDetails;

    }

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        {
            field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => {
                return (<>
                    <Tooltip title="View Invoice" placement="top" arrow ><Button type="button" onClick={(e) => handleView(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-file-invoice"></i></Button></Tooltip>

                    {checkPermission('JOB', 'invoice') && !propertyId &&
                        <>
                            {params.row.xero_invoice_number && <Tooltip title="Send Invoice" placement="top" arrow ><Button type="button" onClick={(e) => handleSendInvoice(params.row.id, params.row.is_sent)} style={{ minWidth: '24px' }}><i className="fa fa-paper-plane"></i></Button></Tooltip>}
                            {!params.row.service_id && params.row.paid_status === 'N' &&
                                <>
                                    <Tooltip title="Edit Invoice" placement="top" arrow ><Button type="button" onClick={() => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>
                                    <Tooltip title="Cancel Invoice" placement="top" arrow ><Button type="button" onClick={() => handleInvoiceCancel(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-circle-xmark"></i></Button></Tooltip>
                                </>}
                            {params.row.is_xero_update === 'N' && <Tooltip title="Sync with Xero" placement="top" arrow ><Button type="button" onClick={() => handleInvoiceXero(params.row.id, params.row.xero_invoice_id)} style={{ minWidth: '28px' }}><img src={XeroSync} alt='XeroSync' className={(xeroRotate === params.row.id ? 'rotate' : '') + " m-auto"} style={{ width: '14px' }} /></Button></Tooltip>}
                        </>}

                </>)
            }
        },
        { field: 'id', headerName: 'ID', width: 80, filterable: true, renderCell: (params) => params.row.id, valueGetter: (value, row) => row.id },
        { field: 'invoice_number', headerName: 'Invoice #', width: 140, filterable: true, renderCell: (params) => viewInvoiceNumber(params.row.xero_invoice_number, params.row.is_sent, params.row.is_viewed, params.row.is_xero_update), valueGetter: (value, row) => row.xero_invoice_number },
        {
            field: 'invoice_date', headerName: 'Invoice Date', width: 125, filterable: false, type: 'date',
            valueGetter: (value, row) => dayjs(row.invoice_date.split('/').reverse().join('/')).format('DD/MM/YYYY'),
            valueFormatter: (value) => dayjs(value.split('/').reverse().join('/')).format('DD/MM/YYYY'),
        },
        {
            field: 'paid_status', headerName: 'Paid Status', editable: true,
            type: 'singleSelect',
            valueOptions: ['Paid', 'Pending'], width: 100, sortable: false, filterable: false, renderCell: (params) => (params.row.paid_status === 'Y') ? <Chip label="Paid" color="primary" size="small" /> : (['AGENT', 'LDLORD'].includes(_role) ? <Button className="themeholy-btn btn-primary w-100" onClick={(e) => handlePay(params.row, 'invoice')}>Pay</Button> : (params.row.paid_status === 'N' ? <Chip label="Pending" color="warning" size="small" /> : <Chip label="Cancelled" color="error" size="small" />)), valueGetter: (value) => (value.status === 'Y') ? 'Paid' : (['AGENT', 'LDLORD'].includes(_role) ? 'Pay' : 'Pending')
        },

        { field: 'description', headerName: 'Property / Description', width: 200, filterable: true, renderCell: (params) => propertyId ? (params.row.description ? params.row.description : 'Rectification - Invoice') : (params.row.property ? <>{params.row.property.address1 ? params.row.property.address1 + ', ' : ''}{params.row.property.address2 ? params.row.property.address2 + ', ' : ''}{params.row.property.suburb}, {params.row.property.state} - {params.row.property.postcode}</> : <>{params.row.services.property.address1 ? params.row.services.property.address1 + ', ' : ''}{params.row.services.property.address2 ? params.row.services.property.address2 + ', ' : ''}{params.row.services.property.suburb}, {params.row.services.property.state} - {params.row.services.property.postcode}</>), valueGetter: (value, row) => propertyId ? (row.description ? row.description : 'Rectification - Invoice') : (row.property ? (row.property.address1 ? row.property.address1 : '') + ' ' + (row.property.address2 ? row.property.address2 : '') + ' ' + row.property.suburb + ' ' + row.property.state + ' ' + row.property.postcode : ((row.services.property.address1 ? row.services.property.address1 : '') + ' ' + (row.services.property.address2 ? row.services.property.address2 : '') + ' ' + row.services.property.suburb + ' ' + row.services.property.state + ' ' + row.services.property.postcode)) },
        { field: 'amount', type: 'number', headerName: 'Amount', width: 100, filterable: true, renderCell: (params) => params.row.amount ? params.row.amount.toFixed(2) : '0.00' },
        { field: 'credit', type: 'number', headerName: 'Credit', width: 100, filterable: true, renderCell: (params) => (params.row.agency_credit + params.row.property_credit + params.row.credit).toFixed(2) },
        { field: 'subtotal', type: 'number', headerName: 'Subtotal', width: 100, filterable: true, renderCell: (params) => params.row.amount ? (params.row.amount - params.row.agency_credit - params.row.property_credit - params.row.credit).toFixed(2) : '0.00' },
        { field: 'tax_rate', type: 'number', headerName: 'Tax Rate', width: 100, filterable: true, renderCell: (params) => params.row.tax_rate ? params.row.tax_rate.toFixed(2) : '0.00' },
        { field: 'total', type: 'number', headerName: 'Total', width: 100, filterable: true, renderCell: (params) => params.row.total ? params.row.total.toFixed(2) : '0.00' },
        { field: 'agent_id', headerName: 'Agent / Assigned PM', width: 280, renderCell: (params) => personDetails(params.row, 'agent', false), valueGetter: (value, row) => personDetails(row, 'agent', true) },
        { field: 'owner_id', headerName: 'Landlord', width: 280, renderCell: (params) => personDetails(params.row, 'owner', false), valueGetter: (value, row) => personDetails(row, 'owner', true) }
    ];

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {invoiceDialogOpen && <InvoiceDialog invoiceDialogOpen={invoiceDialogOpen} handleInvoiceDialogClose={handleInvoiceDialogClose} invoiceData={invoiceData} />}
        </Fragment>
    );
}

export const PaymentHistory = ({ propertyId, updateTabList }) => {
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(true);
    const [DTColumns, setDTColumns] = useState([]);

    const fetchPaymentData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('payments', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /*  if (response?.response?.status === 401) {
                        clearSession();
                    navigate('/login');
                 } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate("/login");
            } else {
                console.log(e);
            }
        }

    }

    useEffect(() => {
        fetchPaymentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 100, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'paid_invoices', headerName: 'Paid Invoices', width: 200, filterable: true },
        { field: 'created_at', headerName: 'Payment Date', width: 200, filterable: true },
        { field: 'paid_amount', headerName: 'Paid Amount', width: 200, filterable: true, renderCell: (params) => '$' + params.row.paid_amount ? params.row.paid_amount.toFixed(2) : '0.00', valueGetter: (params) => params.row.paid_amount ? params.row.paid_amount.toFixed(2) : '0.00' },
        { field: 'transaction_id', headerName: 'Transaction ID', width: 250, filterable: true },
        { field: 'payment_mode', headerName: 'Payment Mode', width: 250, filterable: true },
        { field: 'paid_by', headerName: 'Paid By', width: 250, sortable: false, filterable: false },

    ];
    /* const handleAdd = () => {
                    } */
    return (
        <Fragment>
            {/* <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Payment</Button>
            </div> */}
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const Activities = () => {
    const [loader, setLoader] = useState(true);
    const rows: GridRowsProp = [];
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'activity_date', headerName: 'Activity Date', width: 100, filterable: true },
        { field: 'activity_type', headerName: 'Activity Type', width: 150, filterable: true },
        { field: 'check_in', headerName: 'Checkin', width: 100, filterable: true },
        { field: 'check_out', headerName: 'Checkout', width: 100, filterable: true },
        { field: 'duration', headerName: 'Duration', width: 100, sortable: false, filterable: false },
        { field: 'category', headerName: 'Category', width: 150, sortable: false, filterable: false },
        { field: 'service_type', headerName: 'Service Type', width: 250, sortable: false, filterable: false },
        { field: 'comments', headerName: 'Comments', width: 250, sortable: false, filterable: false },
    ];
    useEffect(() => {
        setLoader(false);
    }, []);

    const handleAdd = () => {
    }

    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Activity</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const Appointments = ({ propertyId }) => {
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(true);
    const [DTColumns, setDTColumns] = useState([]);
    const showText = (params) => {
        let stype = params.row.service_type;
        if (stype === 'ES') {
            stype = 'Electrical Safety Check Only';
        } else if (stype === 'SA') {
            stype = 'Smoke Alarm Safety Check Only';
        } else {
            stype = stype === 'ESA' ? 'Electrical & Smoke Alarm Safety Check Only' : 'Gas Safety Check Only';;
        }
        return (<>S: {params.row.service.product.description} <br /> T: {stype} <br />Inv #: {params.row.service.invoice && params.row.service.invoice.xero_invoice_number}</>);
    }

    const handleStatusColor = (status) => {
        var color = 'info';
        if (/Unassigned/.test(status)) {
            color = 'default';
        } else if (/Assigned/.test(status)) {
            color = 'primary';
        } else if (/Approved/.test(status)) {
            color = 'success';
        } else if (/Pending Approval/.test(status)) {
            color = 'secondary';
        } else if (/Cancelled/.test(status)) {
            color = 'error';
        } else if (/Reschedule/.test(status)) {
            color = 'warning';
        }
        return color;
    }

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'job_date', headerName: 'Appointment Date', width: 150, filterable: true },
        { field: 'job_time', headerName: 'Activity Time', width: 100, filterable: true },
        { field: 'product_code', headerName: 'Service Type', width: 200, filterable: true, renderCell: (params) => showText(params) },
        { field: 'technician_id', headerName: 'Tech Person', width: 250, sortable: false, filterable: false, renderCell: (params) => (params.row.technician && (params.row.technician.fullname ? params.row.technician.fullname : '')) },
        {
            field: 'job_status', headerName: 'Status', width: 200, renderCell: (params) => (params.row.job_status ? <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} label={params.row.job_status} color={handleStatusColor(params.row.job_status)} size="small" /> : ''), valueGetter: (value) => value.job_status ? value.job_status : ''
        }
    ];

    const fetchApptData = async () => {
        try {
            const params = '?property_id=' + propertyId;
            const response = await axiosFunction('subservices' + params, 'get');

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                        clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchApptData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*  const handleAdd = () => {
                    } */

    return (
        <Fragment>
            {/*  <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Appointment</Button>
            </div> */}
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const ChangeLogs = () => {
    const [loader, setLoader] = useState(true);
    const rows: GridRowsProp = [];
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'updated_at', headerName: 'Changed Date & Time', width: 250, filterable: true },
        { field: 'changed_field', headerName: 'Changed Field', width: 200, filterable: true },
        { field: 'old', headerName: 'Old Value', width: 200, filterable: true },
        { field: 'new', headerName: 'New Value', width: 250, sortable: false, filterable: false },
        { field: 'updated_by', headerName: 'Updated By', width: 250, sortable: false, filterable: false },
    ];
    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const ServiceReport = ({ propertyId }) => {
    const navigate = useNavigate(null);
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [jobData, setJobData] = useState({});
    const [serviceReportOpen, setServiceReportDialogOpen] = useState(false);
    const [certificateDialogOpen, setCertificateDialogOpen] = useState(false);

    const fetchData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('subservices', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /*  if (response?.response?.status === 401) {
                        clearSession();
                    navigate("/login");
                 } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);

    const showText = (params) => {
        let stype = params.row.service_type;
        if (stype === 'ES') {
            stype = 'Electrical Safety Check Only';
        } else if (stype === 'SA') {
            stype = 'Smoke Alarm Safety Check Only';
        } else {
            stype = stype === 'ESA' ? 'Electrical & Smoke Alarm Safety Check Only' : 'Gas Safety Check Only';;
        }
        return (<>S: {params.row.service.product.description} <br /> T: {stype} <br />Inv #: {params.row.service.invoice && params.row.service.invoice.xero_invoice_number}</>);
    }


    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 150, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'service_date', headerName: 'Service Date', width: 180, filterable: true, renderCell: (params) => dayjs(params.row.service.service_date).format('DD/MM/YYYY') },
        { field: 'job_date', headerName: 'Job Date', width: 180, filterable: true },
        { field: 'service_type', headerName: 'Service', width: 250, renderCell: (params) => showText(params) },
        { field: 'technician_id', headerName: 'Technician', width: 180, renderCell: (params) => (params.row.technician && (params.row.technician.fullname ? params.row.technician.fullname : '')) },
        { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => { return (<><Tooltip title="View Service Report" placement="top" arrow ><Button type="button" onClick={() => { openServiceReport(params.row) }} style={{ minWidth: '20px' }}><RemoveRedEyeRounded style={{ minWidth: '20px' }} /></Button></Tooltip> {(/Paid/.test(params.row.job_status)) && <Tooltip title="View Certificate" placement="top" arrow ><Button type="button" onClick={(e) => handleViewCertificate(params.row)} style={{ minWidth: '20px', color: 'green' }}><Shield /></Button></Tooltip >}</>) } }
    ];

    //<Link to="https://safetyninja.360membershipcrm.com.au/ElectricalSafetyChecklist.pdf" target="_blank"><Button type="button"><i className="fa fa-th-list"></i></Button></Link>

    const openServiceReport = (data) => {
        setJobData(data);
        setServiceReportDialogOpen(true);
    }
    const handleServiceReportDialogClose = () => {
        setServiceReportDialogOpen(false);
    }

    const handleViewCertificate = (data) => {
        setJobData(data);
        setCertificateDialogOpen(true);
    }

    const handleCertificateDialogClose = () => {
        setJobData({});
        setCertificateDialogOpen(false);
    }

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {serviceReportOpen && <ServiceReportDialog serviceReportOpen={true} handleServiceReportDialogClose={handleServiceReportDialogClose} jobData={jobData} />}
            {certificateDialogOpen && <CertificateDialog certificateDialogOpen={certificateDialogOpen} handleCertificateDialogClose={handleCertificateDialogClose} jobData={jobData} />}
        </Fragment>
    );
}

export const ECertificate = () => {
    const [loader, setLoader] = useState(true);
    const rows: GridRowsProp = [
        { id: 1, created_at: '20/03/2024 09:00 AM', certificate: 'complaince_services_10302023.pdf', action: '' },
        { id: 2, created_at: '05/04/2024 09:23 AM', certificate: 'complaince_services_10202023.pdf', action: '' },
    ];
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'created_at', headerName: 'Date & Time', width: 250, filterable: true },
        { field: 'certificate', headerName: 'Certificate', width: 300, filterable: true, renderCell: (params) => <Link to="#">{params.row.certificate}</Link> },
        { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => { return (<Tooltip title="Download Certificate" placement="top" arrow ><Button type="button"><i className="fa fa-eye"></i></Button> <Button type="button"><i className="fa fa-download"></i></Button></Tooltip>) } }
    ];
    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}


export const CommunicationHistory = ({ propertyId, updateTabList }) => {
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(true);
    const [DTColumns, setDTColumns] = useState([]);

    const fetchCommunicationData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('messages', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                        clearSession();
                    navigate("/login");
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchCommunicationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'created_at', headerName: 'Date & Time', width: 180, filterable: true },
        { field: 'type', headerName: 'Via', width: 100, filterable: true },
        { field: 'from', headerName: 'From', width: 180, filterable: true },
        { field: 'to', headerName: 'To', width: 180, sortable: false, filterable: false },
        { field: 'message', headerName: 'Message', width: 250, sortable: false, filterable: false },
    ];

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}