
import { useState, useEffect, Fragment } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import { axiosFunction } from '../common';
import { Controller, useForm } from "react-hook-form";
import { Alert, IconButton, Collapse, Autocomplete, Box, TextField, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { PatternFormat } from 'react-number-format';

const Register = (props) => {
    const { register, control, handleSubmit, formState: { errors } } = useForm();
    const [loader, setLoader] = useState(false);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [backdropopen, setBackDropOpen] = useState(true);
    const [openForm, setOpenForm] = useState('');
    const [fldVal, setFldVal] = useState(null);
    const [pcodevalue, setPcodeValue] = useState(null);
    const [stateValue, setStateValue] = useState('');
    const [postcodeValue, setPostcodeValue] = useState('');
    const [pcodeinput, setPcodeInput] = useState('');
    const [prefixValue, setPrefixValue] = useState('');
    const codeValue = props.type;
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [prefixOptions, setPrefixOptions] = useState(null);

    const loadData = async () => {
        try {
            const prefixDataList = await axiosFunction('auth/prefix', 'get', {});
            const postCodeDataList = await axiosFunction('auth/postcodes', 'get', {});
            if (prefixDataList.status === 'success') {
                setPrefixOptions(prefixDataList.data);
                setPostcodeOptions(postCodeDataList.data);
                setOpenForm('show');
                setBackDropOpen(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(prefixDataList.message);
                setBackDropOpen(false);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
        }
    }

    useEffect(() => {
        setFldVal({
            agency: '',
            prefix: { id: '', description: '' },
            fname: '',
            lname: '',
            email: '',
            mobile: '',
            addr1: '',
            addr2: '',
            suburb: { id: '', suburb: '', state_shortname: '', code: '' },
            state: '',
            pcode: '',
            msg: ''
        });

        setPcodeValue(null);
        setStateValue('');
        setPostcodeValue('');
        setPcodeInput('');
        setPrefixValue('');
        loadData();
        if (openForm === 'done') setOpenForm('show');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    const handleAutoClose = () => {
        window.scrollTo(0, 400);
        setTimeout(() => setAlertOpen(false), 10000);
    };

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFldVal((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        }
        else
            return '';
    }

    const onSubmit = async (data) => {
        try {
            setLoader(true);
            //console.log(data);
            if (data.email) {
                data.code = codeValue;
                data.state = stateValue;
                data.postcode = postcodeValue;
                data.prefix = prefixValue;
                const response = await axiosFunction('auth/register', 'post', data);
                setAlertOpen(true);
                if (response.status === 'success') {
                    setAlertStatus('success');
                    setAlertMessage(response.message);
                    handleAutoClose();
                    setOpenForm('done');
                    setLoader(false);
                } else {
                    setAlertStatus('error');
                    setAlertMessage(response.message);
                    setLoader(false);
                    document.querySelector('#frmRegister').scrollIntoView({ behavior: 'smooth' });
                }
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
            document.querySelector('#frmRegister').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SiteHeader menu={props.type} />
            <div className="pb-5">
                <div className="container">
                    <div className="bg-smoke bg-bottom-right">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="quote-form-box bg-transparent">
                                    <h4 className="form-title text-center">Register as Property {props.type === 'LDLORD' ? 'Landlord' : 'Agent'}</h4>
                                    {
                                        openForm === 'show' ? (
                                            <>
                                                <Collapse in={alertOpen}>
                                                    <Alert variant="filled" severity={alertStatus}
                                                        action={
                                                            <IconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                size="small"
                                                                onClick={() => {
                                                                    setAlertOpen(false);
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        }
                                                        sx={{ mb: 2 }}
                                                    >
                                                        {alertMessage}
                                                    </Alert>
                                                </Collapse>
                                                <form method="POST" className="contact-form ajax-contact" id="frmRegister" autoComplete="none" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {props.type === 'AGENT' && (
                                                                <div className="form-group">
                                                                    <input type="text" className={"form-control " + (errors.agency_name ? 'is-invalid' : '')} placeholder="Agency Name *" autoComplete="none" {...register("agency_name", { required: "Agency Name is required" })} maxLength={40} value={fldVal.agency} onChange={(e) => setFldVal((prev) => ({ ...prev, agency: e.target.value }))} />
                                                                    <label className="w-full error text-red text-sm text-start">{errors.agency_name?.message}</label>
                                                                </div>
                                                            )}
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <Controller
                                                                        name="prefix"
                                                                        control={control}
                                                                        rules={{
                                                                            required: "Prefix is required"
                                                                        }}
                                                                        render={({ field, fieldState }) => (
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                disableClearable
                                                                                id="prefix"
                                                                                options={prefixOptions}
                                                                                getOptionLabel={(option) => option.description}
                                                                                sx={{ width: '100%' }}
                                                                                renderOption={(props, option) => (
                                                                                    <Box component="li" {...props} key={option.id}>
                                                                                        {option.description}
                                                                                    </Box>
                                                                                )}
                                                                                isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                                                                renderInput={(params) => <TextField {...params} label="Prefix" variant="outlined"
                                                                                    error={!!fieldState.error}
                                                                                    helperText={fieldState.error?.message} sx={{ marginTop: "0px !important" }} className="bg-white" />}
                                                                                onChange={(_, data) => { field.onChange(data); setPrefixValue(data.description); setFldVal((prev) => ({ ...prev, prefix: data })) }}
                                                                                value={fldVal.prefix}

                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="form-group">
                                                                        <input type="text" className={"form-control " + (errors.firstname ? 'is-invalid' : '')} placeholder="First Name *" autoComplete="none" {...register("firstname", { required: "First Name is required" })} maxLength={40} value={fldVal.fname} onChange={(e) => setFldVal((prev) => ({ ...prev, fname: e.target.value }))} />
                                                                        <label className="w-full error text-red text-sm text-start">{errors.firstname?.message}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <input type="text" className={"form-control " + (errors.lastname ? 'is-invalid' : '')} placeholder="Last Name *" autoComplete="none" {...register("lastname", { required: "Last Name is required" })} maxLength={40} value={fldVal.lname} onChange={(e) => setFldVal((prev) => ({ ...prev, lname: e.target.value }))} />
                                                                        <label className="w-full error text-red text-sm text-start">{errors.lastname?.message}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="email" className={"form-control " + (errors.email ? 'is-invalid' : '')} placeholder="Email Address *" autoComplete="none" {...register("email", {
                                                                    required: "Email Address is required", pattern: {
                                                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                        message: 'Please enter a valid email',
                                                                    }
                                                                })} maxLength={40} value={fldVal.email} onChange={(e) => setFldVal((prev) => ({ ...prev, email: e.target.value }))} />
                                                                <label className="w-full error text-red text-sm text-start">{errors.email?.message}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <Controller
                                                                    name="mobile"
                                                                    control={control}
                                                                    rules={{
                                                                        required: "A valid mobile number is required",
                                                                        pattern: {
                                                                            value: /^04\d{8}$/,
                                                                            message: 'Please enter a valid mobile number',
                                                                        }
                                                                    }}
                                                                    render={({ field, fieldState }) => (
                                                                        <PatternFormat
                                                                            label="Mobile *"
                                                                            customInput={TextField}
                                                                            type="tel"
                                                                            format="04########"
                                                                            allowEmptyFormatting
                                                                            fullWidth
                                                                            mask="_"
                                                                            error={!!fieldState.error}
                                                                            helperText={fieldState.error?.message}
                                                                            onChange={(e) => field.onChange(handleMobileChange(e))}
                                                                            value={fldVal.mobile}
                                                                            sx={{ marginTop: "3px !important" }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <input type="text" className={"form-control " + (errors.address1 ? 'is-invalid' : '')} placeholder="Address 1 *" autoComplete="none" {...register("address1", { required: "Address 1 is required" })} maxLength={40} value={fldVal.addr1} onChange={(e) => setFldVal((prev) => ({ ...prev, addr1: e.target.value }))} />
                                                                <label className="w-full error text-red text-sm text-start">{errors.address1?.message}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="text" className={"form-control " + (errors.address2 ? 'is-invalid' : '')} placeholder="Address 2" autoComplete="none" {...register("address2")} maxLength={40} value={fldVal.addr2} onChange={(e) => setFldVal((prev) => ({ ...prev, addr2: e.target.value }))} />
                                                                <label className="w-full error text-red text-sm text-start">{errors.address2?.message}</label>
                                                            </div>
                                                            <Fragment>
                                                                <Autocomplete
                                                                    sx={{ width: '100%', mb: 2 }}
                                                                    className={errors.suburb ? 'is-invalid' : ''}
                                                                    id="suburb"
                                                                    name="suburb"
                                                                    disableClearable
                                                                    value={pcodevalue}
                                                                    onChange={(event, newValue) => {
                                                                        if (newValue) {
                                                                            setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                                                            setStateValue(newValue.state_shortname);
                                                                            setPostcodeValue(newValue.code);
                                                                            setPcodeInput(newValue.suburb);
                                                                            errors.suburb = '';
                                                                        }
                                                                        setFldVal((prev) => ({ ...prev, suburb: newValue.suburb, state: newValue.state_shortname, postcode: newValue.code }));

                                                                    }}
                                                                    inputValue={pcodeinput}
                                                                    onInputChange={(event, newInputValue, eventType) => {
                                                                        if (eventType === 'reset') {
                                                                            setPcodeInput(pcodeinput);
                                                                        } else {
                                                                            setPcodeInput(newInputValue);
                                                                        }
                                                                    }}
                                                                    getOptionLabel={(option) => option.suburb}
                                                                    options={postcodeOptions}
                                                                    renderOption={(props, option) => (
                                                                        <Box component="li" {...props} key={option.id}>
                                                                            {option.suburb} - {option.code}, {option.state_shortname}.
                                                                        </Box>
                                                                    )}
                                                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Search Suburb *"
                                                                            error={!!errors.suburb}
                                                                            helperText={errors.suburb?.message}
                                                                            {...register("suburb", { required: "Suburb is required" })}
                                                                            sx={{ marginTop: "0px !important" }}
                                                                            className="bg-white"
                                                                        />
                                                                    )}
                                                                />
                                                                <label className="w-full error text-red text-sm text-start">{errors.postcode?.message}</label>
                                                            </Fragment>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <input id="state" type="text" className={"form-control " + (errors.state ? 'is-invalid' : '')} placeholder="State" autoComplete="none" {...register("state", { required: false })} maxLength={40} readOnly value={stateValue}
                                                                            onChange={(e) => setStateValue(e.target.value)} />
                                                                        <label className="w-full error text-red text-sm text-start">{errors.state?.message}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <input type="tel" id="postcode" className={"form-control " + (errors.postcode ? 'is-invalid' : '')} placeholder="Postcode" autoComplete="none" {...register("postcode", { required: false })} maxLength={4} value={postcodeValue} readOnly
                                                                            onChange={(e) => setPostcodeValue(e.target.value)} />
                                                                        <label className="w-full error text-red text-sm text-start">{errors.postcode?.message}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <textarea cols="30" rows="3" className={"form-control " + (errors.alertnote ? 'is-invalid' : '')} placeholder="Your Message" {...register("alertnote", { required: "Message is required" })} value={fldVal.msg} onChange={(e) => setFldVal((prev) => ({ ...prev, msg: e.target.value }))}></textarea>
                                                            <label className="w-full error text-red text-sm text-start">{errors.alertnote?.message}</label>
                                                        </div>
                                                        <div className="form-btn col-12"><button type="submit" className="themeholy-btn" disabled={(loader) ? 'disabled' : false}>{(loader) ? 'Processing...' : 'Register Now'}</button></div>
                                                    </div>
                                                    <p className="form-messages mb-0 mt-3"></p>
                                                </form>
                                            </>
                                        ) : (openForm === 'done' &&
                                            <>
                                                <h5>Thank you for registering with us. <Link to="/login">Click here login</Link> </h5>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default Register;