import { Link } from 'react-router-dom';
import { Box, Card, Stack, Typography, CardContent, CardActions } from '@mui/material';

const CardInfo = ({ title, nactive, ninactive, npending, nready, total, subtitle, titlelabel, icon, link }) => {
    return (
        <Card
            component={Stack}
            sx={{
                m: 0,
                borderRadius: 2,
                bgcolor: '#FFFFFF',
                color: '#333333',
                width: '100%'
            }}
        >

            <CardContent>
                <div className='row'>
                    <Box sx={{ margin: '5px 15px', padding: '5px', height: '45px', width: '45px', bgcolor: '#E83A15', color: '#f8f8f8', borderRadius: '50%' }} className="col-1">
                        {icon}
                    </Box>
                    <div className='col-7'>
                        <Typography variant="h6">
                            {titlelabel ? (
                                <div>{titlelabel}</div>
                            ) : (<div>{title}</div>)}

                        </Typography>
                        <Stack>
                            {title !== 'This Month' && (
                                <Typography variant="h6">{nactive} <small style={{ fontSize: '14px' }}>Active</small></Typography>
                            )}
                            {title !== 'This Month' && (
                                <Typography variant="h6">{ninactive} <small style={{ fontSize: '14px' }}>Inactive</small></Typography>
                            )}
                            {title === 'Properties' && (
                                <><Typography variant="h6">{npending} <small style={{ fontSize: '14px' }}>Pending</small></Typography>
                                    <Typography variant="h6">{nready} <small style={{ fontSize: '14px' }}>Ready to assign</small></Typography></>
                            )}
                            {title === 'This Month' && (
                                <Typography variant="h6">{total} <small style={{ fontSize: '14px' }}>{subtitle}</small></Typography>
                            )}
                        </Stack>
                    </div>
                </div>
            </CardContent>
            <CardActions style={{ margin: 'auto', marginBottom: '10px' }}>
                <Link to={link}>View More</Link>
            </CardActions>
        </Card>
    )
}

export default CardInfo