import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Autocomplete, TextField, Button } from "@mui/material";
import { PatternFormat } from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { FormContext } from "./ManageProperties";
import { axiosFunction, clearSession, getSessionItem } from '../../common';

const OwnerForm = () => {
    const navigate = useNavigate(null);
    const _role = getSessionItem("_role");
    const { register, handleSubmit, control, formState: { errors }, reset } = useForm();
    let { userOptions, viewField, setViewField, onSubmit, handleOwnerView, loaderText, setViewChangeFlag } = useContext(FormContext);
    const [ownerInput, setOwnerInput] = useState(viewField.owner_name + (viewField.owner_mobile ? ' (' + viewField.owner_mobile + ')' : ''));
    const [agentInput, setAgentInput] = useState(viewField.agent_name + (viewField.agent_mobile ? ' (' + viewField.agent_mobile + ')' : ''));
    const [agentData, setAgentData] = useState([]);
    const getSelectedItem = (id, code) => {
        // eslint-disable-next-line
        const item = userOptions && userOptions.filter(a => a.code === code).find((opt) => {
            if (opt && opt.id === id) {
                return opt;
            };
        });
        return item || null;
    }

    const fetchAgentData = async (id) => {
        try {
            const params = { owner_id: id }
            const agentUserData = await axiosFunction('auth/users', 'post', params);
            if (agentUserData.status === 'success') {
                setAgentData(agentUserData.data);
            } else {
                setAgentData([]);
                /* if (agentUserData?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        // if (inputRef) inputRef.focus();
        fetchAgentData(viewField.owner_id);
        reset(viewField);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkFormChange = () => {
        setViewChangeFlag(true);
    };

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setViewField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }

    const updateAgentData = async (id) => {
        if (id) {
            try {
                const params = { owner_id: id }
                const response = await axiosFunction('auth/users', 'post', params);
                if (response.status === 'success') {
                    setAgentData(response.data);
                } else {
                    setAgentData([]);
                    /* if (response?.response?.status === 401) {
                        clearSession();
                        navigate('/login');
                    } */
                }
                setAgentInput('');
                setViewField((prev) => ({ ...prev, agent_id: '', agent_name: '', agent_prefix: '' }));
            } catch (e) {
                if (e?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } else {
                    console.log(e);
                }
            }
        }
    }
    return (
        <div id="editForm">
            <Box component="form" noValidate autoComplete="off" onChange={checkFormChange} onSubmit={handleSubmit(onSubmit)}>
                {_role !== 'LDLORD' && (<Controller
                    render={(props) => (
                        <Autocomplete
                            options={userOptions.filter(a => a.is_landlord === "Y")}
                            getOptionLabel={(option) => option.label}
                            renderOption={(args, option) => (
                                <Box component="li" {...args} key={option.id}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Landlord *"
                                    variant="outlined"
                                    error={!!props.fieldState.error}
                                    helperText={props.fieldState.error?.message}
                                />
                            )}
                            onChange={(_, data) => {
                                props.field.onChange(data.id)
                                setViewField((prev) => ({ ...prev, owner_id: data.id, owner_name: data.label, owner_prefix: data.prefix }));
                                if (_role !== 'AGENT') updateAgentData(data.id);
                                setOwnerInput(data.label);
                            }}
                            inputValue={ownerInput}

                            onInputChange={(_, newInputValue, eventType) => {
                                //console.log(eventType);                            
                                if (eventType === 'reset') {
                                    setOwnerInput(ownerInput);
                                } else {
                                    setOwnerInput(newInputValue);
                                }
                            }}
                        />
                    )}
                    defaultValue={viewField.agent_id ? getSelectedItem(viewField.owner_id, "LDLORD") : null}
                    name="owner_id"
                    control={control}
                    rules={{
                        required: (!viewField.owner_id && !viewField.agent_id) ? "Landlord / Agent is required" : false
                    }}
                />
                )}
                {_role !== 'AGENT' && (
                    <>
                        <Controller
                            render={(props) => (
                                <Autocomplete
                                    disableClearable
                                    options={agentData}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(args, option) => (
                                        <Box component="li" {...args} key={option.id}>
                                            {option.label}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Agent"
                                            variant="outlined"
                                            error={!!props.fieldState.error}
                                            helperText={props.fieldState.error?.message}
                                        />
                                    )}
                                    onChange={(_, data) => {
                                        props.field.onChange(data.id)
                                        setViewField((prev) => ({ ...prev, agent_id: data.id, agent_name: data.label, agent_prefix: data.prefix }));
                                        setAgentInput(data.label);
                                    }}
                                    inputValue={agentInput ? agentInput : ''}

                                    onInputChange={(_, newInputValue, eventType) => {
                                        if (eventType === 'reset') {
                                            setAgentInput(agentInput);
                                        } else {
                                            setAgentInput(newInputValue);
                                        }
                                    }}
                                />
                            )}
                            rules={{
                                required: (!viewField.owner_id && !viewField.agent_id) ? "Landlord / Agent is required" : false
                            }}
                            value={viewField.agent_id ? getSelectedItem(viewField.agent_id, "AGENT") : null}
                            name="agent_id"
                            control={control}
                        />
                    </>
                )}
                <TextField
                    fullWidth
                    id="tenancy_name" name="tenancy_name" variant="outlined" error={errors.tenancy_name ? true : false} label="Tenancy Name"
                    helperText={errors.tenancy_name?.message}
                    inputProps={{ maxLength: 100, value: viewField.tenancy_name }}
                    {...register("tenancy_name", { required: false })}
                    onChange={(e) => setViewField((prev) => ({ ...prev, tenancy_name: e.target.value }))}
                />
                <TextField
                    fullWidth
                    id="tenancy_email" name="tenancy_email" variant="outlined" error={errors.tenancy_email ? true : false} label="Tenancy Email Address"
                    helperText={errors.tenancy_email?.message}
                    inputProps={{ maxLength: 60, value: viewField.tenancy_email ? viewField.tenancy_email : '' }}
                    {...register("tenancy_email", {
                        required: false, pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                        }
                    })}
                    onChange={(e) => setViewField((prev) => ({ ...prev, tenancy_email: e.target.value }))}
                />
                <Controller
                    name="tenancy_mobile"
                    control={control}
                    rules={{
                        required: false,
                        pattern: {
                            value: /^04\d{8}$/,
                            message: 'Please enter a valid mobile number',
                        }
                    }}
                    render={({ field, fieldState }) => (
                        <PatternFormat
                            label="Tenancy Mobile"
                            customInput={TextField}
                            type="tel"
                            format="04########"
                            allowEmptyFormatting
                            fullWidth
                            mask="_"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            value={viewField.tenancy_mobile}
                            onChange={(e) => { field.onChange(handleMobileChange(e)) }}
                        />
                    )}
                />
                <div className="col-md-12 text-center">
                    <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleOwnerView}>
                        Cancel
                    </Button>
                    <Button type="submit" className="themeholy-btn btn-primary" disabled={loaderText}>
                        {(loaderText) ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </Box>
        </div>
    );
}
export default OwnerForm;