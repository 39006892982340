import { useRef } from 'react';
//import { Link } from 'react-router-dom';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, useMediaQuery, useTheme
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import jsPDF from 'jspdf';
import ServiceReportComponent from '../Jobs/ServiceReport';
import { Base64 } from 'js-base64';


const ServiceReport = ({ serviceReportOpen, handleServiceReportDialogClose, jobData }) => {

    const serviceReportRef = useRef(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const doc = new jsPDF('p', 'px', 'a4', true);
    //doc.setFont('helvetica', 'normal');
    //doc.setFontSize('14px');


    const handleGeneratePdf = () => {
        doc.setProperties({
            title: "service_report_" + Base64.encode(jobData.id, true) + ".pdf"
        }).html(serviceReportRef.current, {
            callback: function (doc) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize('14px');
                //doc.output('dataurlnewwindow', { filename: 'service_report_' + Base64.encode(jobData.id, true) + '.pdf' });
                doc.save('service_report_' + Base64.encode(jobData.id, true) + '.pdf');
            },
            margin: [5, 4, 5, 4],
            autoPaging: 'text',
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.486,
                ignoreElements(el) {
                    return typeof el.shadowRoot === 'object' && el.shadowRoot !== null;
                },
            }
        });

    };

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="md"
            open={serviceReportOpen}
            onClose={handleServiceReportDialogClose}
            scroll='body'
        >

            <DialogTitle className='p-0'>
                <IconButton
                    aria-label="close"
                    onClick={handleServiceReportDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'black',
                        zIndex: '1000',
                        backgroundColor: 'white',
                        opacity: 0.9
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent ref={serviceReportRef} className='p-0'>
                <ServiceReportComponent jobData={jobData} />
            </DialogContent >
            <DialogActions sx={{ marginBottom: '25px', paddingRight: '25px' }}>
                <Button onClick={handleGeneratePdf}>
                    Generate PDF
                </Button>
                <Button onClick={handleServiceReportDialogClose} className='themeholy-btn btn-primary'>Close</Button>
            </DialogActions>
        </Dialog >
    );
}

export default ServiceReport;