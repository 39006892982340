import { useRef } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, useMediaQuery, useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import Quotation from "../Jobs/Quotation";
import { Base64 } from 'js-base64';


const QuotationDialog = ({ quotationDialogOpen, handleQuotationDialogClose, quotationData }) => {
    const quotationRef = useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const handleGeneratePdf = () => {
        doc.setProperties({
            title: "quotation_" + Base64.encode(quotationData.id, true) + ".pdf"
        }).html(quotationRef.current, {
            callback: function (doc) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize('14px');
                //doc.output('dataurlnewwindow', { filename: "quotation_" + Base64.encode(quotationData.id, true) + ".pdf" });
                doc.save("quotation_" + Base64.encode(quotationData.id, true) + ".pdf");
            },
            margin: [5, 4, 5, 4],
            autoPaging: 'text',
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.486,
                ignoreElements(el) {
                    return typeof el.shadowRoot === 'object' && el.shadowRoot !== null;
                },
            }
        });

    };

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="md"
            open={quotationDialogOpen}
            onClose={handleQuotationDialogClose}
            scroll='body'
        >
            <DialogTitle className='p-0'>
                <IconButton
                    aria-label="close"
                    onClick={handleQuotationDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'black',
                        zIndex: '1000',
                        backgroundColor: 'white',
                        opacity: 0.9
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent ref={quotationRef} className='p-0'>
                <Quotation quotationData={quotationData} />
            </DialogContent>
            <DialogActions className='p-4'>
                {quotationData.paid_status !== 'C' && <Button onClick={handleGeneratePdf}>
                    Generate PDF
                </Button>}
                <Button onClick={handleQuotationDialogClose}>Close</Button>
            </DialogActions>

        </Dialog >
    );
}

export default QuotationDialog;