//import { useState } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { useLocation } from 'react-router-dom';
import ChangePassword from "../components/My-Account/ChangePassword";
import { getSessionItem } from "../common";

const ResetPassword = () => {
    const comp = useLocation();
    let compseg = comp.pathname.split('/')[2];
    if (!compseg) {
        compseg = getSessionItem('_reset_password_token');
    }
    return (
        <>
            <SiteHeader menu='reset' />
            <div className="space-bottom">
                <div className="container">
                    <div className="bg-smoke bg-bottom-right">
                        <div className="quote-form-box bg-transparent">
                            <h4 className="form-title text-center">Reset Password</h4>
                            <ChangePassword resetPassword='Y' resetToken={compseg} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ResetPassword;