import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom"; //, Link
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Chip, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip, Drawer, Box, Autocomplete, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import DataTableListFromServer from '../DataTableListFromServer';
import DataTableList from '../DataTableList';

import { axiosFunction, checkPermission, clearSession, getSessionItem } from '../../common';
import { JobContext } from "../../pages/Jobs";
import JobForm from "./JobForm";
import ServiceReport from "../Modal/ServiceReport";
import InvoiceDialog from "../Modal/InvoiceDialog";
import Checklist from "./Checklist";
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';
import JobQuotationForm from "./JobQuotationForm";

export const FormContext = createContext(null);
export const QuotationContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageJobs = () => {
    const _role = getSessionItem("_role");
    const navigate = useNavigate(null);
    const { reset, handleSubmit, register, formState: { errors } } = useForm();
    const { setBackDropOpen, serviceUsersOptions, jobStatusOptions, scheduleStatusOptions } = useContext(JobContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [invoiceOpen, setInvoiceOpen] = useState(false);
    const [checkListOpen, setCheckListOpen] = useState(false);
    const [jobData, setJobData] = useState({});
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});

    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});

    const [serviceId, setServiceId] = useState('');
    const [subServiceId, setSubServiceId] = useState('');
    const [lookUpsOptions, setLookUpsOptions] = useState(null);

    const [serviceReportOpen, setServiceReportDialogOpen] = useState(false);

    const [formField, setFormField] = useState({
        'property_id': '',
        'job_status': '',
        'technician_id': '',
        'job_date': '',
        'job_time': '',
        'schedule_status': '',
        'schedule_comments': '',
        'quotation_id': '',
        'product_code': '',
        'service_charge': '',
        'job_comments': '',
        'comments': '',
        'service_type': '',
        'action': '',
    });
    //const [rowKeyVal, setRowKeyVal] = useState(0);
    const [alertMessage, setAlertMessage] = useState('');
    const [mailOpen, setMailOpen] = useState(false);

    const [invItems, setInvItems] = useState({ items: [{ description: "", quantity: 1, charge: "", amount: "" }] });
    // const [quotationDialogOpen, setQuotationDialogOpen] = useState(false);
    const [quoteDrawerOpen, setQuoteDrawerOpen] = useState(false);
    const [quotationData, setQuotationData] = useState({});
    const [formChangeFlag, setFormChangeFlag] = useState(false);
    //const curdate = new Date();
    const [searchFields, setSearchFields] = useState({
        "from_date": dayjs().startOf('month'),
        "to_date": dayjs().endOf('month'),
        "technician_id": "",
        "job_status": ""
    });
    /* const [rowCount, setRowCount] = useState(0);
    const [page, setPage] = useState(0);
    //const [pageSize, setPageSize] = useState(10);
    const [sortModel, setSortModel] = useState([]);
    const [filterText, setFilterText] = useState(""); */

    const loadData = async () => {
        try {
            const lookUpsDataList = await axiosFunction('auth/lookups', 'get', {});
            if (lookUpsDataList.status === 'success') {
                setLookUpsOptions(lookUpsDataList.data);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(lookUpsDataList.message);
                setLoader(false);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    const fetchData = async (params) => {
        try {
            setBackDropOpen(true);
            //const sort = sortModel.length ? sortModel[0] : {};
            // var params = { page: page, sortField: sort.field, sortOrder: sort.sort, filterText: filterText };

            //console.log(params);
            const subserviceData = await axiosFunction('subservices', 'get', params);

            if (subserviceData.status === 'success') {
                setDTColumns(subserviceData.data);
                //setRowCount(subserviceData.pagination.total);
                setBackDropOpen(false);
                setLoader(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(subserviceData.message);
                handleAutoClose();
                setBackDropOpen(false);
                setLoader(false);
                /* if (subserviceData?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            //handleAutoClose();
            setBackDropOpen(false);
            setLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        loadData();
        fetchData({ from_date: dayjs().startOf('month').format('YYYY-MM-DD'), to_date: dayjs().endOf('month').format('YYYY-MM-DD') });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //page, sortModel, filterText

    const actionButtons = (params) => {
        return (
            <>
                {checkPermission('JOB', 'edit') && <Tooltip title="Edit Job" placement="top" arrow ><Button type="button" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>
                }
                {checkPermission('JOB', 'delete') &&
                    <Tooltip title="Delete Job" placement="top" arrow ><Button type="button" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px', color: 'red' }}><i className="fa fa-trash"></i></Button></Tooltip>
                }

                {(/Assigned|Pending Approval/.test(params.row.job_status) && checkPermission('JOB', 'checklist') && params.row.technician) && <Tooltip title="Update Checklist" placement="top" arrow ><Button type="button" onClick={(e) => handleChecklist(params.row)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-th-list"></i></Button>
                </Tooltip>
                }
                {/Approved|Closed|Lead/.test(params.row.job_status) && checkPermission('JOB', 'report') && <Tooltip title="View Service Report" placement="top" arrow ><Button type="button" style={{ minWidth: '24px', color: 'purple' }} onClick={(e) => openServiceReport(params.row)}><i className="fa fa-eye"></i></Button></Tooltip>}
                {/Approved|Closed|Lead/.test(params.row.job_status) && checkPermission('JOB', 'viewinvoice') && ((params.row.service.invoice && params.row.service.invoice.id) || (params.row.quotation && params.row.quotation.invoice_number)) && <Tooltip title="View Invoice" placement="top" arrow ><Button type="button" onClick={(e) => handleInvoice(params.row)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-file-invoice"></i></Button></Tooltip>}
                {/Closed|Lead/.test(params.row.job_status) && checkPermission('JOB', 'quotation') && (!params.row.quotation || (params.row.quotation && params.row.quotation.quotation_status !== 'Y')) && <Tooltip title={(!params.row.quotation ? "Add" : "Edit") + " Quotation"} placement="top" arrow >
                    <Button type="button" onClick={(e) => {
                        if (!params.row.quotation)
                            handleQuotation(params.row, 'add')
                        else
                            handleQuotation(params.row, 'edit')
                    }} style={{ minWidth: '24px', color: (!params.row.quotation ? "blue" : "primary") }}><i className="fa fa-file-invoice-dollar"></i>{!params.row.quotation && '+'}</Button></Tooltip>}

            </>);

    }

    const handleStatusColor = (status) => {
        var color = 'info';
        if (/Unassigned/.test(status)) {
            color = 'default';
        } else if (/Assigned/.test(status)) {
            color = 'primary';
        } else if (/Approved/.test(status)) {
            color = 'success';
        } else if (/Pending Approval/.test(status)) {
            color = 'secondary';
        } else if (/Cancelled/.test(status)) {
            color = 'error';
        } else if (/Reschedule/.test(status)) {
            color = 'warning';
        }
        return color;
    }

    const handleScheduleStatusColor = (status) => {
        var color = 'info';
        if (status === 'No Show') {
            color = 'default';
        } else if (status === 'First Call') {
            color = 'primary';
        } else if (status === 'Confirmed') {
            color = 'success';
        } else if (status === 'Call Back') {
            color = 'secondary';
        } else if (status === 'No Response') {
            color = 'error';
        } else if (status === 'ReSchedule') {
            color = 'warning';
        }
        return color;
    }

    const showText = (params, type) => {
        let stype = params.service_type;

        if (stype === 'ES') {
            stype = 'Electrical Safety Check Only';
        } else if (stype === 'SA') {
            stype = 'Smoke Alarm Safety Check Only';
        } else {
            stype = params.quotation_id ? 'Rectification' : stype === 'ESA' ? 'Electrical & Smoke Alarm Safety Check Only' : stype === 'GS' ? 'Gas Safety Check Only' : 'Other Safety Check';
        }
        if (type === 'filter') {
            return stype === 'Rectification' ? (params.quotation ? params.quotation.quotation_number : '') : (params.service.product.description + ' ' + stype + ' ' + (params.quotation_id && params.quotation) ? params.quotation?.xero_invoice_number : (params.service.invoice ? params.service.invoice?.xero_invoice_number + ' ' + (params.job_comments && <Tooltip title={params.job_comments} placement="top" arrow ><CircleNotificationsIcon fontSize="small" color='error' /></Tooltip>) : ''));
        }
        return (<>S: {stype === 'Rectification' ? 'Quotation #: ' + (params.quotation ? params.quotation.quotation_number : '') : params.service.product.description} <br /> T: {stype}  {params.job_comments && <Tooltip title={params.job_comments} placement="top" arrow ><CircleNotificationsIcon fontSize="small" color='error' /></Tooltip>} <br />Inv #: {(params.quotation_id && params.quotation) ? params.quotation?.xero_invoice_number : params.service.invoice ? params.service.invoice?.xero_invoice_number : ''}</>);
    }

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'actions', headerName: 'Actions', width: 180, sortable: false, hideable: false, renderCell: (params) => actionButtons(params) },
        { field: 'id', headerName: 'Job ID', width: 70, renderCell: (params) => (<div className="d-flex justify-content-between">{params.row.service_id} {params.row.service.comments && <Tooltip title={params.row.service.comments} placement="top" arrow ><CircleNotificationsIcon fontSize="small" color='error' /></Tooltip>}</div>), valueGetter: (value, row) => row.service_id },
        { field: 'job_date', headerName: 'Job Date', width: 150, type: 'date', valueFormatter: (value) => dayjs(value.split('/').reverse().join('/')).format('DD-MM-YYYY'), renderCell: (params) => (<>D: {params.row.job_date} <br /> T: {params.row.job_time}</>) },
        {
            field: 'job_status', headerName: 'Job Status', width: 200, renderCell: (params) => (params.row.job_status ? <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} label={params.row.job_status} color={handleStatusColor(params.row.job_status)} size="small" /> : ''), valueGetter: (value, row) => row.job_status ? row.job_status : ''
        },
        {
            field: 'schedule_status', headerName: 'Schedule Status', width: 150, renderCell: (params) => (params.row.schedule_status ? <div className="d-flex justify-content-between"><Tooltip title={params.row.schedule_comments} placement="top" arrow ><Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} label={params.row.schedule_status} color={handleScheduleStatusColor(params.row.schedule_status)} size="small" /></Tooltip> {params.row.schedule_comments && <Tooltip title={params.row.schedule_comments} placement="top" arrow ><CircleNotificationsIcon fontSize="small" color='error' /></Tooltip>}</div> : ''), valueGetter: (value, row) => row.schedule_status ? row.schedule_status : ''
        },
        { field: 'product_code', headerName: 'Service Type', width: 280, renderCell: (params) => showText(params.row), valueGetter: (value, row) => showText(row, 'filter') },
        { field: 'technician_id', headerName: 'Technician', width: 125, renderCell: (params) => (params.row.technician && (params.row.technician.fullname ? params.row.technician.fullname : '')), valueGetter: (value, row) => (row.technician && (row.technician.fullname ? row.technician.fullname : '')) },
        { field: 'property_id', headerName: 'Property', width: 200, hideable: false, renderCell: (params) => ((params.row.service.property.address1 ? params.row.service.property.address1 + ', ' : '') + (params.row.service.property.address2 ? params.row.service.property.address2 + ', ' : '') + params.row.service.property.suburb + ', ' + params.row.service.property.state + ' - ' + params.row.service.property.postcode), valueGetter: (value, row) => (row.service.property.address1 ? row.service.property.address1 + ' ' : '') + (row.service.property.address2 ? row.service.property.address2 + ' ' : '') + row.service.property.suburb + ' ' + row.service.property.state + ' ' + row.service.property.postcode },
        { field: 'service.property.propertyagent', headerName: 'Agent / Assigned PM', width: 280, renderCell: (params) => ((params.row.service.property.propertyagent && params.row.service.property.propertyagent.individualuser) ? <div>{params.row.service.property.propertyagent.agency && <div>A: {params.row.service.property.propertyagent.agency.fullname}</div>}<div>PM: {params.row.service.property.propertyagent.prefix ? params.row.service.property.propertyagent.prefix + '.' : ''} {params.row.service.property.propertyagent.fullname}</div><div>E: {params.row.service.property.propertyagent.individualuser.email}</div><div>M: {params.row.service.property.propertyagent.individualuser.mobile} </div></div> : ''), valueGetter: (value, row) => (row.service.property.propertyagent && row.service.property.propertyagent.individualuser) ? (row.service.property.propertyagent.agency ? row.service.property.propertyagent.agency.fullname : '') + ' ' + row.service.property.propertyagent.fullname + ' ' + row.service.property.propertyagent.individualuser.email + ' ' + row.service.property.propertyagent.individualuser.mobile : '' },
        { field: 'service.property.tenancy_name', headerName: 'Tenant', width: 280, renderCell: (params) => (<div><div>N: {params.row.service.property.tenancy_name}</div><div>E: {params.row.service.property.tenancy_email}</div><div>M: {params.row.service.property.tenancy_mobile} </div></div>), valueGetter: (value, row) => row.service.property.tenancy_name + ' ' + row.service.property.tenancy_email + ' ' + row.service.property.tenancy_mobile },
        {
            field: 'property_owner', headerName: 'Landlord', width: 250, renderCell: (params) => (params.row.service.property.propertyowner && <>N: {params.row.service.property.propertyowner.prefix ? params.row.service.property.propertyowner.prefix + '. ' : ''}{params.row.service.property.propertyowner ? params.row.service.property.propertyowner.fullname : ''}<br />E: {params.row.service.property.propertyowner.individualuser.email}<br />M: {params.row.service.property.propertyowner.individualuser.mobile}</>), valueGetter: (value, row) => (row.service.property.propertyowner && (row.service.property.propertyowner.prefix ? row.service.property.propertyowner.prefix + '. ' : '') + row.service.property.propertyowner.fullname + ' ' + row.service.property.propertyowner.individualuser.email + ' ' + row.service.property.propertyowner.individualuser.mobile)
        }
    ];

    const handleEdit = (key, data) => {
        setLoaderText(false);

        setServiceId(data.service_id);
        setSubServiceId(data.id);
        setJobData(data);

        setFormChangeFlag(false);

        //setRowKeyVal(key);

        //const service_date = data.service_date.split('/');
        const job_date = data.job_date.split('/');

        setFormField({
            'property_id': data.property_id,
            'job_status': data.job_status ? data.job_status : '',
            'service_type': data.service_type,
            'technician_id': data.technician_id ? data.technician_id : '',
            'technician_name': data.technician && (data.technician.fullname ? data.technician.fullname : ''),
            'job_date': job_date[2] + '/' + job_date[1] + '/' + job_date[0],
            'job_time': data.job_time,
            'quotation_id': data.quotation_id,
            'schedule_status': data.schedule_status,
            'schedule_comments': data.schedule_comments,
            'product_code': data.service.product_code,
            'service_charge': data.service.service_charge ? data.service.service_charge : data.service.product.service_charge,
            'job_comments': data.job_comments,
            'comments': data.service.comments,
            'action': 'edit',
        });
        setDialogOpen(true);
    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = async () => {
        try {
            const delResponse = await axiosFunction('subservices/' + delData.id, 'post', delData);
            setAlertOpen(true);
            if (delResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(delResponse.message);
                handleAutoClose();
                fetchData();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            } else {
                setAlertStatus('error');
                setAlertMessage(delResponse.message);
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            setConfirmOpen(false);
            setDelData({});
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    };

    const openServiceReport = (data) => {
        setJobData(data);
        setServiceReportDialogOpen(true);
    }

    const handleServiceReportDialogClose = () => {
        setServiceReportDialogOpen(false);
    }

    const handleInvoice = (data) => {
        let invoiceDtls = data.quotation ? data.quotation : data.service.invoice;
        let invoiceData = { ...invoiceDtls, 'services': { 'service_date': data.job_date.split('/').reverse().join('/'), 'property': data.service.property } }
        setInvoiceData(invoiceData);
        setInvoiceDialogOpen(true);
    }

    const handleInvoiceDialogClose = () => {
        setInvoiceData({});
        setInvoiceDialogOpen(false);
    }

    const handleChecklist = (data) => {
        setServiceId(data.service.id);
        setSubServiceId(data.id);
        setJobData(data);
        setCheckListOpen(true);
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setDialogOpen(false);
        setServiceId('');
        setSubServiceId('');
        setFormField({
            'property_id': '',
            'job_status': '',
            'service_type': '',
            'technician_id': '',
            'technician_name': '',
            'job_date': '',
            'job_time': '',
            'quatation_id': '',
            'schedule_status': '',
            'schedule_comments': '',
            'product_code': '',
            'service_charge': '',
            'job_comments': '',
            'comments': '',
            'action': '',
        });
        setJobData({});
    };

    const handleInvoiceClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setInvoiceOpen(false);
        setServiceId('');
        setSubServiceId('');
        setFormField({
            'invoice_id': '',
            'invoice_date': '',
            'description': '',
            'amount': '',
            'received': '',
            'balance': '',
            'paid_status': '',
            'action': '',
        });
    };

    const handleChecklistClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setCheckListOpen(false);
        setServiceId('');
        setSubServiceId('');
        setJobData({});
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const handleMailClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setMailOpen(false);
        }
    };

    const onSubmit = async (data) => {
        if (formChangeFlag === true) {
            try {

                setLoaderText(true);
                data.job_date = dayjs(data.job_date).format('DD-MM-YYYY');
                data.product_code = formField.product_code;
                data.service_charge = formField.service_charge;
                data.technician_id = formField.technician_id ? formField.technician_id : '';
                data.technician_name = formField.technician_name ? formField.technician_name : '';
                var saveResponse = null;
                if (formField.action === 'edit') {
                    data._method = 'PUT';
                    saveResponse = await axiosFunction('subservices/' + subServiceId, 'post', data);
                } else if (formField.action === 'add') {
                    data.job_status = formField.job_status;
                    data.property_id = formField.property_id;
                    saveResponse = await axiosFunction('subservices', 'post', data);
                }
                setAlertOpen(true);
                if (saveResponse.status === 'success') {
                    if (saveResponse.item.invoice_id) {
                        window.open('/generateinvoice/' + Base64.encode(saveResponse.item.invoice_id, true), '_blank')
                    }
                    setAlertStatus('success');
                    handleDialogClose();
                    fetchData();
                    setAlertMessage(saveResponse.message);
                    handleAutoClose();
                    setLoaderText(false);
                    handleDialogClose();
                } else {
                    setAlertStatus('error');
                    setAlertMessage(saveResponse.message);
                    setLoaderText(false);
                    handleDialogClose();
                }
            } catch (e) {
                setAlertStatus('error');
                setAlertMessage(e.message);
                setLoaderText(false);
                handleDialogClose();
                if (e?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                }
            }
        } else {
            handleDialogClose();
        }

    };

    const handleQuotation = (jobData, action) => {
        setQuotationData(jobData);
        if (action === 'edit') {
            setInvItems(jobData.quotation);
            setFormField({ subtotal: jobData.quotation.amount, tax_rate: jobData.quotation.tax_rate, total: jobData.quotation.total, action: action })
        } else {
            setFormField({ subtotal: 0, tax_rate: 0, total: 0, action: action });
            setInvItems({ items: [{ description: '', quantity: 1, charge: '', amount: '' }] });
        }
        setQuoteDrawerOpen(true);
    }

    const handleQuotationDialogClose = () => {
        setFormField({});
        setInvItems({});
        setQuotationData({});
        setQuoteDrawerOpen(false);
    }

    const onSearch = async () => {

        if (searchFields.from_date || searchFields.to_date || searchFields.technician_id || searchFields.job_status) {

            var params = {};
            if (searchFields.from_date) params.from_date = dayjs(searchFields.from_date).format('YYYY-MM-DD');
            if (searchFields.to_date) params.to_date = dayjs(searchFields.to_date).format('YYYY-MM-DD');
            if (searchFields.technician_id) params.technician_id = searchFields.technician_id;
            if (searchFields.job_status) params.job_status = searchFields.job_status;

            fetchData(params);
        }
    }

    const handleSearchReset = () => {
        if (searchFields.from_date || searchFields.to_date || searchFields.technician_id || searchFields.job_status) {
            setSearchFields({
                "from_date": "",
                "to_date": "",
                "technician_id": "",
                "job_status": ""
            });
            reset(searchFields);

            fetchData({});
        }
    }

    const getSelectedItem = (label) => {
        // eslint-disable-next-line
        const item = jobStatusOptions.find((opt) => {
            if (opt && opt.label === label) {
                return opt;
            };

        })

        return item || null;
    }

    const getSelectedTechnician = (id) => {
        // eslint-disable-next-line
        const item = serviceUsersOptions.filter(a => (a.code === 'E-TRADIE' || a.code === 'P-TRADIE')).find((opt) => {

            if (opt && opt.id === id) {

                return opt;
            };

        });


        return item || null;
    }


    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
            >
                <DialogTitle>Are you sure you want to delete this Job?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, job will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={mailOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleMailClose}
            >
                <DialogTitle>Save Checklist</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Save and Mail has been sent successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-primary" onClick={(e) => handleMailClose(e, 'ok')}>OK</Button>
                </DialogActions>
            </Dialog>

            <FormContext.Provider value={{
                handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
                alertMessage,
                alertOpen,
                setAlertOpen,
                setAlertStatus,
                setAlertMessage,
                dialogOpen,
                serviceUsersOptions,
                jobStatusOptions,
                scheduleStatusOptions,
                handleInvoiceClose,
                invoiceOpen,
                serviceId,
                subServiceId,
                checkListOpen,
                handleChecklistClose,
                jobData,
                fetchData,
                lookUpsOptions,
                setFormChangeFlag
            }}>
                {dialogOpen && <JobForm />}
                {checkListOpen && <Checklist />}
            </FormContext.Provider>

            <Box component="form" id="job-search-form" name="job-search-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSearch)} className="row">

                <div className="col-12 col-md-4 d-flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Job From Date *"
                            format="DD/MM/YYYY"
                            name="from_date"
                            {...register("from_date", { required: "From Date is required" })}
                            maxDate={dayjs(searchFields.to_date ? searchFields.to_date : new Date(new Date().setMonth(new Date().getMonth() + 12)))}
                            value={dayjs(searchFields?.from_date)}
                            onChange={(newValue) => { setSearchFields((prev) => ({ ...prev, from_date: newValue })) }}
                            sx={{ margin: '15px' }}
                            slotProps={{
                                textField: {
                                    error: errors.from_date ? true : false,
                                    helperText: errors.from_date?.message
                                },
                            }}
                        />

                        <DatePicker
                            label="Job To Date *"
                            format="DD/MM/YYYY"
                            name="to_date"
                            {...register("to_date", { required: "To Date is required" })}
                            minDate={dayjs(searchFields.from_date)}
                            maxDate={dayjs(new Date(new Date().setMonth(new Date().getMonth() + 12)))}
                            value={dayjs(searchFields?.to_date)}
                            onChange={(newValue) => { setSearchFields((prev) => ({ ...prev, to_date: newValue })) }}
                            sx={{ margin: '15px' }}
                            slotProps={{
                                textField: {
                                    error: errors.to_date ? true : false,
                                    helperText: errors.to_date?.message,

                                },
                            }}
                        />
                    </LocalizationProvider>
                </div>
                {_role !== 'E-TRADIE' && _role !== 'P-TRADIE' &&
                    <div className="col-12 col-md-3">
                        <Autocomplete
                            id="technician_id"
                            name="technician_id"
                            {...register("technician_id", { required: false })}
                            options={serviceUsersOptions.length > 0 ? serviceUsersOptions.filter(a => (a.code === 'E-TRADIE' || a.code === 'P-TRADIE')) : []}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.id}>
                                    {option.label}
                                </Box>
                            )}
                            //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                            renderInput={(params) => <TextField {...params} label="Select Technician" variant="outlined"
                                error={!!errors.technician_id}
                                helperText={errors.technician_id?.message}
                            />}
                            onChange={(_, data) => {
                                setSearchFields((prev) => ({ ...prev, technician_id: data ? data.id : '' }))
                            }}

                            value={searchFields.technician_id ? getSelectedTechnician(searchFields.technician_id) : null}
                        />
                    </div>
                }
                <div className="col-12 col-md-2">
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="job_status"
                        name="job_status"
                        {...register("job_status", { required: false })}
                        options={jobStatusOptions}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id} className="text-start">
                                {option.label}
                            </Box>
                        )}
                        //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                        renderInput={(params) => <TextField {...params} label="Select Job Status" variant="outlined"
                            error={!!errors.job_status}
                            helperText={errors.job_status?.message}

                        />}
                        onChange={(_, data) => {
                            setSearchFields((prev) => ({ ...prev, job_status: data ? data.label : '' }))
                        }}

                        value={searchFields.job_status ? getSelectedItem(searchFields.job_status) : null}
                    />
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-around">
                    <Button type="submit" className="themeholy-btn btn-primary m-2" style={{ height: '50px' }} disabled={loaderText}>{(loaderText) ? 'Searching...' : 'Search'}</Button>

                    <Button type="button" className="themeholy-btn btn-secondary m-2" style={{ height: '50px' }} onClick={() => handleSearchReset()} >Reset</Button>
                </div>
            </Box >
            {/*<DataTableListFromServer columns={columns} rows={rows} loading={loader} columnVisibilityModel={{ actions: checkPermission('JOB', 'report') }} page={page} rowCount={rowCount} setPage={setPage} sortModel={sortModel} setSortModel={setSortModel} filterText={filterText} setFilterText={setFilterText} />*/}
            < DataTableList columns={columns} rows={rows} loading={loader} columnVisibilityModel={{ actions: checkPermission('JOB', 'report') }
            } />


            {serviceReportOpen && <ServiceReport serviceReportOpen={serviceReportOpen} handleServiceReportDialogClose={handleServiceReportDialogClose} jobData={jobData} />}
            {invoiceDialogOpen && <InvoiceDialog invoiceDialogOpen={invoiceDialogOpen} handleInvoiceDialogClose={handleInvoiceDialogClose} invoiceData={invoiceData} />}
            {/* {quotationDialogOpen && <QuotationDialog quotationDialogOpen={quotationDialogOpen} handleQuotationDialogClose={handleQuotationDialogClose} quotationData={quotationData} />} */}
            {
                quoteDrawerOpen && <QuotationContext.Provider value={
                    {
                        formField, setFormField,
                        handleQuotationDialogClose,
                        invItems, formChangeFlag, setFormChangeFlag,
                        quotationData, fetchData
                    }
                }>
                    <Drawer anchor="right" open={quoteDrawerOpen} sx={{ zIndex: 1400 }} PaperProps={{ className: 'container p-0' }}>
                        <JobQuotationForm mainAlertStatus={setAlertStatus} mainAlertOpen={setAlertOpen} mainAlertMessage={setAlertMessage} mainAlertAutoClose={handleAutoClose} />
                    </Drawer>
                </QuotationContext.Provider>
            }
        </>
    );
}

export default ManageJobs;