import { useEffect, useContext, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { axiosFunction, clearSession, getSessionItem } from '../../common';
import dayjs from 'dayjs';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Box,
    CircularProgress, Typography, Fade, IconButton, RadioGroup, Radio, FormControlLabel,
    Table, TableBody, TableCell, TableHead, TableRow, Collapse, Alert, TextareaAutosize, Select, MenuItem, FormControl, InputLabel, Checkbox, FormGroup, TextField, Backdrop
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./ManageJobs";
import ImageUploading from "react-images-uploading";
import Resizer from "react-image-file-resizer";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SignatureCanvas from 'react-signature-canvas';
import { CloudDownload } from '@mui/icons-material';

const Checklist = () => {
    const refContent = useRef();
    const signatureCanvasRef = useRef(null);
    const navigate = useNavigate(null);
    const [backdropopen, setBackDropOpen] = useState(false);
    const _role = getSessionItem("_role");
    let category = '';
    let num = 1;
    let secTitle = { 'MAIN': 'SUPPLY MAINS', 'SWITBOARD': 'SWITCHBOARD', 'WIRING': 'WIRING AND ACCESSORIES', 'ELECAPPL': 'FIXED ELECTRICAL APPLIANCES', 'DISTRIBUTION': 'DISTRIBUTION BOARDS', 'GENERATOR': 'SUPPLEMENTARY/STANDBY ENERGY SUPPLY', 'AUDITEST': 'Audit Tests', 'GSINSTALL': 'Installation Check For Application', 'GSSERVICE': 'Servicing For Application', 'GSSPILLAGE': 'Spillage And Combustion Test For Application' }
    const { serviceId, subServiceId, jobData, checkListOpen, handleChecklistClose, setAlertOpen, setAlertStatus, setAlertMessage, fetchData } = useContext(FormContext);
    const [loading, setLoading] = useState(true);
    const [checklistData, setChecklistData] = useState([]);
    const [switchInstalled, setSwitchInstalled] = useState('hide');
    const [distBrdInstalled, setDisBrdInstalled] = useState('hide');
    const [genSupInstalled, setGenSupInstalled] = useState('hide');
    const [clAlertStatus, setCLAlertStatus] = useState('success');
    const [clAlertOpen, setCLAlertOpen] = useState(false);
    const [clAlertMessage, setCLAlertMessage] = useState('');
    const [sign, setSign] = useState('');
    const [showSignErr, setShowSignErr] = useState(false);
    const [changeFlag, setChangeFlag] = useState('N');
    const [loaderText, setLoaderText] = useState(false);
    const [loaderSubmitText, setLoaderSubmitText] = useState(false);
    const [imgChangeFlag, setImgChangeFlag] = useState(false);
    const [remAllImg, setRemAllImg] = useState(jobData.pictures.length > 1 ? 'show' : 'hide');
    const maxNumber = 10;
    const [images, setImages] = useState(jobData.pictures ? jobData.pictures : []);
    const [rImages, setRImages] = useState([]);
    const [auditTestField, setAuditTestField] = useState([{ test: "", result: "P", comments: "" }]);
    const [safetySwitches, setSafetySwitches] = useState(['', '', '', '']);
    const [gasFields, setGasFields] = useState([{ checklist: [] }]);
    const [showImageErr, setShowImageErr] = useState(jobData.pictures.length > 0 ? false : true);

    const getDate = (date, duration) => {
        let vdate = dayjs(new Date(date).setMonth(new Date(date).getMonth() + duration)).format('YYYY/MM/DD');
        return vdate;
    }

    var jDate = jobData.job_date.split('/');
    var jobDt = jDate[2] + '/' + jDate[1] + '/' + jDate[0];

    const insNextDueDate = jobData.next_job_due_date ? jobData.next_job_due_date.split('/').reverse().join('/') : getDate(jobDt, jobData.service.product.service_duration);
    const saNextDueDate = jobData.sa_next_due_date ? jobData.sa_next_due_date.split('/').reverse().join('/') : getDate(jobDt, 12);

    let checklist_title = '';
    let checklist_name = '';

    if (jobData.service_type === 'SA') {
        checklist_title = 'Smoke Alarm Safety Check Only';
        checklist_name = 'Smke Alarm';
    } else if (jobData.service_type === 'ESA') {
        checklist_title = 'Electrical & Smoke Alarm Safety Check';
        checklist_name = 'Electrical';
    } else if (jobData.service_type === 'GS') {
        checklist_title = 'Gas Safety Check';
        checklist_name = 'Gas';
    }

    const curdate = new Date();

    //const [saDueDateFld, setSaDueDateFld] = useState(true);

    const [formField, setFormField] = useState({ recommendation: '', compliance: '', pictures: '', sa_check: '', sa_next_due_date: '', declaration: '', phase_config: '', generator_supply: '', vacant_property: 'N', safety_switches: '', audit_test: '', next_job_due_date: '', all_service_done: '', created_record: '', signature: [] });

    //console.log(images);

    const resizeFile = (file, imageList, i) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                    imageList[i]['data_url'] = uri;
                    setRImages(imageList);
                    // console.log(imageList);
                },
                "base64"
            );
        });

    const onImageChange = (imageList) => {
        setImgChangeFlag(true);
        setShowImageErr(false);

        imageList.map((img, i) => img.file ? resizeFile(img.file, imageList, i) : setRImages(imageList));
        //const file = event.target.files[0];
        //const image = await resizeFile(file);

        // console.log(images);
        setImages(imageList);

        //setFormField((prev) => ({ ...prev, picture: imageList }));

        if (imageList.length > 1) {
            setRemAllImg('show');
        } else {
            setRemAllImg('hide');
        }
    }

    const handleChange = (e, item, i) => {
        setChecklistData(
            checklistData.map(e1 => e1.id === item.id ? { ...e1, checked: e.target.value } : e1)
        );

        if (item.code === 'SWITINSTAL') {
            setSwitchInstalled((e.target.value === 'Y') ? 'show' : 'hide');
            if (e.target.value === 'N') {
                setSafetySwitches(["", "", "", ""]);
                if (checklistData[i + 2].category === item.code) checklistData[i + 2].checked = '';
            }
        }
        if (item.code === 'DISBOARD') {
            setDisBrdInstalled((e.target.value === 'Y') ? 'show' : 'hide');
            if (e.target.value === 'N') {
                setFormField((prev) => ({ ...prev, phase_config: '' }));
                for (var j = 1; j <= 9; j++) {
                    if (checklistData[i + j].category === item.code) checklistData[i + j].checked = '';
                }

            }
        }
    };

    const handleCommentsChange = (e, item) => {
        setChecklistData(
            checklistData.map(e1 => e1.id === item.id ? { ...e1, comments: e.target.value } : e1)
        );
    }

    const CLfetchData = async () => {
        try {
            setBackDropOpen(true);
            const serviceData = await axiosFunction('servicechecklist/' + subServiceId, 'get', {});

            if (serviceData.status === 'success') {
                if (jobData.quotation_id) {
                    if (serviceData.data.checklistdata)
                        setChecklistData(Object.values(serviceData.data.checklistdata));
                    setFormField({
                        declaration: jobData.declaration,
                        vacant_property: jobData.vacant_property ? jobData.vacant_property : 'N',
                        signature: jobData.signature
                    });
                } else {
                    if (jobData.service_type === 'SA') {
                        setFormField({
                            sa_check: jobData.sa_check ? jobData.sa_check : 'N',
                            sa_next_due_date: saNextDueDate.split('/').reverse().join('/'),
                            vacant_property: jobData.vacant_property ? jobData.vacant_property : '',
                            next_job_due_date: insNextDueDate.split('/').reverse().join('/'),
                            recommendation: jobData.recommendation ? jobData.recommendation : '',
                            compliance: jobData.compliance ? jobData.compliance : '',
                            declaration: jobData.declaration,
                            signature: jobData.signature
                        });

                    } else if (jobData.service_type === 'ESA' || jobData.service_type === 'ES') {

                        if (serviceData.data.checklistdata)
                            setChecklistData(Object.values(serviceData.data.checklistdata));

                        // let inspectnextdt = getDate(curdate, jobData.service.product.service_duration);

                        if (jobData.service_type === 'ES') {
                            setFormField({
                                next_job_due_date: insNextDueDate.split('/').reverse().join('/'),
                                phase_config: jobData.phase_config,
                                generator_supply: jobData.generator_supply ? jobData.generator_supply : 'NA',
                                recommendation: jobData.recommendation ? jobData.recommendation : '',
                                compliance: jobData.compliance ? jobData.compliance : '',
                                declaration: jobData.declaration,
                                vacant_property: jobData.vacant_property ? jobData.vacant_property : 'N',
                                signature: jobData.signature
                            });
                        } else {
                            setFormField({
                                sa_check: jobData.sa_check ? jobData.sa_check : 'N',
                                sa_next_due_date: saNextDueDate.split('/').reverse().join('/'),
                                next_job_due_date: insNextDueDate.split('/').reverse().join('/'),
                                phase_config: jobData.phase_config,
                                generator_supply: jobData.generator_supply ? jobData.generator_supply : 'NA',
                                recommendation: jobData.recommendation ? jobData.recommendation : '',
                                compliance: jobData.compliance ? jobData.compliance : '',
                                declaration: jobData.declaration,
                                vacant_property: jobData.vacant_property ? jobData.vacant_property : 'N',
                                signature: jobData.signature
                            });
                        }
                        setAuditTestField(jobData.audit_test ?? [{ test: "", result: "P", comments: "" }]);
                        setSafetySwitches(jobData.safety_switches ?? ['', '', '', '']);
                        setGenSupInstalled((jobData.generator_supply === null || jobData.generator_supply === 'NA') ? 'hide' : 'show');
                        if (serviceData.data.safety_switches_installed && serviceData.data.safety_switches_installed === 'Y') {
                            setSwitchInstalled('show');
                        }
                        if (serviceData.data.board_switches_installed && serviceData.data.board_switches_installed === 'Y') {
                            setDisBrdInstalled('show');
                        }
                    } else if (jobData.service_type === 'GS') {

                        if (serviceData.data.checklistdata)
                            setChecklistData(Object.values(serviceData.data.checklistdata));

                        /* let inspectnextdt = getDate(curdate, jobData.service.product.service_duration);
                        if (jobData.job_date) {
                            inspectnextdt = jobData.job_date.split('/');
                            let date = new Date(inspectnextdt[2] + '-' + inspectnextdt[1] + '-' + inspectnextdt[0]);
                            inspectnextdt = getDate(date, jobData.service.product.service_duration);
                        } */

                        if (serviceData.data.gasElements)
                            setGasFields(serviceData.data.gasElements);

                        setFormField({
                            vacant_property: jobData.vacant_property ? jobData.vacant_property : 'N',
                            next_job_due_date: insNextDueDate.split('/').reverse().join('/'),
                            recommendation: jobData.recommendation ? jobData.recommendation : '',
                            compliance: jobData.compliance ? jobData.compliance : '',
                            declaration: jobData.declaration,
                            all_service_done: jobData.all_service_done,
                            created_record: jobData.created_record,
                            signature: jobData.signature
                        });

                    }
                }
                setTimeout(() => {
                    // Iterate through all images you want to magnify
                    const zimages = document.querySelectorAll('.magnify-image');
                    Array.from(zimages).forEach((image) => {
                        magnify(image, 3);
                    });
                }, 100);

                setLoading(false);
                setBackDropOpen(false);
                if (jobData.signature) {
                    signatureCanvasRef?.current?.fromDataURL(jobData.signature[1]);
                } else {
                    setShowSignErr(true);
                }
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(serviceData.message);
                setCLAlertOpen(true);
                setCLAlertStatus('error');
                setCLAlertMessage(serviceData.message);
                //handleAutoClose();
                setLoading(false);
                setBackDropOpen(false);
                /* if (serviceData.response.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setCLAlertOpen(true);
            setCLAlertStatus('error');
            setCLAlertMessage(e.message);
            //handleAutoClose();
            setLoading(false);
            setBackDropOpen(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }



    useEffect(() => {
        //console.log(serviceId);

        if (subServiceId) {
            CLfetchData();

            /* if (jobData.sa_check === 'Y' && (_role === 'SYS' || _role === 'MG')) {
                setSaDueDateFld(false);
            } */
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subServiceId]);

    /* const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setCLAlertOpen(false), 5000);
    };
 */

    const addAuditTestFields = () => {
        setAuditTestField([...auditTestField, { test: "", result: "P", comments: "" }])
    }

    const handleFieldChange = (i, e) => {
        let newAuditValues = [...auditTestField];
        newAuditValues[i][e.target.name] = e.target.value;
        setAuditTestField(newAuditValues);
    }

    const removeFormFields = (i) => {
        let newAuditValues = [...auditTestField];
        newAuditValues.splice(i, 1);
        setAuditTestField(newAuditValues)
    }

    const handleChkBox = (e, i) => {

        let newSafetySwitches = [...safetySwitches];
        if (e.target.checked) {
            newSafetySwitches[i] = e.target.value;
        } else {
            newSafetySwitches[i] = '';
        }

        setSafetySwitches(newSafetySwitches);
    }

    const handleAddAppliance = () => {
        setGasFields([...gasFields, { checklist: checklistData }]);
        setChangeFlag('Y');
    }

    const handleRemoveAppliance = (i) => {
        let newGasFields = [...gasFields];
        newGasFields.splice(i, 1);
        setGasFields(newGasFields);
        setChangeFlag('Y');
    }

    const handleGasFieldChange = (e, i, j) => {
        let newGasFields = [...gasFields];
        let checklist = newGasFields[i].checklist;
        if (e.target.name === 'checked') {
            setGasFields(
                newGasFields.map((e1, ei) => ei === i ? { ...e1, checklist: checklist.map((e2, ej) => ej === j ? { ...e2, checked: e.target.value } : e2) } : e1)
            );
        } else {
            setGasFields(
                newGasFields.map((e1, ei) => ei === i ? { ...e1, checklist: checklist.map((e2, ej) => ej === j ? { ...e2, comments: e.target.value } : e2) } : e1)
            );
        }
    }

    const handSubmit = async (e) => {

        var data = formField;

        data.service_id = serviceId;
        data.subservice_id = subServiceId;

        if (jobData.quotation_id) {
            if (imgChangeFlag) {
                data.pictures = rImages;
            }

            data.checklist = checklistData;
        } else {

            if (jobData.service_type === 'ESA' || jobData.service_type === 'SA') {
                data.sa_next_due_date = (data.sa_check !== 'Y') ? "" : formField.sa_next_due_date.split('/').reverse().join('/');

                if (data.sa_next_due_date !== '') {
                    var sa_next_due_date_val = data.sa_next_due_date.split('/');
                    if (sa_next_due_date_val[2].length === 4) {
                        data.sa_next_due_date = sa_next_due_date_val[2] + '/' + sa_next_due_date_val[1] + '/' + sa_next_due_date_val[0];
                    }
                }
            }

            if (jobData.service_type === 'ESA' || jobData.service_type === 'ES') {
                if (imgChangeFlag) {
                    data.pictures = rImages;
                }

                data.checklist = checklistData;

                data.audittest = auditTestField;

                data.safetySwitches = safetySwitches;
            }

            if (jobData.service_type === 'SA') {
                if (imgChangeFlag) {
                    data.pictures = rImages;
                }
            }

            if (jobData.service_type === 'GS') {
                //console.log(data);
                //console.log(gasFields);
                data.checklist = gasFields;
                data.changeFlag = changeFlag;
            }

            let next_job_due_date = '';
            if (jobData.service_type === 'ESA' || jobData.service_type === 'ES' || jobData.service_type === 'GS') {
                next_job_due_date = formField.next_job_due_date.split('/');
                if (next_job_due_date[2].length === 4) {
                    data.next_job_due_date = next_job_due_date[2] + '/' + next_job_due_date[1] + '/' + next_job_due_date[0];
                } else data.next_job_due_date = next_job_due_date[0] + '/' + next_job_due_date[1] + '/' + next_job_due_date[2];
            }
        }

        if (!signatureCanvasRef.current.isEmpty() && (!showImageErr || jobData.service_type === 'GS' || jobData.service_type === 'OTH')) {
            data.signature = [];
            data.signature.push(signatureCanvasRef.current.getTrimmedCanvas().toDataURL());
            data.signature.push(signatureCanvasRef.current.toDataURL());
            setShowSignErr(false);

            try {
                setLoaderSubmitText(true);
                setLoaderText(true);

                if (e.target.type === 'submit') {
                    data.saveAll = 'Y';
                } else {
                    data.saveAll = 'N';
                }

                setAlertOpen(true);
                const response = await axiosFunction('servicechecklist/update', 'post', data);

                //console.log(response);
                if (response.status === 'success') {
                    setAlertStatus('success');
                    // handleClose();
                    fetchData();
                    setAlertMessage(response.message);
                    setAlertOpen(false);
                    handleChecklistClose();
                } else {
                    setAlertStatus('error');
                    setAlertMessage(response.message);
                    setCLAlertOpen(true);
                    setCLAlertStatus('error');
                    setCLAlertMessage(response.message);
                    //handleChecklistClose();
                    document.querySelector('#checklistform').scrollIntoView({ behavior: 'smooth' });
                    /* if (response?.response?.status === 401) {
                        clearSession();
                        navigate('/login');
                    } */
                }

            } catch (ce) {
                setAlertStatus('error');
                setAlertMessage(ce.message);
                setCLAlertOpen(true);
                setCLAlertStatus('error');
                setCLAlertMessage(ce.message);
                document.querySelector('#checklistform').scrollIntoView({ behavior: 'smooth' });

                if (ce?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                }
            }
            /* if (e.target.type === 'submit') {
                setLoaderSubmitText(false);
            } else {
                setLoaderText(false);
            } */
            setLoaderSubmitText(false);
            setLoaderText(false);

        } else {
            console.log(showImageErr);
            setShowSignErr(true);
            if (showImageErr && jobData.service_type !== 'OTH') document.querySelector('#supportpictureref').scrollIntoView({ behavior: 'smooth' });
        }
    }

    // magnify hover
    function magnify(img, zoom) {
        var glass, w, h, bw;
        if (!img.parentElement.querySelector('.img-magnifier-glass')) {
            /*create magnifier glass:*/
            glass = document.createElement("DIV");
            glass.setAttribute("class", "img-magnifier-glass");
            /*insert magnifier glass:*/
            img.parentElement.insertBefore(glass, img);
        } else {

            glass = img.parentElement.querySelector('.img-magnifier-glass');
        }
        if (glass) {

            /*set background properties for the magnifier glass:*/
            glass.style.backgroundImage = "url('" + img.src + "')";
            glass.style.backgroundRepeat = "no-repeat";
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
            bw = 3;
            w = glass.offsetWidth / 2;
            h = glass.offsetHeight / 2;
            /*execute a function when someone moves the magnifier glass over the image:*/
            glass.addEventListener("mousemove", moveMagnifier);
            img.addEventListener("mousemove", moveMagnifier);
            /*and also for touch screens:*/
            glass.addEventListener("touchmove", moveMagnifier);
            img.addEventListener("touchmove", moveMagnifier);
        }
        function moveMagnifier(e) {
            var pos, x, y;
            /*prevent any other actions that may occur when moving over the image*/
            e.preventDefault();
            /*get the cursor's x and y positions:*/
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;
            /*prevent the magnifier glass from being positioned outside the image:*/
            if (x > img.width - (w / zoom)) {
                x = img.width - (w / zoom);
            }
            if (x < w / zoom) {
                x = w / zoom;
            }
            if (y > img.height - (h / zoom)) {
                y = img.height - (h / zoom);
            }
            if (y < h / zoom) {
                y = h / zoom;
            }
            /*set the position of the magnifier glass:*/
            glass.style.left = (x - w) + "px";
            glass.style.top = (y - h) + "px";
            /*display what the magnifier glass "sees":*/
            glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
        }

        function getCursorPos(e) {
            var a, x = 10,
                y = 10;
            e = e || window.event;
            /*get the x and y positions of the image:*/
            a = img.getBoundingClientRect();
            /*calculate the cursor's x and y coordinates, relative to the image:*/
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /*consider any page scrolling:*/
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {
                x: x,
                y: y
            };
        }
    }

    const handleImgDownload = async (imageUrl) => {
        try {
            //console.log(imageUrl);
            // Fetch the image as a blob using Axios
            /* const response = await axios.get(imageUrl, {
                 responseType: 'blob', // Ensure the response is a Blob
             });*/

            if (imageUrl.startsWith('data:image/')) {
                // Create a temporary URL for the blob
                // const url = window.URL.createObjectURL(new Blob([response.data_url]));
                const url = imageUrl;

                // Create an anchor element
                const link = document.createElement('a');
                link.href = url;

                // Set the download attribute with the desired file name
                link.download = 'image_' + new Date().getTime() + '.jpg';

                // Append the link to the body, click it, and remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Revoke the blob URL to free up memory
                window.URL.revokeObjectURL(url);
            } else {
                const imagename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

                const response = await axiosFunction('auth/downloadimage/' + imagename, 'get', {});

                if (response.status === 'success') {
                    // Create a temporary URL for the blob
                    // const url = window.URL.createObjectURL(new Blob([response.data_url]));
                    const url = response.data_url;

                    // Create an anchor element
                    const link = document.createElement('a');
                    link.href = url;

                    // Set the download attribute with the desired file name
                    link.download = imagename;

                    // Append the link to the body, click it, and remove it
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Revoke the blob URL to free up memory
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('Error downloading the image');
                }
            }
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (

        <Dialog
            fullScreen
            open={checkListOpen}
            onClose={handleChecklistClose}
            scroll='body'
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme text-center">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <IconButton
                aria-label="close"
                onClick={handleChecklistClose}
                sx={{
                    position: 'absolute',
                    right: 5,
                    top: 5,
                    color: 'white'
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle id="scroll-checklist-dialog" className="app-header-top text-center" component="header">
                <Typography
                    sx={{ display: 'block', marginTop: '25px', marginBottom: '15px' }}
                    component="div"
                    variant="h5"
                    color="white"
                >
                    Residential Tenancies Regulations {curdate.getFullYear()}
                </Typography>
                <Typography
                    sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                    component="h6"
                    variant="sub-title"
                    color="white"
                >
                    Checklist for {checklist_title}
                </Typography>
                <Typography

                    className="text-center"
                >
                    {jobData.service_type === 'GS' ? <>
                        Appliance servicing Regulation 12(4) of the Gas Safety (Gas Installation)
                        Regulations 2018 prescribes AS4575 as the standard for Type A appliance
                        servicing work on an appliance that is part of a standard gas installation.
                    </>
                        : <>


                            This {checklist_name} installation audit report is not a substitute for a Certificate of {checklist_name} Safety and cannot be relied on as part of certification of a Certificate of {checklist_name} Safety.
                        </>
                    }
                </Typography>
            </DialogTitle>

            <DialogContent dividers={false}>
                <div className='row mt-3 mb-5'>

                    <div className='col-md-2'>Actual Service Date:</div>
                    <div className='col-md-6 fw-bold  mb-2'>{jobData.job_date}</div>

                    <div className='col-md-2'>Inspection Date:</div>
                    <div className='col-md-2 fw-bold mb-2'>{jobData.next_job_due_date ? jobData.job_date : dayjs(curdate).format('DD/MM/YYYY')}</div>

                    <div className='col-md-2'>Technician Name:</div>
                    <div className='col-md-6 fw-bold mb-2'>{jobData.technician && jobData.technician.prefix + ' ' + jobData.technician.fullname}</div>

                    <div className='col-md-2'>Licence / Registration Number:</div>
                    <div className='col-md-2 fw-bold'>{jobData.technician && jobData.technician.license_number}</div>

                    <div className='col-md-12 mt-3 mb-2'><Typography
                        variant='h6'
                        className='sub-title'
                    >Property Details</Typography></div>

                    <div className='col-12 col-md-8'>
                        <div>Address:</div>
                        <div className='fw-bold mb-2'>{jobData.service.property && (jobData.service.property.address1 ? jobData.service.property.address1 + ', ' : '') + (jobData.service.property.address2 ? jobData.service.property.address2 + ',' : '')} <br /> {jobData.service.property.suburb + ', ' + jobData.service.property.state + ' - ' + jobData.service.property.postcode}</div>
                        {jobData.service.property.tenancy_name && <div>Tenancy: <br /> {jobData.service.property.tenancy_name}<br />
                            Ph: {jobData.service.property.tenancy_mobile}</div>}
                    </div>

                    <div className='col-12 col-md-4'>
                        {jobData.service.property && jobData.service.property.propertyowner && <>
                            <div className='col-md-2'>Landlord:</div>
                            <div className='col-md-6 fw-bold mb-2'>{(jobData.service.property.propertyowner.prefix ? jobData.service.property.propertyowner.prefix + '. ' : '') + jobData.service.property.propertyowner.fullname}
                                {_role !== 'E-TRADIE' && _role !== 'P-TRADIE' && <>
                                    <br />
                                    Ph: {jobData.service.property.propertyowner.individualuser.mobile}
                                </>}
                            </div>
                        </>
                        }
                        {jobData.service.property && jobData.service.property.propertyagent && <>
                            <div className='col-md-2'>Agent:</div>
                            <div className='col-md-6 fw-bold mb-2'>{(jobData.service.property.propertyagent.prefix ? jobData.service.property.propertyagent.prefix + '. ' : '') + jobData.service.property.propertyagent.fullname}
                                {_role !== 'E-TRADIE' && _role !== 'P-TRADIE' && <>
                                    <br />
                                    Ph: {jobData.service.property.propertyagent.individualuser.mobile}
                                </>}
                            </div>
                        </>}
                    </div>


                </div>

                <div>

                    <Checkbox className='m-0 p-0' name="vacant_property" checked={formField.vacant_property === 'Y'} onChange={(e) => { setFormField((prev) => ({ ...prev, vacant_property: e.target.checked ? 'Y' : 'N' })) }} /> Vacant Property
                    <p className='p-1'>In the event of a vacant property, it is assumed that permission has been given for myself to carry out the inspection on behalf of the rental provider or agent.</p>

                </div>

                <Box id="checklistform">
                    <Box sx={{ textAlign: 'center' }}>
                        <Fade
                            in={loading}
                            unmountOnExit
                        >
                            <CircularProgress />
                        </Fade>
                    </Box>
                    <Collapse in={clAlertOpen}>
                        <Alert variant="filled" severity={clAlertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setCLAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }

                        >
                            {clAlertMessage}
                        </Alert>
                    </Collapse>
                    {jobData.quotation_id ? <>
                        <div key="cont-quotation">
                            <div className="mb-3 mt-4">
                                <Typography
                                    variant='h6'
                                    className='sub-title'
                                >
                                    Rectification Items
                                </Typography>
                            </div>

                            {checklistData && checklistData.map((item, index) => (
                                <div className='row m-auto mb-2' key={"cont-" + index}>
                                    <div className='col-12 col-md-6 bg-smoke2 black-color mb-2 pt-1 pb-2 d-flex align-items-center'>{item.description}</div>
                                    <div className='col-12 col-md-6'>
                                        <RadioGroup
                                            row
                                            name={"radio-buttons-" + index}
                                            value={item.status ? item.status : ''}
                                            onChange={(e) => {
                                                setChecklistData(
                                                    checklistData.map(e1 => e1.id === item.id ? { ...e1, status: e.target.value } : e1)
                                                );
                                            }}
                                            className='col-11'
                                        >
                                            <FormControlLabel value="Y" control={<Radio />} label="Yes" className='radioBox' />
                                            <FormControlLabel value="N" control={<Radio />} label="No" className='radioBox' />
                                            <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                        </RadioGroup>
                                    </div>
                                </div>
                            ))}

                            <Table sx={{ marginTop: '15px' }}>
                                <TableHead className="bg-smoke text-body">
                                    <TableRow >
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                className="sec-title fw-bold"
                                            >
                                                Upload any supporting picture here
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onImageChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={['jpg', 'jpeg', 'png']}
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                    errors
                                                }) => (
                                                    <div id='supportpictureref'>
                                                        <button type="button" className="btn btn-primary mx-2 my-2 " onClick={onImageUpload}>Choose Images</button>

                                                        <button type="button" className={"btn btn-danger mx-2 my-2 " + remAllImg} onClick={onImageRemoveAll}>Remove all images</button>
                                                        <div {...dragProps} className="border border-light p-1" style={{ minHeight: '100px' }}>
                                                            <p className="m-1">{isDragging ? "Drop here please" : "Drag & Drop photos here to upload"}. Maximum 10 photos and JPG, JPEG and PNG image types are allowed.</p>
                                                            <div className="container">
                                                                <div className="row">
                                                                    {images && images.map((image, index) => (
                                                                        <div key={index} className="card col-md-3 p-0 m-1">
                                                                            <div className="card-body image-item text-center">
                                                                                {/* <img src={image.data_url} alt={"image" + index} style={{ width: '100%' }} /> */}
                                                                                <img width="100%" border="0" className="cover pstrart magnify-image" alt={"image" + index} src={image.data_url} />

                                                                                <div className="image-item__btn-wrapper mt-3">
                                                                                    <button type="button" onClick={() => onImageUpdate(index)} className="m-1 border-0 bg-white"><i className="fa fa-edit"></i></button>
                                                                                    <CloudDownload onClick={() => handleImgDownload(image.data_url)} style={{ color: 'blue' }} />
                                                                                    <button type="button" onClick={() => { onImageRemove(index); if (!images.length || images.length === 1) { setRemAllImg('hide') } else if (!images.length) { setShowImageErr(true) } }} className="m-1 border-0 bg-white"><i className="fa fa-close"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {showImageErr && <label className="w-full error text-red text-sm text-start">Supporting picture is required</label>}
                                                            </div>
                                                        </div>
                                                        {errors && <div className="text-danger">
                                                            {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                                            {errors.acceptType && <span>Your selected file type is not allow</span>}
                                                            {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                                            {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                                        </div>}
                                                    </div>

                                                )}
                                            </ImageUploading>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </> : <>

                        {(jobData.service_type === 'ES' || jobData.service_type === 'ESA') && (<div ref={refContent}>
                            {checklistData && checklistData.map((item, index) => (

                                <div key={"cont-" + index}>
                                    {
                                        (item.category !== 'SWITINSTAL' && item.category !== 'DISBOARD' && item.category !== 'GENSUPPLY' && category !== item.category) && (
                                            <div className={"mb-3 " + (index > 0 ? "mt-4" : "")}>
                                                <Typography
                                                    variant='h6'
                                                    className='sub-title'
                                                >
                                                    Part {num++} - {secTitle[category = item.category]}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    <div className={'row m-auto mb-2 ' + (item.category === 'SWITINSTAL' ? switchInstalled : (item.category === 'DISBOARD' ? distBrdInstalled : (item.category === 'GENSUPPLY' ? genSupInstalled : '')))}>
                                        <div className='col-12 col-md-6 bg-smoke2 black-color mb-2 pt-1 pb-2 d-flex align-items-center'>{item.description}</div>
                                        <div className='col-12 col-md-6'>
                                            {item.code === 'GENSUPPLY' ? <FormControl sx={{ minWidth: 300 }} variant="standard">
                                                <InputLabel id={"gen_sub-label-" + item.id}>Select Generator Supply Type</InputLabel>
                                                <Select
                                                    id={"gen_sub-label-" + item.id}
                                                    labelId={"gen_sub-label-" + item.id}
                                                    name={"generator_supply-" + item.id}
                                                    value={formField.generator_supply ? formField.generator_supply : 'NA'}
                                                    onChange={(e) => {
                                                        setFormField((prev) => ({ ...prev, generator_supply: e.target.value })); setGenSupInstalled((e.target.value !== 'NA') ? 'show' : 'hide');
                                                        if (e.target.value === 'NA') {
                                                            for (var j = 1; j <= 8; j++) {
                                                                if (checklistData[index + j].category === item.code) checklistData[index + j].checked = '';
                                                            }
                                                        }
                                                    }}
                                                    autoWidth

                                                >

                                                    <MenuItem value="NA">N/A</MenuItem>
                                                    <MenuItem value="Motor Generator">Motor Generator</MenuItem>
                                                    <MenuItem value="Solar PV">Solar PV</MenuItem>
                                                    <MenuItem value="Wind Turbine">Wind Turbine</MenuItem>
                                                    <MenuItem value="Water Generator">Water Generator</MenuItem>
                                                </Select>
                                            </FormControl> :
                                                (item.code === 'SWITINSTAL' || item.code === 'DISBOARD') ?
                                                    <RadioGroup
                                                        row
                                                        name={"radio-buttons-" + index}
                                                        value={checklistData[index].checked ? checklistData[index].checked : 'N'}
                                                        onChange={(e) => handleChange(e, item, index)}
                                                    >
                                                        <FormControlLabel value="Y" control={<Radio />} label="Yes" className='radioBox' />
                                                        <FormControlLabel value="N" control={<Radio />} label="No" className='radioBox' />
                                                    </RadioGroup> : (
                                                        (item.code === 'SAFSWITCH') ?
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox onChange={(e) => handleChkBox(e, 0)} name="safety_switches[]" value='Installed On All Socket Outlet Circuits' checked={!safetySwitches[0] ? false : true} />} label='Installed On All Socket Outlet Circuits' />
                                                                <FormControlLabel control={<Checkbox onChange={(e) => handleChkBox(e, 1)} name="safety_switches[]" value='Installed on all lighting circuits' checked={!safetySwitches[1] ? false : true} />} label='Installed on all lighting circuits' />
                                                                <FormControlLabel control={<Checkbox onChange={(e) => handleChkBox(e, 2)} name="safety_switches[]" value='Installed on all fixed equipment circuits' checked={!safetySwitches[2] ? false : true} />} label='Installed on all fixed equipment circuits' />
                                                                <FormControlLabel control={<Checkbox onChange={(e) => handleChkBox(e, 3)} name="safety_switches[]" value='Other' checked={!safetySwitches[3] ? false : true} />} label='Other' />
                                                            </FormGroup>
                                                            :
                                                            (item.category === 'AUDITEST') ?
                                                                <div className='row'>
                                                                    <RadioGroup
                                                                        row
                                                                        name={"radio-buttons-" + index}
                                                                        value={checklistData[index].checked ?? ''}
                                                                        onChange={(e) => handleChange(e, item)}
                                                                        className='col-11'
                                                                    >
                                                                        <FormControlLabel value="P" control={<Radio />} label="Pass" className='radioBox' />
                                                                        <FormControlLabel value="F" control={<Radio />} label="Fail" className='radioBox' />
                                                                        <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                                                    </RadioGroup>
                                                                    <TextareaAutosize className='col-11 m-3 mt-1' style={{ minHeight: '45px', padding: '2px 5px' }} name={"audit-comments" + index} placeholder='Enter comments/notes' maxLength={160} value={(checklistData[index].comments !== '' && checklistData[index].comments !== null) ? checklistData[index].comments : ''} onChange={(e) => handleCommentsChange(e, item)}></TextareaAutosize>
                                                                </div> :
                                                                <RadioGroup
                                                                    row
                                                                    name={"radio-buttons-" + index}
                                                                    value={checklistData[index].checked ? checklistData[index].checked : 'N'}
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    required
                                                                >
                                                                    <FormControlLabel value="AD" control={<Radio />} label="Adequate" className='radioBox' />
                                                                    <FormControlLabel value="AT" control={<Radio />} label="Attention Recommended" className='radioBox' />
                                                                    <FormControlLabel value="US" control={<Radio />} label="UnSafe" className='radioBox' />
                                                                    <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                                                </RadioGroup>
                                                    )}


                                        </div>
                                    </div>


                                    {item.category === 'DISTRIBUTION' && (
                                        <div className={'row m-auto mb-2 ' + distBrdInstalled}>
                                            <div className='col-12 col-md-6 bg-smoke2 black-color mb-2 pt-1 pb-2 d-flex align-items-center'>Phase Configuration</div>
                                            <div className='col-12 col-md-6'>
                                                <FormControl sx={{ minWidth: 300 }} variant="standard">
                                                    <InputLabel id={"phase_config-label-" + item.id}>Select Phase Configuration</InputLabel>
                                                    <Select
                                                        id={"phase_config-label-" + item.id}
                                                        labelId={"phase_config-label-" + item.id}
                                                        name={"phase_config-" + item.id}
                                                        value={formField.phase_config ? formField.phase_config : ''}
                                                        onChange={(e) => setFormField((prev) => ({ ...prev, phase_config: e.target.value }))}
                                                        autoWidth
                                                    >
                                                        <MenuItem></MenuItem>
                                                        <MenuItem value="Single Phase">Single Phase</MenuItem>
                                                        <MenuItem value="Two Phases">Two Phases</MenuItem>
                                                        <MenuItem value="Three Phases">Three Phases</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='col-12 sub-title p-3 m-0'>Distribution Boards Rating</div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className="mb-3 mt-4">
                                <Typography
                                    variant='h6'
                                    className='sub-title'
                                >
                                    Additional Optional Tests
                                </Typography>
                            </div>
                            {auditTestField.map((element, index) => (
                                <div className='row m-auto mb-2' key={"audit-" + index}>
                                    <div className='col-12 col-md-6 bg-smoke2 black-color mb-2 pt-1 pb-2 d-flex align-items-center'>
                                        <Link to='#' onClick={() => removeFormFields(index)} style={{ marginRight: '10px' }}><i className="fa fa-xmark-circle" ></i></Link>

                                        <TextField
                                            fullWidth
                                            name="test"
                                            variant="outlined"
                                            inputProps={{ value: element.test ? element.test : '', maxLength: 100, sx: 'padding:5px' }}
                                            className='bg-white'
                                            onChange={e => handleFieldChange(index, e)}
                                            placeholder='Enter the name of the appliance'
                                        />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className='row'>
                                            <RadioGroup
                                                row
                                                name="result"
                                                value={element.result ? element.result : 'P'}
                                                onChange={(e) => handleFieldChange(index, e)}
                                                className='col-11'
                                            >
                                                <FormControlLabel value="P" control={<Radio />} label="Pass" className='radioBox' />
                                                <FormControlLabel value="F" control={<Radio />} label="Fail" className='radioBox' />
                                            </RadioGroup>
                                            <TextareaAutosize className='col-11 m-3 mt-1' style={{ minHeight: '45px', padding: '2px 5px' }} placeholder='Enter comments/notes' maxLength={160} name="comments" value={(element.comments !== '' && element.comments !== null) ? element.comments : ''} onChange={(e) => handleFieldChange(index, e)}></TextareaAutosize>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='col-12'><Button type="button" className="themeholy-btn btn-primary" onClick={() => addAuditTestFields()}>+ Add More Test (if any)</Button></div>
                        </div>
                        )}

                        {jobData.service_type === 'GS' && (<div ref={refContent}>
                            {gasFields.map((gaselement, gindex) => (
                                <div key={"gas-" + gindex}>
                                    <div className="mb-3 mt-4" id={(category = 1) + gindex}>
                                        <Typography
                                            variant='h6'
                                            className='sub-title'
                                        >
                                            <Link to='#' onClick={() => handleRemoveAppliance(gindex)} style={{ marginRight: '10px' }}><i className="fa fa-xmark-circle" style={{ fontSize: '20px' }} ></i></Link> Gas Appliance {gindex + 1}
                                        </Typography>
                                    </div>
                                    {gaselement.checklist && gaselement.checklist.map((item, index) => (
                                        <div key={"cont-" + gindex + "-" + index}>
                                            {(item.code === 'GASNAME') ? <div className='row m-auto mb-2'><div className='col-12 col-md-6 bg-smoke2 black-color mb-2 pt-1 pb-2 d-flex align-items-center'>{item.description}</div>
                                                <div className='col-12 col-md-6'><TextField
                                                    fullWidth
                                                    name="gasname"
                                                    variant="outlined"
                                                    inputProps={{ value: gaselement.checklist[index].comments ? gaselement.checklist[index].comments : '', maxLength: 100 }}
                                                    className='bg-white'
                                                    onChange={e => handleGasFieldChange(e, gindex, index)}
                                                    placeholder='Enter the gas application name'
                                                /></div></div>
                                                :
                                                (item.code === 'GASCOMMENT') ? <div className='row m-auto mb-2'><div className='col-12 text-theme mb-2 pt-1 pb-2 d-flex align-items-center'>{item.description}</div>
                                                    <div className='col-12'>
                                                        <TextareaAutosize style={{ backgroundColor: 'white', borderColor: '#eee', minHeight: '50px' }} name="gascomments"
                                                            onChange={(e) => handleGasFieldChange(e, gindex, index)}
                                                            value={gaselement.checklist[index].comments ? gaselement.checklist[index].comments : ''}
                                                        >
                                                        </TextareaAutosize></div></div> :
                                                    (item.code === 'GASPICTURE') ? <div className='row m-auto mb-2'><div className='col-12 text-theme mb-2 pt-1 pb-2 d-flex align-items-center'>{item.description}</div>
                                                        <div className='col-12'>
                                                            <Table sx={{ marginTop: '15px' }} aria-label="CSection">
                                                                <TableHead className="bg-smoke text-body">
                                                                    <TableRow >
                                                                        <TableCell>
                                                                            <Typography
                                                                                component="div"
                                                                                className="sec-title fw-bold"
                                                                            >
                                                                                Upload any supporting picture here
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <ImageUploading
                                                                                multiple
                                                                                value={gaselement.checklist[index].pictures}
                                                                                onChange={(uploadList) => {
                                                                                    //console.log(gaselement.checklist[index].pictures)                                                                                    
                                                                                    let newGasFields = [...gasFields];
                                                                                    let checklist = newGasFields[gindex].checklist;
                                                                                    uploadList.map((img, i) => img.file ? new Promise((resolve) => {
                                                                                        Resizer.imageFileResizer(
                                                                                            img.file,
                                                                                            1024,
                                                                                            1024,
                                                                                            "JPEG",
                                                                                            100,
                                                                                            0,
                                                                                            (uri) => {
                                                                                                resolve(uri);
                                                                                                uploadList[i]['data_url'] = uri;
                                                                                                setGasFields(
                                                                                                    newGasFields.map((e1, ei) => ei === gindex ? { ...e1, checklist: checklist.map((e2, ej) => ej === index ? { ...e2, pictures: uploadList } : e2) } : e1)
                                                                                                );
                                                                                                // console.log(imageList);
                                                                                            },
                                                                                            "base64"
                                                                                        );
                                                                                    }) : setGasFields(
                                                                                        newGasFields.map((e1, ei) => ei === gindex ? { ...e1, checklist: checklist.map((e2, ej) => ej === index ? { ...e2, pictures: uploadList } : e2) } : e1)
                                                                                    ));

                                                                                    //setRImages(uploadList)

                                                                                }}
                                                                                maxNumber={maxNumber}
                                                                                dataURLKey="data_url"
                                                                                acceptType={['jpg', 'jpeg', 'png']}
                                                                            >
                                                                                {({
                                                                                    uploadList,
                                                                                    onImageUpload,
                                                                                    onImageRemoveAll,
                                                                                    onImageUpdate,
                                                                                    onImageRemove,
                                                                                    isDragging,
                                                                                    dragProps,
                                                                                    errors
                                                                                }) => (
                                                                                    <div id={'supportpictureref' + index}>
                                                                                        <button type="button" className="btn btn-primary mx-2 my-2 " onClick={onImageUpload}>Choose Images</button>
                                                                                        <div {...dragProps} className="border border-light p-1" style={{ minHeight: '100px' }}>
                                                                                            <p className="m-1">{isDragging ? "Drop here please" : "Drag & Drop photos here to upload"}. Maximum 10 photos and JPG, JPEG and PNG image types are allowed.</p>
                                                                                            <div className="container">
                                                                                                <div className="row">
                                                                                                    {gaselement.checklist[index].pictures && gaselement.checklist[index].pictures.map((image, imindx) => (
                                                                                                        <div key={gindex + "-" + index + "-" + imindx} className="card col-md-3 p-0 m-1">
                                                                                                            <div className="card-body image-item text-center">
                                                                                                                {/* <img src={image.data_url} alt={"image" + imindx} style={{ width: '100%' }} /> */}
                                                                                                                <img width="100%" border="0" className="cover pstrart magnify-image" alt={"image-" + index + '-' + imindx} src={image.data_url} style={{}} />
                                                                                                                <div className="image-item__btn-wrapper mt-3">
                                                                                                                    <button type="button" onClick={() => onImageUpdate(imindx)} className="m-1 border-0 bg-white"><i className="fa fa-edit"></i></button>
                                                                                                                    <CloudDownload onClick={() => handleImgDownload(image.data_url)} style={{ color: 'blue' }} />
                                                                                                                    <button type="button" onClick={() => { onImageRemove(imindx); if (gaselement.checklist[index].pictures.length === 0) setShowImageErr(true) }} className="m-1 border-0 bg-white"><i className="fa fa-close"></i></button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {errors && <div className="text-danger">
                                                                                            {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                                                                            {errors.acceptType && <span>Your selected file type is not allow</span>}
                                                                                            {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                                                                            {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                                                                        </div>}
                                                                                    </div>

                                                                                )}
                                                                            </ImageUploading>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </div></div>
                                                        : <div className='row m-auto mb-2'><div className='col-12 col-md-6 bg-smoke2 black-color mb-2 pt-1 pb-2 d-flex align-items-center'>{category++}. {item.description}</div>
                                                            <div className='col-12 col-md-6'>
                                                                <RadioGroup
                                                                    row
                                                                    name={"checked"}
                                                                    value={gaselement.checklist[index].checked ? gaselement.checklist[index].checked : ''}
                                                                    onChange={(e) => handleGasFieldChange(e, gindex, index)}
                                                                >
                                                                    <FormControlLabel value="Y" control={<Radio />} label="Yes" className='radioBox' />
                                                                    <FormControlLabel value="N" control={<Radio />} label="No" className='radioBox' />
                                                                    <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                                                </RadioGroup>
                                                            </div></div>

                                            }
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <Button type="button" className="themeholy-btn btn-primary" onClick={() => handleAddAppliance()}>+ Add Appliance</Button>

                            <div className="row mt-3">
                                <div className='col-12'>
                                    Appliance servicing Regulation 12(4) of the Gas Safety (Gas Installation)
                                    Regulations 2018 prescribes AS4575 as the standard for Type A appliance servicing work on an appliance that is part of a standard gas installation
                                    <p className='fw-bold'>
                                        I have serviced all appliances in accordance with AS 4575
                                    </p>
                                </div>
                                <div className='col-12 mb-3'>
                                    <RadioGroup
                                        row
                                        name="all_service_done"
                                        value={formField.all_service_done ?? ''}
                                        onChange={(e) => setFormField((prev) => ({ ...prev, all_service_done: e.target.value }))}
                                    >
                                        <FormControlLabel value="Y" control={<Radio />} label="Yes" className='radioBox' />
                                        <FormControlLabel value="N" control={<Radio />} label="No" className='radioBox' />
                                        <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                    </RadioGroup>
                                </div>
                                <div className='col-12'>
                                    <p>I have created a record (VBA online) under regulation 36(2) or 37(2) of
                                        the Gas Safety (Gas Installation) Regulations 2018 and provided a copy
                                        to the rental provider under the regulation 30 (1)(ab) of the Residential
                                        Tenancies Regulations 2021</p>
                                </div>
                                <div className='col-12'>
                                    <RadioGroup
                                        row
                                        name="created_record"
                                        value={formField.created_record ?? ''}
                                        onChange={(e) => setFormField((prev) => ({ ...prev, created_record: e.target.value }))}
                                    >
                                        <FormControlLabel value="Y" control={<Radio />} label="Yes" className='radioBox' />
                                        <FormControlLabel value="N" control={<Radio />} label="No" className='radioBox' />
                                        <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>

                        )}


                        {(jobData.service_type !== 'ES' && (jobData.service_type === 'ESA' || jobData.service_type === 'SA')) && (<>
                            <Table sx={{ marginTop: '15px' }} aria-label="CSection">
                                <TableHead className="bg-smoke text-body">
                                    <TableRow >
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                className="sec-title fw-bold"
                                            >
                                                Smoke Alarms
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: 'white' }}>
                                        <TableCell>

                                            All smoke alarms must be tested according to the
                                            manufacturer's instructions at least once every 12 months

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <div className='row'>
                                                <div className='col-12 col-md-6 mb-2'>All smoke alarm are correctly installed and in working
                                                    condition; and have been tested according to the
                                                    manufacturer's instructions. </div>
                                                <div className='col-12 col-md-6'>
                                                    <RadioGroup
                                                        row
                                                        name="smoke-radio-buttons"
                                                        value={formField.sa_check ?? ''}
                                                        onChange={(e) => {
                                                            if (e.target.value !== 'Y') {
                                                                setFormField((prev) => ({ ...prev, sa_check: e.target.value, sa_next_due_date: '' }))
                                                            } else {
                                                                setFormField((prev) => ({ ...prev, sa_check: e.target.value, sa_next_due_date: saNextDueDate.split('/').reverse().join('/') }))
                                                            }
                                                        }
                                                        }
                                                    >
                                                        <FormControlLabel value="Y" control={<Radio />} label="Yes" className='radioBox' />
                                                        <FormControlLabel value="N" control={<Radio />} label="No" className='radioBox' />
                                                        <FormControlLabel value="NA" control={<Radio />} label="N/A" className='radioBox' />
                                                    </RadioGroup>
                                                </div>

                                                <div className='col-12 col-md-6 mt-2 mb-2'>Next smoke alarms check is due by:</div>
                                                <div className='col-12 col-md-6'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            name="sa_due_date"
                                                            sx={{ width: '100%' }}
                                                            disablePast
                                                            label="Next Smoke Alarm Check Due Date"
                                                            format="DD/MM/YYYY"
                                                            value={dayjs(formField.sa_check === 'Y' ? saNextDueDate : '')}
                                                            onChange={(newValue) => { setFormField((prev) => ({ ...prev, sa_next_due_date: newValue })) }}
                                                        //readOnly={saDueDateFld}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </>)}

                        {(jobData.service_type === 'ESA' || jobData.service_type === 'ES' || jobData.service_type === 'SA') && <><Table sx={{ marginTop: '15px' }}>
                            <TableHead className="bg-smoke text-body">
                                <TableRow >
                                    <TableCell>
                                        <Typography
                                            component="div"
                                            className="sec-title fw-bold"
                                        >
                                            Upload any supporting picture here
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className='img-magnifier-container'>
                                        <ImageUploading
                                            multiple
                                            value={images}
                                            onChange={onImageChange}
                                            maxNumber={maxNumber}
                                            dataURLKey="data_url"
                                            acceptType={['jpg', 'jpeg', 'png']}
                                        >
                                            {({
                                                imageList,
                                                onImageUpload,
                                                onImageRemoveAll,
                                                onImageUpdate,
                                                onImageRemove,
                                                isDragging,
                                                dragProps,
                                                errors
                                            }) => (
                                                <div id='supportpictureref'>
                                                    <button type="button" className="btn btn-primary mx-2 my-2 " onClick={onImageUpload}>Choose Images</button>

                                                    <button type="button" className={"btn btn-danger mx-2 my-2 " + remAllImg} onClick={onImageRemoveAll}>Remove all images</button>
                                                    <div {...dragProps} className="border border-light p-1" style={{ minHeight: '100px' }}>
                                                        <p className="m-1">{isDragging ? "Drop here please" : "Drag & Drop photos here to upload"}. Maximum 10 photos and JPG, JPEG and PNG image types are allowed.</p>
                                                        <div className="container">
                                                            <div className="row">
                                                                {images && images.map((image, index) => (
                                                                    <div key={index} className="card col-md-3 p-0 m-1">
                                                                        <div className="card-body image-item text-center">
                                                                            {/* <img src={image.data_url} alt={"image" + index} style={{ width: '100%' }} /> */}

                                                                            <img width="100%" border="0" className="cover pstrart magnify-image" alt={"image" + index} src={image.data_url} style={{}} />

                                                                            <div className="image-item__btn-wrapper mt-3">
                                                                                <button type="button" onClick={() => onImageUpdate(index)} className="m-1 border-0 bg-white"><i className="fa fa-edit"></i></button>
                                                                                <CloudDownload onClick={() => handleImgDownload(image.data_url)} style={{ color: 'blue' }} />
                                                                                <button type="button" onClick={() => { onImageRemove(index); if (!images.length || images.length === 1) { setRemAllImg('hide') } else if (!images.length) { setShowImageErr(true) } }} className="m-1 border-0 bg-white"><i className="fa fa-close"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {showImageErr && <label className="w-full error text-red text-sm text-start">Supporting picture is required</label>}
                                                        </div>
                                                    </div>
                                                    {errors && <div className="text-danger">
                                                        {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                                        {errors.acceptType && <span>Your selected file type is not allow</span>}
                                                        {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                                        {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                                    </div>}
                                                </div>

                                            )}
                                        </ImageUploading>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                            <Table sx={{ marginTop: '15px' }} aria-label="OSection">
                                <TableHead className="bg-smoke text-body">
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                className="sec-title fw-bold"
                                            >
                                                OBSERVATIONS AND RECOMMENDATIONS FOR ANY ACTIONS TO BE TAKEN
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            The following observations and recommendations are made:
                                            <TextareaAutosize style={{ backgroundColor: 'white', borderColor: '#eee', minHeight: '50px' }} name="recommendation"
                                                onChange={(e) => { setFormField((prev) => ({ ...prev, recommendation: e.target.value })) }}
                                                value={formField.recommendation ? formField.recommendation : ''}
                                            >
                                            </TextareaAutosize>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            The following work is required for compliance purposes:
                                            <TextareaAutosize style={{ backgroundColor: 'white', borderColor: '#eee', minHeight: '50px' }} name="compliance"
                                                onChange={(e) => { setFormField((prev) => ({ ...prev, compliance: e.target.value })) }}
                                                value={formField.compliance ? formField.compliance : ''}
                                            >
                                            </TextareaAutosize>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table></>}
                        {jobData.service_type === 'GS' &&
                            <Table sx={{ marginTop: '15px' }}>
                                <TableHead className="bg-smoke text-body">
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                className="sec-title fw-bold"
                                            >
                                                Dangerous Gas Installations
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Gas Safety (Gas Installation) Regulations 2018, Part 3, Division 3, Section 21)
                                            <ol>
                                                <li className="mb-2">If a person carrying out gas fitting work on a gas installation becomes
                                                    aware of a danger arising from a defect in the gas installation, the
                                                    person must without delay-
                                                    <br />a) take all steps that are necessary to make the installation safe; and
                                                    <br />b) notify the owner of the gas installation and the occupier of the
                                                    premises in which the installation is situated of the defect</li>
                                                <li className="mb-2">Sub regulation (1)(a) does not apply if the person is unable, or it is
                                                    unreasonable for the person, to take the necessary steps to make the
                                                    gas installation safe</li>
                                                <li className="mb-2">If the person carrying out the gas fitting work is unable, or it is
                                                    unreasonable for the person, to make the gas installation safe, he or
                                                    she must, without delay, notify Energy Safe Victoria and —
                                                    <br />a) if the gas installation uses natural gas, the gas distribution company
                                                    which supplies that gas to the gas installation of the defect; or
                                                    <br />b) if the gas installation uses LPG, the gas retailer which supplies that
                                                    gas to the gas installation of the defect.</li>
                                            </ol>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }
                    </>}


                    <Table sx={{ marginTop: '15px', width: '100%' }}>
                        <TableHead className="bg-smoke text-body">
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        component="div"
                                        className="sec-title fw-bold"
                                    >
                                        Declaration
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {(jobData.service_type === 'ES' || jobData.service_type === 'ESA' || jobData.service_type === 'SA') ? <>
                                        <p>I, being the person responsible for the audit of the {checklist_title} installation (as indicated by my approval below), particulars of which are described above, having exercised reasonable skill and care when carrying out the audit, hereby declare that the information in this report, including the observations, provides an accurate assessment of the condition of the {checklist_title} installation taking into account the stated extent of the audit and the limitations of the visual inspection and testing.</p>
                                        <div className='mb-3'>I further declare that in my judgment, the condition of the said installation was (below selection) at the time the audit was carried out. This does not preclude the possibility that other defects may exist.
                                        </div>
                                    </> : <>
                                        <p>I, being the person responsible for the inspection of the identified gas
                                            appliances or installations in the rental property or rooming house, particulars
                                            of which are described here, having exercised reasonable skill and care when
                                            carrying out the inspection, hereby declare on the date of inspection that the
                                            information in this report, including the observations and recommendations,
                                            provides an accurate assessment of the condition of the gas appliances or
                                            installations in the rental property or rooming house taking into account the
                                            stated extent of the installation and the limitations of the inspection and
                                            testing.</p>
                                        <div className='mb-3'>I further declare that in my judgment, the said appliance(s) and corresponding
                                            installation(s) is/are: (Select one of the below as applicable)
                                        </div>
                                    </>
                                    }
                                    <div className='mb-3'>
                                        <RadioGroup
                                            row
                                            name="dec-radio-buttons"
                                            value={formField.declaration ?? ''}
                                            onChange={(e) => setFormField((prev) => ({ ...prev, declaration: e.target.value }))}
                                        >
                                            {jobData.service_type === 'GS' ? <>
                                                <FormControlLabel value="Compliant" control={<Radio />} label={<><b>Compliant</b> (Gas appliance or gas installation complies with the criteria for a &quot;gas safety
                                                    check&quot; in the residential tenancies regulations)</>} className='radioBox' />
                                                <FormControlLabel value="Non-Compliant" control={<Radio />} label={<><b>Non-Compliant</b> (No immediate risk, however the customer should be advised that remedial
                                                    work is required to be carried out to bring the gas appliance or its installation
                                                    up to standard.)</>} className='radioBox' />
                                                <FormControlLabel value="UnSafe" control={<Radio />} label={<><b>UnSafe</b> (Gas appliance or its installation is unsafe and requires disconnection and
                                                    urgent work as the safety of persons may be at risk or there may be damage
                                                    to property)</>} className='radioBox' />
                                            </> : <>
                                                <FormControlLabel value="Safe" control={<Radio />} label="Safe" className='radioBox' />
                                                <FormControlLabel value="UnSafe" control={<Radio />} label="UnSafe" className='radioBox' />
                                            </>
                                            }
                                        </RadioGroup>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>Name of Technician: </div>
                                        <div className='col-md-4'><strong>{jobData.technician && (jobData.technician.prefix ? jobData.technician.prefix + '. ' : '') + jobData.technician.fullname}</strong></div>


                                        <div className='col-md-3'>Licence / Registration Number:</div>
                                        <div className='col-md-2'><strong>{jobData.technician && jobData.technician.license_number}</strong></div>
                                        <div className='col-md-3 mt-4'>Declaration Date:</div>
                                        <div className='col-md-4 mt-4'><strong>{jobData.next_job_due_date ? jobData.job_date : dayjs(curdate).format('DD/MM/YYYY')}</strong></div>
                                        {!jobData.quotation_id && jobData.service_type !== 'OTH' && <>
                                            <div className='col-md-3 mt-4'>Next inspection Due Date:</div>
                                            <div className='col-md-2 mt-4'><strong>{formField.next_job_due_date}</strong></div>
                                        </>
                                        }

                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {(jobData.service_type === 'ES' || jobData.service_type === 'ESA' || jobData.service_type === 'SA') ? <div>
                                        I the above named licenced electrician have carried out an {checklist_name} safety check of this residential tenancies per the
                                        requirements of the Residential Tenancies Regulations 2021 and set out in the Australian/New Zealand Standard
                                        AS/NZS 3019, "{checklist_name} installations—Periodic verification, and have recorded my observations and
                                        recommendations.</div>
                                        : <div>I declare that I have provided all the information in this compliance check
                                            including the estimated quote of parts cost:</div>}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <div className='row'>
                                        <div className='col-12 col-md-10'>Signature of the technician:
                                            <div className='mt-4'>
                                                <Button type="button" className="themeholy-btn btn-primary mt-2" onClick={(e) => { signatureCanvasRef.current.clear(); setSign('') }} >Clear the sign</Button>
                                                <p>Please sign in the box below,</p>
                                                <div style={{ width: 300, border: '1px solid #ccc' }}>
                                                    <SignatureCanvas penColor='blue' canvasProps={{ width: '300', height: '200', className: 'sigCanvas' }} data={sign} ref={signatureCanvasRef} />
                                                </div>
                                                {showSignErr && <label className="w-full error text-red text-sm text-start">Signature is required</label>}
                                            </div>
                                            <strong>{jobData.technician && (jobData.technician.prefix ? jobData.technician.prefix + '. ' : '') + jobData.technician.fullname}</strong>
                                        </div>
                                        <div className='col-12 col-md-2 text-md-end'>Date: <strong>{jobData.job_date}</strong></div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box >
            </DialogContent >
            <DialogActions className='mb-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xxl-4 text-end pb-2'>
                            <Button type="button" className="themeholy-btn btn-primary w-100" onClick={(e) => handSubmit(e)} disabled={loaderText}>{(loaderText) ? <i className='fa fa-gear fa-spin '></i> : 'Save & Exit'}</Button>
                        </div>
                        <div className='col-12 col-md-5 col-lg-5 col-sm-12 col-xl-5 col-xxl-5 text-end pb-2'>
                            <Button type="submit" className="themeholy-btn btn-primary w-100" onClick={(e) => handSubmit(e)} disabled={loaderSubmitText}>{(loaderSubmitText) ? <i className='fa fa-gear fa-spin '></i> : 'Submit for Approval'}</Button>
                        </div>
                        <div className='col-12 col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xxl-3 text-end pb-2'>
                            <Button type="button" className="themeholy-btn btn-secondary w-100" onClick={handleChecklistClose}>Close</Button>
                        </div>
                    </div>
                </div>
            </DialogActions>
        </Dialog >
    );
}

export default Checklist;