import { useState, useEffect, Fragment, useRef } from 'react';
import {
    Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme, Grid
} from '@mui/material';

import ninja32icon from '../../assets/img/ninja32.png';
import { apiBaseUrl, getSessionItem, setSessionItem } from '../../common';
//import { AppContext } from "../../pages/Main";
import { CheckCircleOutline, WarningAmber } from '@mui/icons-material';

const PaymentSuccess = ({ handleClose, setPaymentHeading }) => {
    useEffect(() => {
        setPaymentHeading(<><div className='text-center'>
            <CheckCircleOutline color='success' sx={{ fontSize: '5em', margin: 'auto' }} />
        </div>
            ⚡ Ninja Strike Successful! ⚡</>);
    }, [setPaymentHeading]);
    return (<Fragment>
        <DialogContentText>
            Your payment was a direct hit! 🎯💸 With the precision of a seasoned Safety Ninja, you've kept your training on point. Great job!

            Keep those ninja skills strong! 💪<img src={ninja32icon} width='18px' alt='ninja32icon' />
        </DialogContentText>
        <div className='mt-3'>
            <div className='row'>
                <div className='col-12 mb-3 text-center'>
                    <Button autoFocus onClick={handleClose} className="themeholy-btn btn-primary">
                        Close
                    </Button>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

const PaymentFailure = ({ handleClose, handleNavi, setPaymentHeading }) => {
    useEffect(() => {
        setPaymentHeading(<><div className='text-center'>
            <WarningAmber color='error' sx={{ fontSize: '5em', margin: 'auto' }} />
        </div>
            💔 Ninja Missed the Mark! 💔</>);
    }, [setPaymentHeading]);
    return (<Fragment>
        <DialogContentText>
            Uh-oh, your payment didn’t go through. <img src={ninja32icon} width='18px' alt='ninja32icon' />💳 But don't fret! Ninjas are all about perseverance. Let’s double back and give it another go!

            Ready to make it happen? Try again! 🥋🚀
        </DialogContentText>
        <div className='mt-3'>
            <div className='row'>
                <div className='col-12 col-md-6 mb-3'>
                    <Button autoFocus onClick={handleClose} className="themeholy-btn btn-secondary w-100">
                        Close
                    </Button>
                </div>
                <div className='col-12 col-md-6'>
                    <Button onClick={() => handleNavi('creditcard')} autoFocus className="themeholy-btn btn-primary w-100">
                        Try Again!
                    </Button>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

const PaymentDetails = ({ paymentData, setDoNot, handleClose, handleNavi }) => {
    return (
        <Fragment>
            <DialogContentText>
                Your Safety Ninja payment is due, and it’s waiting for your next move! <img src={ninja32icon} width='18px' alt='ninja32icon' /> Don’t let it slip away—complete your payment to keep your ninja skills sharp and unstoppable!

                Ready to pay? Let’s do this! 💰⚔️
            </DialogContentText>
            <div className='mt-3'>
                {paymentData.paytype === 'owner' &&
                    <div className='row'>
                        <label className='col-12 col-md-8 sub-title mb-2'>Overall Due Amount (incl. GST)</label>
                        <label className='col-12 col-md-4 sub-title mb-2 text-md-end'>${paymentData.owner.overall ? paymentData.owner.overall.toFixed(2) : '0.00'}</label>
                    </div>
                }
                {paymentData.paytype === 'property' &&
                    <div className='row'>
                        <label className='col-12 col-md-8 sub-title mb-2'>Due Amount (incl. GST) of property {paymentData.property_details}</label>
                        <label className='col-12 col-md-4 sub-title mb-2 text-md-end'>${paymentData.overall ? paymentData.overall.toFixed(2) : '0.00'}</label>
                    </div>
                }
                {paymentData.paytype === 'invoice' &&
                    <div className='row mb-3'>
                        <label className='col-12 col-md-8 h6 text-smoke mb-2'>Invoice Number</label>
                        <label className='col-12 col-md-4 h6 text-smoke mb-2 text-md-end'>{paymentData.invoice_number}</label>
                        <label className='col-12 col-md-8 sub-title mb-2'>Invoice Amount (incl. GST)</label>
                        <label className='col-12 col-md-4 sub-title mb-2 text-md-end'>${paymentData.overall ? paymentData.overall.toFixed(2) : '0.00'}</label>
                    </div>
                }
                {paymentData.openPayment === 'auto' && <>
                    <Checkbox className='m-0 p-0' name="donot" onChange={(e) => setDoNot(e.target.checked ? 'Y' : 'N')} /> Don't show this message again
                </>}
            </div>
            <div className='mt-3'>
                <div className='row'>
                    <div className='col-12 col-md-6 mb-3'>
                        <Button autoFocus onClick={handleClose} className="themeholy-btn btn-secondary w-100">
                            Close
                        </Button>
                    </div>
                    <div className='col-12 col-md-6'>
                        <Button onClick={() => handleNavi('creditcard')} autoFocus className="themeholy-btn btn-primary w-100">
                            Pay ${(paymentData.paytype === 'property' || paymentData.paytype === 'invoice') ? (paymentData.overall ? paymentData.overall.toFixed(2) : '0.00') : (paymentData.owner.overall ? paymentData.owner.overall.toFixed(2) : '0.00')}
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

/* const CreditCardForm = ({ paymentData, handleClose, setPaySteps, setPaymentHeading, fetchData, setUpdateTabList }) => {
    const { handleLogout } = useContext(AppContext);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate(null);

    const ccNameFld = useRef();

    const {
        meta,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getCardImageProps
    } = usePaymentInputs();

    const { erroredInputs, touchedInputs } = meta;
    const { register, handleSubmit, formState: { errors }, trigger,
        setValue } = useForm();

    const validation = (name, e) => {
        setValue(name, e.target.value, true);
        trigger(name);
        if (name === 'payment.cvv' && e.target.value.length >= 3) {
            ccNameFld?.current?.focus();
        }
    };

    const handleBlur = () => {
        // setValue(`${type}.ccType`, meta.cardType?.displayName);
        // console.log("This is blur");
    };

    useEffect(() => {
        setPaymentHeading(<><img src={ninja32icon} alt='ninja32icon' /> Ready to pay 💰⚔️</>);
        register(
            "payment.cardnumber",
            { required: "Enter a card number" }
        );

        register("payment.expiry", { required: "Enter an expiry date" });
        register("payment.cvv", { required: "Enter a CVV" });
        register(
            "payment.accountHolderName",
            { required: "Name required" }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [register, setPaymentHeading]);


    //console.log(meta);
    const onFormSubmit = async (data) => {
        setLoader(true);
        //console.log(data);
        /* if (data.payment.cardnumber === '4444 3333 2222 1111') {
            setPaySteps((prev) => ({ ...prev, creditcard: false, failure: true }));
        } else { */
/* const min = 100000;
const max = 1000000000;
const minCeiled = Math.ceil(min);
const maxFloored = Math.floor(max); */

//data.transaction_id = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);

/*******var cardnumber = data.payment.cardnumber;
cardnumber = cardnumber.replace(/\s/g, '');
data.payment.cardnumber = cardnumber;

var cvv = data.payment.cvv;
data.payment.cvv = cvv;

//data.token = _token;

data.paid_amount = (paymentData.paytype === 'owner') ? paymentData.owner.overall.toFixed(2) : paymentData.overall.toFixed(2);
data.payment_mode = 'creditcard';
// data.status = 'success';
//data.response = 'json';
data.paytype = paymentData.paytype;

if (paymentData.paytype === 'owner') {
    data.owner_id = paymentData.owner.owner_id;
}

if (paymentData.paytype === 'property') {
    data.property_id = paymentData.property_id;
}

if (paymentData.paytype === 'invoice') {
    data.invoice_id = paymentData.invoice_id;
}

try{
const response = await axiosFunction('ewaypayment', 'post', data);
   
    if (response.status === 'success') {
        setPaySteps((prev) => ({ ...prev, creditcard: false, success: true }));
        fetchData();
        setUpdateTabList(true);
        setLoader(false);
    } else {
        setPaySteps((prev) => ({ ...prev, creditcard: false, failure: true }));
        setLoader(false);
        //if (response?.response?.status === 401) {
         //   clearSession();
         //   navigate('/login');
       // }
    }
        } catch (e) {
         setPaySteps((prev) => ({ ...prev, creditcard: false, failure: true }));
        setLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }

//}

};

return (
<Box component="form" noValidate id="paymentForm" autoComplete="off" onSubmit={handleSubmit(onFormSubmit)} data-eway-encrypt-key="yh10p89btBOmnVn8/Lr1A7ZgBkMs+ngMRTVyv21UUQtYBTdMS5RXv0vZOZyhgO0QkYx5k181OT+c2pbLbdWGpZefbV5rcKqR9MfQyF3RhtiJmlTjBMkC5PGQZ8EhlOgmCO1GkDle1mA0EZZspNPwHsoVHlM2b4PaIqNXcuOsNmZOrjEeohjagMSNwdrt82fG/7ZrJg8yBCnYV3dFJKFMwfYYtQZE+yGeYA7AH3b76lkXckp/YDgEpkDoOy+tYo5S7CYUIljSxarwqXK5Sl8oKl91H2VNgIcXyUesn1o6rB7GNmXMu+aXfYcmU2cL9TuaAz/YgFUI8MjY6HJguCxcLQ==">
    <div className='text-theme'>Please fill all fields below of your credit card</div>
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <TextField
                {...getCardNumberProps({
                    refKey: "inputProps",
                    onChange: validation.bind(null, "payment.cardnumber")
                })}
                inputProps={{ ...getCardNumberProps({}), 'data-eway-encrypt-name': "EWAY_CARDNUMBER" }}
                fullWidth
                type="tel"
                label="Credit card number"
                name="payment.cardnumber"
                variant="standard"
                error={
                    (erroredInputs.cardNumber && touchedInputs.cardNumber) ||
                    !!errors?.payment?.cardnumber?.message
                }
                helperText={
                    (erroredInputs.cardNumber &&
                        touchedInputs.cardNumber &&
                        erroredInputs.cardNumber) ||
                    errors?.payment?.cardnumber?.message
                }
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <svg {...getCardImageProps({ images })} />
                        </InputAdornment>
                    )
                }}
            />
        </Grid>

        <Grid item xs={6}>
            <TextField
                {...getExpiryDateProps({
                    refKey: "inputProps",
                    onChange: validation.bind(null, "payment.expiry")
                })}
                inputProps={getExpiryDateProps({})}
                fullWidth
                type="tel"
                label="Expiry date"
                name="payment.expiry"
                variant="standard"
                error={
                    (erroredInputs.expiryDate && touchedInputs.expiryDate) ||
                    !!errors?.payment?.expiry?.message
                }
                helperText={
                    (erroredInputs.expiryDate &&
                        touchedInputs.expiryDate &&
                        erroredInputs.expiryDate) ||
                    errors?.payment?.expiry?.message
                }
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                {...getCVCProps({
                    refKey: "inputProps",
                    onChange: validation.bind(null, "payment.cvv"),
                    onBlur: handleBlur
                })}
                inputProps={{ ...getCVCProps({}), 'data-eway-encrypt-name': "EWAY_CARDCVN" }}
                fullWidth
                type="tel"
                label="CVV"
                name="payment.cvv"
                variant="standard"
                error={
                    (erroredInputs.cvc && touchedInputs.cvc) ||
                    !!errors?.payment?.cvv?.message
                }
                helperText={
                    (erroredInputs.cvc && touchedInputs.cvc && erroredInputs.cvc) ||
                    errors?.payment?.cvv?.message
                }
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                variant="standard"
                type="text"
                inputRef={ccNameFld}
                label="Name on Card"
                placeholder="J Smith"
                name="payment.accountHolderName"
                error={!!errors?.payment?.accountHolderName?.message}
                helperText={errors?.payment?.accountHolderName?.message}
                onChange={validation.bind(null, "payment.accountHolderName")}
            />
        </Grid>

    </Grid>
    <div className='mt-3'>
        <div className='row'>
            <div className='col-12 col-md-6 mb-3'>
                <Button type="button" autoFocus onClick={handleClose} className="themeholy-btn btn-secondary w-100">
                    Close
                </Button>
            </div>
            <div className='col-12 col-md-6'>
                <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loader}>
                    {(loader) ? <i className="fa fa-spin fa-spinner"></i> : <>Pay ${(paymentData.paytype === 'property' || paymentData.paytype === 'invoice') ? paymentData.overall.toFixed(2) : paymentData.owner.overall.toFixed(2)}</>}
                </Button>
            </div>
        </div>
    </div>
</Box>


)
} */

export const PaymentAlert = ({ paymentData, setPaymentData, fetchData, setUpdateTabList, openPaymentDialog, setOpenPaymentDialog }) => {
    const iFrameRef = useRef();
    const profile_id = getSessionItem("_profile_id");
    const [pageLoader, setPageLoader] = useState(false);
    const [doNot, setDoNot] = useState('N');
    const [paymentHeading, setPaymentHeading] = useState(`🚨 Ninja in the House! 🚨`);
    const [paySteps, setPaySteps] = useState({
        payment: false,
        creditcard: false,
        success: false,
        failure: false
    });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [creditCardFormURL, setCreditCardFormURL] = useState('');
    //const { handleLogout } = useContext(AppContext);   

    useEffect(() => {
        setPaySteps({
            payment: true,
            creditcard: false,
            success: false,
            failure: false
        });

        if (openPaymentDialog) {
            var idParams = '';
            if (paymentData.paytype === 'owner') {
                idParams = '&owner_id=' + paymentData.owner.owner_id;
            }

            if (paymentData.paytype === 'property') {
                idParams = '&property_id=' + paymentData.property_id;
            }

            if (paymentData.paytype === 'invoice') {
                idParams = '&invoice_id=' + paymentData.invoice_id;
            }

            //console.log(paymentData);
            const amountParam = '&pay_amount=' + (paymentData.paytype === 'owner' ? (paymentData.owner.overall ? paymentData.owner.overall.toFixed(2) : '0.00') : (paymentData.overall ? paymentData.overall.toFixed(2) : '0.00'));

            setCreditCardFormURL(apiBaseUrl + 'ewaypayment/?paytype=' + paymentData.paytype + idParams + amountParam + '&payment_mode=creditcard&profile_id=' + profile_id);

            //console.log(apiBaseUrl + 'ewaypayment/?paytype=' + paymentData.paytype + idParams + amountParam + '&payment_mode=creditcard');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPaymentDialog, setPaySteps]);

    /* const handleClickOpen = () => {
        setOpen(true);
    }; */

    const handleClose = () => {
        setOpenPaymentDialog(false);
        setPaymentHeading('');
        setPageLoader(false);
        // setOpen(false);
        setPaySteps({
            payment: false,
            creditcard: false,
            success: false,
            failure: false
        });
        setPaymentData({ openPayment: 'close' });
        if (doNot === 'Y') setSessionItem("_do_not_show", doNot);
    };

    const handleNavi = (step) => {
        if (step === 'payment') {
            setPaySteps((prev) => ({ ...prev, payment: true, creditcard: false, failure: false }));
            setPaymentHeading(`🚨 Ninja in the House! 🚨`);
        }
        if (step === 'creditcard') {
            setPageLoader(true);
            setPaySteps((prev) => ({ ...prev, payment: false, creditcard: true, failure: false }));
            //setPaymentHeading(<><img src={ninja32icon} alt='ninja32icon' /> Ready to pay 💰⚔️</>);
            setPaymentHeading('');
            setTimeout(() => {
                setPageLoader(false);
            }, 2000);
        }
    };

    return (
        <Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={openPaymentDialog}
                scroll='body'
            >
                {paymentData.paytype && <>
                    <DialogTitle id="responsive-payment-dialog-title">
                        {paymentHeading}
                    </DialogTitle>
                    <DialogContent>
                        {pageLoader && <div style={{ position: 'absolute', textAlign: 'center', padding: '150px 0px', backgroundColor: '#CCCCCC', width: '86%', opacity: '0.5' }}>Payment page is loading.<br />Please wait...<br /><h6><i className="fa fa-spin fa-spinner"></i></h6></div>}
                        {paySteps.payment === true && <PaymentDetails paymentData={paymentData} setDoNot={setDoNot} handleClose={handleClose} handleNavi={handleNavi} />}

                        {/* paySteps.creditcard === true && <CreditCardForm paymentData={paymentData} handleClose={handleClose} setPaySteps={setPaySteps} setPaymentHeading={setPaymentHeading} fetchData={fetchData} setUpdateTabList={setUpdateTabList} /> */}

                        {paySteps.creditcard === true && <><iframe ref={iFrameRef} title="EWAY PAYMENT" src={creditCardFormURL} height='410px' scrolling="no" style={{ zIndex: 1501 }}></iframe>
                            <Grid container spacing={2} className='text-center'>
                                {/* <Grid item xs={6}>

                                    <Button type="button" autoFocus onClick={() => handleNavi('payment')}>
                                        Back
                                    </Button>
                                </Grid> */}
                                <Grid item xs={12}>

                                    <Button type="button" onClick={handleClose} >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </>}

                        {paySteps.success === true && <PaymentSuccess handleClose={handleClose} setPaymentHeading={setPaymentHeading} />}

                        {paySteps.failure === true && <PaymentFailure handleClose={handleClose} handleNavi={handleNavi} setPaymentHeading={setPaymentHeading} />}
                    </DialogContent>
                </>}
            </Dialog>
        </Fragment>
    );
}