import { useState } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { useNavigate, Link } from 'react-router-dom';
import { axiosFunction, setSessionItem } from '../common';
import { useForm } from "react-hook-form";
import { Alert, IconButton, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate(null);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [passwdType, setPasswdType] = useState('password');
    const [passwdViewIcon, setPasswdViewIcon] = useState('');

    const onSubmit = async (data) => {
        try {
            setLoader(true);
            if (data.email && data.password) {
                const user = {
                    email: data.email,
                    password: data.password
                };
                const response = await axiosFunction('auth/login', 'post', user);
                if (response.status === 'success') {
                    setSessionItem('_profile_id', response.data.profile_id);
                    setSessionItem('_role', response.data.role);
                    setSessionItem('_token', response.data.access_token);
                    setSessionItem('_token_expires', response.data.expires_in);
                    setSessionItem('_is_landlord', response.data.is_landlord);
                    setSessionItem('_user_name', response.data.user_name);
                    setSessionItem('_xero_connect', response.xeroConnectFlag);
                    setLoader(false);
                    if (response.data.reset_password_token) {
                        setSessionItem('_redirect', "reset");
                        setSessionItem('_reset_password_token', response.data.reset_password_token)
                        navigate('/reset-password');
                    }
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(response.message);
                    setLoader(false);
                }
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
        }
    };

    /* const handleAutoClose = () => {
        setTimeout(() => setAlertOpen(false), 5000);
    }; */

    const handlePasswdView = (e) => {
        e.preventDefault();
        if (passwdType === 'password') {
            setPasswdType('text');
            setPasswdViewIcon('-slash');
        } else {
            setPasswdType('password');
            setPasswdViewIcon('');
        }
    }

    return (
        <>
            <SiteHeader menu='login' />
            <div className="space-bottom">
                <div className="container">
                    <div className="bg-smoke bg-bottom-right">
                        <div className="row">
                            <div className="col-xl-6 m-auto">
                                <div className="quote-form-box bg-transparent">
                                    <h4 className="form-title text-center">Login</h4>
                                    <Collapse in={alertOpen}>
                                        <Alert variant="filled" severity={alertStatus}
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setAlertOpen(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {alertMessage}
                                        </Alert>
                                    </Collapse>
                                    <form method="POST" className="contact-form ajax-contact" id="frmLogin" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <input type="text" autoFocus className={"form-control " + (errors.email ? 'is-invalid' : '')} placeholder="Email Address or Mobile Number" autoComplete="off" {...register("email", { required: "Email Address or Mobile number is required" })} />
                                                <label className="w-full error text-red text-sm text-start">{errors.email?.message}</label>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <input type={passwdType} maxLength={20} id="password-field" className={"form-control " + (errors.password ? 'is-invalid' : '')} placeholder="Password" autoComplete="off" {...register("password", { required: "Password is required" })} />
                                                <span toggle="#password-field" className={"fa fa-fw fa-eye" + passwdViewIcon + " passwd-field-icon toggle-password"} onClick={(e) => handlePasswdView(e)}></span>
                                                <label className="w-full error text-red text-sm text-start">{errors.password?.message}</label>
                                                <div className="text-end">
                                                    <Link to='/forgot-password'>Forgot Password?</Link>
                                                </div>
                                            </div>
                                            <div className="form-btn col-12"><button className="themeholy-btn btn-fw" disabled={(loader) ? 'disabled' : ''}>{(loader) ? 'Processing...' : 'Login'}</button></div>
                                        </div>
                                        <p className="form-messages mb-0 mt-3"></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default Login;