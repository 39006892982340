import { useState, useEffect, forwardRef, createContext, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { axiosFunction, clearSession } from '../../common';
import { PostCodeForm } from "./PostCodeForm";
import { AccountContext } from "../../pages/MyAccount";

export const FormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PostCode = () => {
    const navigate = useNavigate(null);
    const dialogRef = useRef(null);
    const { setBackDropOpen } = useContext(AccountContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});
    const [postCodeId, setPostCodeId] = useState('');
    const [formField, setFormField] = useState({
        'code': '',
        'suburb': '',
        'state_shortname': '',
        'status': '',
        'action': ''
    });
    const [alertMessage, setAlertMessage] = useState('');

    const fetchData = async () => {
        try {
            setBackDropOpen(true);
            const postcodeData = await axiosFunction('postcodes', 'get', {});

            if (postcodeData.status === 'success') {
                setDTColumns(postcodeData.data);
                setLoader(false);
                setBackDropOpen(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(postcodeData.message);
                setBackDropOpen(false);
                /* if (postcodeData?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 100, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'code', headerName: 'Code', width: 150 },
        { field: 'suburb', headerName: 'Suburb', width: 400 },
        { field: 'state_shortname', headerName: 'State', width: 150 },
        { field: 'status', headerName: 'Status', width: 150, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="primary" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (value, row) => (row.status === 'Y') ? 'Active' : 'Inactive' },
        { field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => { return (<><Tooltip title="Edit Postcode" placement="top" arrow ><Button type="button" onClick={(e) => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip> <Tooltip title="Delete Postcode" placement="top" arrow ><Button type="button" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button></Tooltip ></>) } },
    ];

    const handleEdit = (data) => {
        setLoaderText(false);
        setPostCodeId(data.id);
        setFormField({
            'code': data.code,
            'suburb': data.suburb,
            'state_shortname': data.state_shortname,
            'status': data.status,
            'action': 'edit'
        });

        setDialogOpen(true);
        setTimeout(() => { document.getElementById('suburb').focus() }, 100);
    };

    const handleAdd = () => {
        setLoaderText(false);
        setPostCodeId('');
        setFormField({
            'code': '',
            'suburb': '',
            'state_shortname': '',
            'status': '',
            'action': 'add'
        });
        setDialogOpen(true);
        setTimeout(() => { document.getElementById('suburb').focus() }, 100);
    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = async () => {
        try {
            const delResponse = await axiosFunction('postcodes/' + delData.id, 'post', delData);
            setAlertOpen(true);
            if (delResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(delResponse.message);
                handleAutoClose();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(delResponse.message);
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
                if (delResponse?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                }
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            setConfirmOpen(false);
            setDelData({});
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setPostCodeId('');
        setFormField({
            'code': '',
            'suburb': '',
            'state_shortname': '',
            'status': '',
            'action': '',
        });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const onSubmit = async (data) => {
        try {
            setLoaderText(true);
            //console.log(data);
            let saveResponse = null;
            if (formField.action === 'edit') {
                data._method = 'PUT';
                saveResponse = await axiosFunction('postcodes/' + postCodeId, 'post', data);
            } else if (formField.action === 'add') {
                saveResponse = await axiosFunction('postcodes', 'post', data);
            }

            setAlertOpen(true);
            if (saveResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(saveResponse.message);
                handleAutoClose();
                handleDialogClose();
                setLoaderText(false);
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(saveResponse.message);
                setLoaderText(false);
                document.querySelector('#postcodeform').scrollIntoView({ behavior: 'smooth' });
                /*  if (saveResponse?.response?.status === 401) {
                     clearSession();
                     navigate('/login');
                 } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    };

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>
            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
            >
                <DialogTitle>Are you sure you want to delete this Postcode?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, Postcode will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                ref={dialogRef}
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >
                <FormContext.Provider value={{
                    handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
                    alertMessage,
                    alertOpen,
                    setAlertOpen
                }}>
                    <PostCodeForm />
                </FormContext.Provider>
            </Dialog>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Postcode</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </>
    );
}
export default PostCode;