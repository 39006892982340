import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Card } from '@mui/material';

/* const data = [
  { label: 'Total', value: 164.3, color: 'red' },
  { label: 'Pending', value: 164.3, color: 'orange' },
  { label: 'Paid', value: 0, color: 'green' }
]; */

const getArcLabel = (params) => {
  const percent = params.value;
  return `$ ${percent.toFixed(2)}`;
};

export default function PieBoxChart({ data }) {
  return (
    <Card className='w-100'>
      <PieChart
        series={[
          {
            data,
            arcLabel: getArcLabel
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white'
          },
        }}

        height={300}
      />
    </Card>
  );
}
