import { useRef } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, useMediaQuery, useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import Invoice from "../Jobs/Invoice";
import { Base64 } from 'js-base64';
//const _token = getSessionItem("_token");
//const _role = getSessionItem("_role");

const InvoiceDialog = ({ invoiceDialogOpen, handleInvoiceDialogClose, invoiceData }) => {

    const invoiceRef = useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const handleGeneratePdf = () => {

        doc.setProperties({
            title: "invoice_" + Base64.encode(invoiceData.id, true) + ".pdf"
        }).html(invoiceRef.current, {
            callback: function (doc) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize('14px');
                //doc.output('dataurlnewwindow', { filename: "invoice_" + Base64.encode(invoiceData.id, true) + ".pdf" });
                doc.save("invoice_" + Base64.encode(invoiceData.id, true) + ".pdf");
            },
            margin: [5, 4, 5, 4],
            autoPaging: 'text',
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.486,
                ignoreElements(el) {
                    return typeof el.shadowRoot === 'object' && el.shadowRoot !== null;
                },
            }
        });

    };

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="md"
            open={invoiceDialogOpen}
            onClose={handleInvoiceDialogClose}
            scroll='body'
        >
            <DialogTitle className='p-0'>
                <IconButton
                    aria-label="close"
                    onClick={handleInvoiceDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'black',
                        zIndex: '1000',
                        backgroundColor: 'white',
                        opacity: 0.9
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent ref={invoiceRef} className='p-0'>
                <Invoice invoiceData={invoiceData} />
            </DialogContent>
            <DialogActions className='p-4'>
                {invoiceData.paid_status !== 'C' && <Button onClick={handleGeneratePdf}>
                    Generate PDF
                </Button>}
                <Button onClick={handleInvoiceDialogClose}>Close</Button>
            </DialogActions>

        </Dialog >
    );
}

export default InvoiceDialog;