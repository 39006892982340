import { useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Alert, Collapse, Box, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { PatternFormat } from 'react-number-format';
import { axiosFunction, getSessionItem, clearSession } from '../../common';
import { UserFormContext } from "../Properties/PropertyForm";

export const DialogUserForm = () => {
    const navigate = useNavigate(null);
    const _role = getSessionItem('_role');
    const _profile_id = getSessionItem('_profile_id');
    const { userFormType, addDialogOpen, toggleAddDialog, dialogValue, setDialogValue, setUserOptions, setOwnerInput, setFormField, formField } = useContext(UserFormContext);

    const { register, control, handleSubmit, formState: { errors }, reset } = useForm();

    let uTypeArr = { 'AGENT': 'Agent', 'LDLORD': 'Landlord', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const UserFormType = uTypeArr[userFormType];

    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loaderText, setLoaderText] = useState(false);

    const [openForm, setOpenForm] = useState(false);
    const [userFormField, setUserFormField] = useState(
        {
            firstname: '',
            lastname: '',
            mobile: '',
            email: '',
        }
    )

    const fetchUserData = async () => {
        try {
            const userDataList = await axiosFunction('auth/users', 'post', {});
            if (userDataList.status === 'success') {
                setUserOptions(userDataList.data);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(userDataList.message);
                /* if (userDataList?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    const loadData = () => {

        if (isNaN(dialogValue)) {

            const name = dialogValue.split(' ');

            setUserFormField((prev) => ({ ...prev, lastname: name[0] }));
            if (name.length > 1) {
                setUserFormField((prev) => ({ ...prev, firstname: dialogValue.slice(dialogValue.indexOf(" ") + 1) }));
            }

        } else {
            setUserFormField((prev) => ({ ...prev, mobile: dialogValue }));
        }

        setOpenForm(true);
    }

    useEffect(() => {
        if (dialogValue) loadData();
        if (userFormField.firstname || userFormField.mobile) reset(userFormField);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogValue]);


    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setUserFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }

    const handleDialogClose = () => {
        toggleAddDialog(false);
        setUserFormField({
            firstname: '',
            lastname: '',
            mobile: ''
        })
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const onSubmit = async (data) => {
        data.form = 'mini';
        let saveResponse = null;
        data.code = userFormType;
        data.agents = [];
        var agent_id = formField.agent_id ? formField.agent_id : '';
        if (_role === 'AGENT') {
            agent_id = _profile_id;
        }
        data.agents.push(agent_id);
        data.is_landlord = userFormType === 'LDLORD' ? 'Y' : 'N';
        try {
            saveResponse = await axiosFunction('individuals', 'post', data);
            setAlertOpen(true);
            if (saveResponse.status === 'success') {
                fetchUserData();
                setDialogValue(saveResponse.item.firstname + ' ' + saveResponse.item.lastname + ' (' + saveResponse.item.mobile + ')');
                setOwnerInput(saveResponse.item.firstname + ' ' + saveResponse.item.lastname + ' (' + saveResponse.item.mobile + ')');
                setFormField((prev) => ({
                    ...prev,
                    owner_id: saveResponse.item.id,
                    owner_name: saveResponse.item.firstname + ' ' + saveResponse.item.lastname,
                    owner_prefix: '',
                    agent_id: agent_id
                }));
                setAlertStatus('success');
                setAlertMessage(saveResponse.message);
                handleDialogClose();
                handleAutoClose();
                setLoaderText(false);
            } else {
                setAlertStatus('error');
                setAlertMessage(saveResponse.message);
                setLoaderText(false);
                /* if (saveResponse?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }

    return (
        <Dialog open={addDialogOpen} onClose={handleDialogClose}>
            {openForm && (
                <Fragment>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '49ch' }, }} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle>Add a new {UserFormType}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Did you miss any {UserFormType} in our list? Please, add it!
                            </DialogContentText>
                            <div className="container-fluid mt-3">
                                <Collapse in={alertOpen}>
                                    <Alert variant="filled" severity={alertStatus}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setAlertOpen(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        {alertMessage}
                                    </Alert>
                                </Collapse>

                                <div className="master-form mt-2">
                                    <div className="row">
                                        <div className="col-md-12">

                                            {userFormType === 'AGENT' && (
                                                <TextField
                                                    id="agency_name" name="agency_name" variant="outlined" error={errors.agency_name ? true : false} label="Agency Name *"
                                                    helperText={errors.agency_name?.message}
                                                    inputProps={{ maxLength: 100 }}
                                                    {...register("agency_name", { required: "Agency Name is required" })}
                                                />
                                            )}

                                            <TextField
                                                id="firstname" name="firstname" variant="outlined" error={errors.firstname ? true : false} label="Firstname *"
                                                helperText={errors.firstname?.message}
                                                inputProps={{ maxLength: 40, value: userFormField.firstname }}
                                                {...register("firstname", { required: "Firstname is required" })}
                                                onChange={(e) => setUserFormField((prev) => ({ ...prev, firstname: e.target.value }))}
                                            />


                                            <TextField
                                                id="lastname" name="lastname" variant="outlined" error={errors.lastname ? true : false} label="Lastname *"
                                                helperText={errors.lastname?.message}
                                                inputProps={{ maxLength: 40, value: userFormField.lastname }}
                                                {...register("lastname", { required: "Lastname is required" })}
                                                onChange={(e) => setUserFormField((prev) => ({ ...prev, lastname: e.target.value }))}
                                            />

                                            <TextField
                                                id="email" name="email" variant="outlined" error={errors.email ? true : false} label="Email Address *"
                                                helperText={errors.email?.message}
                                                inputProps={{ maxLength: 60, value: userFormField.email }}
                                                {...register("email", {
                                                    required: "Email Address is required", pattern: {
                                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: 'Please enter a valid email',
                                                    }
                                                })}
                                                onChange={(e) => setUserFormField((prev) => ({ ...prev, email: e.target.value }))}
                                            />

                                            <Controller
                                                name="mobile"
                                                control={control}
                                                rules={{
                                                    required: "A valid mobile number is required",
                                                    pattern: {
                                                        value: /^04\d{8}$/,
                                                        message: 'Please enter a valid mobile number',
                                                    }
                                                }}

                                                render={({ field, fieldState }) => (
                                                    <PatternFormat
                                                        label="Mobile *"
                                                        customInput={TextField}
                                                        type="tel"
                                                        format="04########"
                                                        allowEmptyFormatting
                                                        fullWidth
                                                        mask="_"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        value={userFormField.mobile}
                                                        onChange={(e) => { field.onChange(handleMobileChange(e)); setUserFormField((prev) => ({ ...prev, mobile: e.target.value })) }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }}>
                            <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                                Cancel
                            </Button>
                            <Button type="submit" autoFocus className="themeholy-btn btn-primary" disabled={loaderText}>
                                {(loaderText) ? 'Saving...' : 'Save'}
                            </Button>
                        </DialogActions>
                    </Box>
                </Fragment>
            )}
        </Dialog>
    );
}