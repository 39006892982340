import { useState, useEffect, useContext, Fragment, createContext, useRef, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
    Alert, Collapse, Box, TextField, IconButton, Button, AppBar, Toolbar, Typography,
    FormControl, InputLabel, Select, MenuItem, Autocomplete, Checkbox, FormControlLabel,
    createFilterOptions, CircularProgress, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./ManageProperties";
//import ImageUploading from "react-images-uploading";
import { NumericFormat, PatternFormat } from 'react-number-format';
import { axiosFunction, checkPermission, getSessionItem, clearSession } from '../../common';
import { DialogUserForm } from "../Modal/DialogUserForm";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const filter = createFilterOptions();

const filterOptions = createFilterOptions({
    stringify: ({ id, label, agency }) => `${id} ${label} ${agency}`
});

export const UserFormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PropertyForm = ({ own }) => {
    const navigate = useNavigate(null);
    const _role = getSessionItem("_role");
    const _profile_id = getSessionItem("_profile_id");
    const _user_name = getSessionItem("_user_name");
    const buttonRef = useRef();

    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus, alertMessage, alertOpen, setAlertOpen, userOptions, setUserOptions, postcodeOptions, checkFormChange, propertyId, lookUpsOptions, openForm, currentVal, setConfirmSubmit, setSubmitConfirmOpen, submitConfirmOpen } = useContext(FormContext);

    const [dialogValue, setDialogValue] = useState('');

    const [userFormType, setUserFormType] = useState('');
    const [dateError, setDateError] = useState(false);

    const [addDialogOpen, toggleAddDialog] = useState(false);

    const [pcodeValue, setPcodeValue] = useState({ code: formField.postcode, suburb: formField.suburb });

    const actionFun = formField.action;

    const [agentData, setAgentData] = useState([]);

    const [ownerInput, setOwnerInput] = useState(formField.owner_name + (formField.owner_mobile ? ' (' + formField.owner_mobile + ')' : ''));
    const [agentInput, setAgentInput] = useState(formField.agent_name + (formField.agent_mobile ? ' (' + formField.agent_mobile + ')' : ''));


    const [isLLAddr, setIsLLAddr] = useState(false);
    const [eAddressDetails, setEAddressDetails] = useState({});
    const [addressDetails, setAddressDetails] = useState({
        address1: formField.owner_address1,
        address2: formField.owner_address2,
        suburb: formField.owner_suburb,
        state: formField.owner_state,
        postcode: formField.owner_postcode
    });

    const [showAlertCheck, setShowAlertCheck] = useState(((formField.showalert === 'Y') ? true : false));

    const { register, control, handleSubmit, formState: { errors }, reset, clearErrors, setValue } = useForm();

    //const [images, setImages] = useState([]);
    //const [remAllImg, setRemAllImg] = useState('hide');
    //const maxNumber = 10;

    //let inputRef;

    const getSelectedItem = (id, code) => {
        //console.log(userOptions);   
        // eslint-disable-next-line
        const item = userOptions.filter(a => (code === 'LDLORD' && a.is_landlord === 'Y') || (code === 'AGENT' && a.code === code)).find((opt) => {
            if (opt && id && opt.id === id) {
                return opt;
            }
        });

        return item || null;
    }

    const fetchAgentData = async (id) => {
        try {
            const params = { owner_id: id, code: 'AGENT', acct_type: 'IND' }
            const agentUserData = await axiosFunction('auth/users', 'post', params);
            if (agentUserData.status === 'success') {
                setAgentData(agentUserData.data);
            } else {
                setAgentData([]);
                /* if (agentUserData?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }

    }


    useEffect(() => {
        //console.log(dialogValue);
        if (dialogValue || formField.action === 'add') {
            updateAgentData((formField.owner_id ? formField.owner_id : ''));
        }
        if (formField.action === 'edit') {
            //if(dialogField.owner_id !== formField.owner_id){  
            fetchAgentData(formField.owner_id);
            //}
            setEAddressDetails({
                address1: formField.address1,
                address2: formField.address2,
                suburb: formField.suburb,
                state: formField.state,
                postcode: formField.postcode
            });
            reset(formField);
        }
        if (isLLAddr) {
            setPcodeValue({ code: addressDetails.postcode, suburb: addressDetails.suburb, state_shortname: addressDetails.state });
            setFormField((prev) => ({
                ...prev, address1: addressDetails.address1,
                address2: addressDetails.address2, suburb: addressDetails.suburb,
                state: addressDetails.state, postcode: addressDetails.postcode
            }));
        } else if (formField.action === 'edit') {
            /* setPcodeValue({ code: eAddressDetails.postcode, suburb: eAddressDetails.suburb, state_shortname: eAddressDetails.state });
            setFormField((prev) => ({
                ...prev, address1: eAddressDetails.address1,
                address2: eAddressDetails.address2, suburb: eAddressDetails.suburb,
                state: eAddressDetails.state, postcode: eAddressDetails.postcode
            })); */
        } else if (formField.action === 'add') {
            setPcodeValue({ code: '', suburb: '', state_shortname: '' });
            setFormField((prev) => ({
                ...prev, address1: '',
                address2: '', suburb: '',
                state: '', postcode: ''
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLLAddr, setValue, dialogValue]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    /* const onImageChange = (imageList, addUpdateIndex) => {
        // data for submit
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);

        //setFormField((prev) => ({ ...prev, picture: imageList }));

        if (imageList.length > 1) {
            setRemAllImg('show');
        } else {
            setRemAllImg('hide');
        }
    }; */

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }

    const updateAgentData = async (id) => {
        try {
            const params = { code: 'AGENT', acct_type: 'IND' };
            if (id) {
                params.owner_id = id;
            }
            const agentUserData = await axiosFunction('auth/users', 'post', params);
            if (agentUserData.status === 'success') {
                setAgentData(agentUserData.data);
            } else {
                setAgentData([]);
                /* if (agentUserData?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }

            setAgentInput('');
            setFormField((prev) => ({ ...prev, agent_id: '', agent_name: '', agent_prefix: '' }));
        } catch (e) {
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            } else {
                console.log(e);
            }
        }

    }

    const getProductSelectedItem = (id) => {
        // eslint-disable-next-line
        const item = lookUpsOptions.PRODUCT.filter(a => id && a.id === parseInt(id)).find((opt) => {
            return opt;
        });

        return item || null;
    }

    const handleConfirmClose = () => {
        setFormField((prev) => ({ ...prev, product_code: currentVal.product_code }));
        setSubmitConfirmOpen(false);
    };

    const handleFormSubmit = () => {
        setConfirmSubmit(true);
        setTimeout(() => {
            buttonRef?.current?.click();
        }, 500);
    }

    /* const getSelectedManagerItem = (manager) => {
        // eslint-disable-next-line
        const item = userOptions.find((opt) => {
            if (opt && opt.id === manager) {
                return opt;
            };
        });
        return item || null;
    } */

    return (
        <Fragment>
            <Box component="form" id="propertyForm" noValidate autoComplete="off" onChange={checkFormChange} onSubmit={handleSubmit(onSubmit)}>
                <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleDialogClose}
                            aria-label="close"
                            className="me-auto"
                        >
                            <CloseIcon />
                            <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                                {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {own === 'Y' ? 'My' : ''} Property
                            </Typography>
                        </IconButton>

                        <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                            Cancel
                        </Button>
                        <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                            {(loaderText) ? 'Saving...' : 'Save'}
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="container" style={{ paddingTop: '90px' }}>
                    <Collapse in={alertOpen}>
                        <Alert variant="filled" severity={alertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMessage}
                        </Alert>
                    </Collapse>

                    {openForm ?

                        <div className="row">
                            <div className="col-md-12 d-block d-lg-none">
                                <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                                    {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {own === 'Y' ? 'My' : ''} Property
                                </Typography>
                            </div>
                            <div className="col-md-6">
                                <>
                                    <h5 className="mb-3" style={{ textAlign: 'left' }}>Property Info:</h5>
                                    {(formField.owner_id && (propertyId !== '' || _role === 'LDLORD' || own === 'Y')) ?
                                        <div className="row" style={{ height: '87px', paddingTop: '25px' }}>
                                            <Typography variant="body" component="label" className="col-4">
                                                Landlord
                                                <input type='hidden' value={formField.owner_id} {...register("owner_id", { required: false })} />
                                            </Typography>
                                            <Typography variant="body" component="div" className="col-8">
                                                {formField.owner_name}
                                            </Typography>
                                        </div>
                                        :
                                        <Controller
                                            name="owner_id"
                                            control={control}
                                            rules={{
                                                required: (!formField.owner_id && !formField.agent_id) ? "Landlord / Agent Manager is required" : false
                                            }}
                                            render={(props) => (
                                                <Autocomplete
                                                    options={userOptions.filter(a => a.is_landlord === "Y")}
                                                    getOptionLabel={(option) => option.label}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        //console.log(params);

                                                        if (params && params.inputValue && (!filtered.length || (filtered.length > 0 && filtered[0].label !== params.inputValue))) {
                                                            //console.log('text');
                                                            filtered.push({
                                                                id: '',
                                                                code: '',
                                                                label: `Add "${params.inputValue}"`,
                                                            });
                                                        }

                                                        return filtered;
                                                    }}

                                                    renderOption={(args, option) => (
                                                        <Box component="li" {...args} key={option.id}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Landlord"
                                                            variant="outlined"
                                                            error={!!props.fieldState.error}
                                                            helperText={props.fieldState.error?.message}
                                                        />
                                                    )}
                                                    onChange={(_, data) => {
                                                        if (data) {
                                                            if (data.id === '' && typeof data.label === 'string') {
                                                                // timeout to avoid instant validation of the dialog's form.
                                                                setTimeout(() => {
                                                                    toggleAddDialog(true);
                                                                    setAddressDetails(data);
                                                                    setFormField((prev) => ({ ...prev, owner_id: '', owner_name: data.label.replace(new RegExp(/^Add |"/, "g"), ''), owner_prefix: '' }));
                                                                    // setOwnerInput('');
                                                                    setUserFormType('LDLORD');
                                                                    setDialogValue(data.label.replace(new RegExp(/^Add |"/, "g"), ''));
                                                                });
                                                            } else if (data.id !== '') {
                                                                props.field.onChange(data.id)
                                                                setFormField((prev) => ({ ...prev, owner_id: data.id, owner_name: data.label, owner_prefix: data.prefix }));
                                                                if (_role !== 'AGENT') updateAgentData(data.id);
                                                                setOwnerInput(data.label);
                                                                setAddressDetails(data);
                                                                checkFormChange();
                                                            }
                                                        } else {
                                                            setFormField((prev) => ({ ...prev, owner_id: '', owner_name: '', owner_prefix: '' }));
                                                            updateAgentData();
                                                        }

                                                        setIsLLAddr(false);

                                                    }}
                                                    inputValue={ownerInput}

                                                    onInputChange={(_, newInputValue, eventType) => {
                                                        if (eventType === 'reset') {
                                                            setOwnerInput(ownerInput);
                                                        } else {
                                                            setOwnerInput(newInputValue);
                                                        }
                                                    }}
                                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                                    value={formField.owner_id ? getSelectedItem(formField.owner_id, "LDLORD") : null}


                                                />
                                            )}
                                        //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                        //value={formField.owner_id ? getSelectedItem(formField.owner_id, "LDLORD") : null}

                                        />
                                    }

                                    {(_role === 'AGENT' && own === 'N') ?
                                        <div className="row" style={{ height: '87px', paddingTop: '25px' }}>
                                            <Typography variant="body" component="label" className="col-4">
                                                Agent/Assign PM
                                                <input type='hidden' value={_profile_id} {...register("agent_id", { required: false })} />
                                            </Typography>
                                            <Typography variant="body" component="div" className="col-8">
                                                {_user_name}
                                            </Typography>
                                        </div>
                                        :
                                        <>
                                            {['SYS', 'MG', 'STAFF'].includes(_role) &&
                                                <Controller
                                                    name="agent_id"
                                                    control={control}
                                                    rules={{
                                                        required: (!formField.owner_id && !formField.agent_id) ? "Landlord / Agent Manager is required" : false
                                                    }}
                                                    render={(props) => (
                                                        <Autocomplete
                                                            options={agentData}
                                                            getOptionLabel={(option) => option.label}
                                                            /* renderOption={(args, option) => (
                                                                <Box component="li" {...args} key={option.id}>
                                                                    {option.label}
                                                                </Box>
                                                            )} */
                                                            filterOptions={filterOptions}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" {...props} key={option.id}>
                                                                    <div className="container">
                                                                        <div>{`${option.label}`}</div>
                                                                        <div className="text-light fs-xs">{option.agency}</div>
                                                                    </div>
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Assign Agent Property Manager"
                                                                    variant="outlined"
                                                                    error={!!props.fieldState.error}
                                                                    helperText={props.fieldState.error?.message}
                                                                />
                                                            )}
                                                            onChange={(_, data) => {
                                                                //console.log(data);
                                                                //props.field.onChange(data.id)
                                                                if (data) {
                                                                    setFormField((prev) => ({ ...prev, agent_id: data.id, agent_name: data.label, agent_prefix: data.prefix ? data.prefix : '' }));
                                                                    setAgentInput(data.label);
                                                                } else {
                                                                    setFormField((prev) => ({ ...prev, agent_id: '', agent_name: '', agent_prefix: '' }));
                                                                    setAgentInput('');
                                                                }
                                                                checkFormChange();
                                                            }}
                                                            inputValue={agentInput}

                                                            onInputChange={(_, newInputValue, eventType) => {
                                                                if (eventType === 'reset') {
                                                                    setAgentInput(agentInput);
                                                                } else {
                                                                    setAgentInput(newInputValue);
                                                                }
                                                            }}

                                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                                            value={formField.agent_id ? getSelectedItem(formField.agent_id, "AGENT") : null}
                                                        />
                                                    )
                                                    }
                                                //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                                //value={formField.agent_id ? getSelectedItem(formField.agent_id, "AGENT") : null}
                                                />}
                                        </>
                                    }

                                    <TextField
                                        fullWidth
                                        id="tenancy_name" name="tenancy_name" variant="outlined" error={errors.tenancy_name ? true : false} label="Tenancy Name"
                                        helperText={errors.tenancy_name?.message}

                                        inputProps={{ maxLength: 100, value: formField.tenancy_name }}

                                        {...register("tenancy_name", { required: false })}

                                        onChange={(e) => { setFormField((prev) => ({ ...prev, tenancy_name: e.target.value })); checkFormChange(); }}

                                    />
                                    <TextField
                                        fullWidth
                                        id="tenancy_email" name="tenancy_email" variant="outlined" error={errors.tenancy_email ? true : false} label="Tenancy Email Address"
                                        helperText={errors.tenancy_email?.message}

                                        inputProps={{ maxLength: 60, value: formField.tenancy_email ? formField.tenancy_email : '' }}

                                        {...register("tenancy_email", {
                                            required: false, pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Please enter a valid email',
                                            }
                                        })}

                                        onChange={(e) => { setFormField((prev) => ({ ...prev, tenancy_email: e.target.value })); checkFormChange(); }}
                                    />
                                    <Controller
                                        name="tenancy_mobile"
                                        control={control}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^04\d{8}$/,
                                                message: 'Please enter a valid mobile number',
                                            }
                                        }}

                                        render={({ field, fieldState }) => (
                                            <PatternFormat
                                                label="Tenancy Mobile"
                                                customInput={TextField}
                                                type="tel"
                                                format="04########"
                                                allowEmptyFormatting
                                                fullWidth
                                                mask="_"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                value={formField.tenancy_mobile}
                                                onChange={(e) => { field.onChange(handleMobileChange(e)); checkFormChange(); }}
                                            />
                                        )}
                                    />

                                </>
                                {checkPermission('JOB', 'edit') &&
                                    <Controller
                                        control={control}
                                        name="service_duedate"
                                        defaultValue={formField.service_duedate}
                                        rules={{ required: 'Service Due Date is required' }}
                                        render={({ field, }) => {
                                            return (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        disablePast
                                                        label="Service Due Date *"
                                                        format="DD/MM/YYYY"
                                                        value={dayjs(formField.service_duedate)}
                                                        onChange={(newValue) => { field.onChange(newValue); setFormField((prev) => ({ ...prev, service_duedate: dayjs(newValue) })); checkFormChange(); }}
                                                        //readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}
                                                        onError={(newError) => setDateError(newError)}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                error: errors.service_duedate ? true : false,
                                                                helperText: dateError ? 'Enter/Choose service date' : errors.service_duedate?.message,

                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>);
                                        }}
                                    />
                                }

                                <Autocomplete
                                    sx={{ width: '100%' }}

                                    id="product_code"
                                    name="product_code"
                                    value={formField.product_code ? getProductSelectedItem(formField.product_code) : null}

                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setFormField((prev) => ({ ...prev, product_code: newValue.id }));
                                            // setProductCodeInput(newValue.label);
                                            checkFormChange();
                                        } else {
                                            setFormField((prev) => ({ ...prev, product_code: '' }));
                                            checkFormChange();
                                        }
                                    }}

                                    getOptionLabel={(option) => option.label ? option.label : ''}

                                    options={lookUpsOptions.PRODUCT}

                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.label}
                                        </Box>
                                    )}

                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Product *"
                                            {...register("product_code", { required: "Product is required" })}
                                            error={errors.product_code ? true : false}
                                            helperText={errors.product_code?.message}
                                        />
                                    )}

                                />

                                <NumericFormat
                                    customInput={TextField}
                                    sx={{ width: '100%' }}
                                    label="Credit"
                                    placeholder="Enter service charge in format 5.00 or 10.00"
                                    value={formField.credit ? formField.credit : 0.00}
                                    maxLength={10}
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    className="bg-white"
                                    onValueChange={(values, sourceInfo) => {
                                        //console.log(values);
                                        setFormField((prev) => ({ ...prev, credit: values.value }))
                                    }}
                                />


                            </div>
                            <div className="col-md-6">
                                <h5 className="mb-3" style={{ textAlign: 'left' }}>Address Info:</h5>
                                {formField.owner_id && (addressDetails.address1 || addressDetails.address2) && addressDetails.postcode && <FormControlLabel
                                    label="Same as Landlord Address"
                                    control={
                                        <Checkbox
                                            checked={isLLAddr}
                                            onChange={(e) => {
                                                setIsLLAddr(e.target.checked);
                                                if (e.target.checked) {
                                                    setValue('address1', addressDetails.address1);
                                                    setValue('suburb', addressDetails.suburb);
                                                } else {
                                                    setValue('address1', eAddressDetails.address1);
                                                    setValue('suburb', eAddressDetails.suburb);
                                                }
                                                clearErrors(['address1', 'suburb']);
                                                checkFormChange();
                                            }}
                                        />
                                    }
                                />}
                                <TextField
                                    fullWidth
                                    id="address1" name="address1" variant="outlined" error={errors.address1 ? true : false} label="Address 1 *"
                                    helperText={errors.address1?.message}

                                    inputProps={{ maxLength: 40, value: formField.address1 ? formField.address1 : '' }}

                                    {...register("address1", { required: "Address 1 is required" })}

                                    onChange={(e) => { setFormField((prev) => ({ ...prev, address1: e.target.value, changedFlag: 'Y' })); checkFormChange(); }}

                                />
                                <TextField
                                    fullWidth
                                    id="address2" name="address2" variant="outlined" error={errors.address2 ? true : false} label="Address 2"
                                    helperText={errors.address2?.message}

                                    inputProps={{ maxLength: 40, value: formField.address2 ? formField.address2 : '' }}

                                    {...register("address2", { required: false })}
                                    onChange={(e) => { setFormField((prev) => ({ ...prev, address2: e.target.value })); checkFormChange(); }}

                                />

                                <Autocomplete
                                    sx={{ width: '100%' }}

                                    id="suburb"
                                    name="suburb"
                                    value={pcodeValue ? pcodeValue : ''}

                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                            setFormField((prev) => ({ ...prev, suburb: newValue?.suburb, state: newValue?.state_shortname, postcode: newValue?.code }));

                                            //setPcodeInput(newValue.suburb);
                                            checkFormChange();
                                        } else {
                                            setPcodeValue({ code: '', suburb: '', state_shortname: '' });
                                            setFormField((prev) => ({ ...prev, suburb: '', state: '', postcode: '' }));
                                        }
                                    }}

                                    getOptionLabel={(option) => option.suburb ? option.suburb : ''}

                                    options={postcodeOptions}

                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>

                                            {option.suburb}, {option.state_shortname} - {option.code}.
                                        </Box>
                                    )}

                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Suburb *"
                                            error={errors.suburb ? true : false}
                                            helperText={errors.suburb?.message}
                                            {...register("suburb", { required: "Suburb is required" })}

                                        />
                                    )}

                                />

                                <TextField fullWidth id="state" name="state" variant="outlined" error={errors.state ? true : false} label="State" helperText={errors.state?.message} {...register("state", { required: false })} inputProps={{ maxLength: 4, value: formField.state, readOnly: true }} onChange={(e) => setFormField((prev) => ({ ...prev, state: e.target.value }))} />

                                <TextField
                                    fullWidth
                                    id="postcode" name="postcode"
                                    variant="outlined" error={errors.postcode ? true : false}
                                    label="Postcode"
                                    helperText={errors.postcode?.message}
                                    inputProps={{ maxLength: 4, value: formField.postcode, readOnly: true }}

                                    {...register("postcode", { required: false })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, postcode: e.target.value }))}
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="under-select-label"> Select property is under </InputLabel>
                                    <Select
                                        labelId="under-select-label"
                                        id="is_under"
                                        name="is_under"
                                        error={errors.is_under ? true : false}
                                        value={formField.is_under}
                                        label="Select property is under"
                                        {...register("is_under", { required: false })}
                                        onChange={(e) => { setFormField((prev) => ({ ...prev, is_under: e.target.value })); checkFormChange(); }}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value=''>None</MenuItem>
                                        <MenuItem value='company'>Company</MenuItem>
                                        <MenuItem value='superfund'>Superfund</MenuItem>
                                    </Select>
                                </FormControl>

                                {formField.is_under && <>
                                    <TextField
                                        fullWidth
                                        id="under_taken" name="under_taken" variant="outlined" error={errors.under_taken ? true : false} label="Under taken by"
                                        helperText={errors.under_taken?.message}

                                        inputProps={{ maxLength: 100, value: formField.under_taken }}

                                        {...register("under_taken", { required: formField.is_under ? 'Under taken by is required' : false })}

                                        onChange={(e) => { setFormField((prev) => ({ ...prev, under_taken: e.target.value })); checkFormChange(); }}

                                    />
                                </>}
                                {['SYS', 'MG', 'STAFF'].includes(_role) && <>
                                    <textarea
                                        id="cc_email"
                                        name="cc_email"
                                        rows={2}
                                        style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%', minHeight: '100px' }}
                                        placeholder="CC Email"
                                        {...register("cc_email", { required: false })}
                                        onChange={(e) => { setFormField((prev) => ({ ...prev, cc_email: e.target.value })); checkFormChange(); }}

                                        defaultValue={formField.cc_email ? formField.cc_email : ''}

                                    />
                                    <div className="text-light mb-3 text-sm">Format: example1@domain.com,example2@domain.com,example3@domain.com</div>
                                </>}

                                {(['SYS', 'MG', 'STAFF'].includes(_role) || formField.status !== 'P') && (
                                    <FormControl fullWidth>
                                        <InputLabel id="status"> Select Status </InputLabel>
                                        <Select
                                            labelId="status"
                                            id="status"
                                            name="status"
                                            error={errors.status ? true : false}
                                            value={formField.status}
                                            label="Select Status"
                                            {...register("status", { required: "Status is required" })}
                                            onChange={(e) => { setFormField((prev) => ({ ...prev, status: e.target.value })); checkFormChange() }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='P'>Pending</MenuItem>
                                            <MenuItem value='Y'>Active</MenuItem>
                                            <MenuItem value='R'>Ready to assign</MenuItem>
                                            <MenuItem value='N'>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                            <div className="col-md-12">
                                <div className="text-end">
                                    <FormControlLabel control={<Checkbox name="showalert"
                                        checked={showAlertCheck}
                                        onChange={(event) => { setShowAlertCheck(event.target.checked); setFormField((prev) => ({ ...prev, showalert: event.target.checked ? 'Y' : 'N' })); checkFormChange(); }} />}
                                        label="Show Alert" labelPlacement="start"

                                    />
                                </div>
                                <textarea
                                    id="alertnote"
                                    name="alertnote"
                                    rows={4}
                                    style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
                                    placeholder="Notes"

                                    {...register("alertnote", { required: false })}
                                    onChange={(e) => { setFormField((prev) => ({ ...prev, alertnote: e.target.value })); checkFormChange(); }}

                                    defaultValue={formField.alertnote ? formField.alertnote : ''}

                                />

                            </div>
                            <div className="col-md-12 mt-3 text-center">
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-lg-6 col-sm-2 col-xl-6 col-xxl-6'></div>
                                    <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end mb-3'>
                                        <Button type="button" className="themeholy-btn btn-secondary w-100" onClick={handleDialogClose}>Cancel</Button>

                                    </div>
                                    <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end'>
                                        <Button type="submit" ref={buttonRef} autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="text-center p-5 m-5"><CircularProgress /><br />Form is loading. Please wait...</div>}

                    {/*)*/}

                </div>
                <Dialog
                    open={submitConfirmOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleConfirmClose}
                >
                    <h1 className="text-theme text-center"><i className="fa fa-warning"></i> </h1>
                    <DialogTitle>
                        Are you sure you want to change product code to this property?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            If you change product code, the job created already for the current product code of this property will not be effective. The new job will be created to this property if not exist for the selected product code.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                        <Button type="submit" className="themeholy-btn btn-primary" onClick={handleFormSubmit}>Yes</Button>
                    </DialogActions>
                </Dialog>

            </Box>

            <UserFormContext.Provider
                value={{ userFormType, addDialogOpen, toggleAddDialog, dialogValue, setDialogValue, setUserOptions, setOwnerInput, setFormField, formField }}
            >

                <DialogUserForm />

            </UserFormContext.Provider>



        </Fragment >
    );


}