import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Alert, IconButton, Collapse, FormControl, InputLabel, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { axiosFunction, getSessionItem, clearSession, removeSessionItem } from '../../common';
import PasswordChecklist from "react-password-checklist"
import ninja32icon from '../../assets/img/ninja32.png';
import { useNavigate, Link } from 'react-router-dom';
import { CheckCircleOutline } from '@mui/icons-material';

const ChangePassword = ({ resetPassword, resetToken }) => {
    const navigate = useNavigate(null);
    const _redirect = getSessionItem("_redirect");
    const _role = getSessionItem("_role");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loader, setLoader] = useState(false);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [oldpassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [newpassins, setNewpassins] = useState('hide');
    const [confirmpassins, setConfirmpassins] = useState('hide');

    /*   //let inputRef;
      useEffect(() => {
          //if (inputRef) inputRef.focus();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); */

    const onSubmit = async (data) => {
        //console.log(data);
        try {
            setLoader(true);
            if (data.confirm_password) {
                data.force_password_reset = (_redirect === 'reset' || resetToken) ? 'Y' : 'N';
                data.resetToken = resetToken;
                const response = await axiosFunction('auth/changepassword', 'post', data);

                if (response.status === 'success') {
                    if (_redirect === 'reset') {
                        removeSessionItem('_redirect');
                        if (_role === 'AGENT') navigate('/land-lords');

                        else if (_role === 'LDLORD') navigate('/my-properties');

                        else navigate('/dashboard');
                    } else {
                        if (resetToken) {
                            setAlertOpen(true);
                            setAlertStatus('success');
                            setAlertMessage(<div className="container bg-white p-5" style={{ borderRadius: '10px' }}><div className='text-center'>
                                <CheckCircleOutline color='success' sx={{ fontSize: '5em', margin: 'auto' }} />
                            </div><p>{response.message}</p><p>Please wait a moment. You are redirecting to Login.</p><p>If not redirect in a moment, please <Link to="/login" className="text-yellow">click here</Link> to login.</p></div>);
                            handleAutoClose();
                        } else {
                            setAlertOpen(true);
                            setAlertStatus('success');
                            setAlertMessage(<>{response.message}</>);
                            handleAutoClose();
                            setLoader(false);
                        }
                        setOldPassword('');
                        setPassword('');
                        setPasswordAgain('');
                        setNewpassins('hide');
                        setConfirmpassins('hide');
                    }
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(<>{response.message} Please <Link to="/login" className="text-yellow">click here</Link> to login.</>);
                    setLoader(false);
                    document.querySelector('#change-password-form').scrollIntoView({ behavior: 'smooth' });
                }
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setAlertOpen(false);
            if (resetPassword === 'Y') {
                removeSessionItem('_redirect');
                removeSessionItem('_reset_password_token');
                navigate('/login');
            }
        }, 5000);
    };

    const handleClickShowOldPassword = () => {
        setShowOldPassword((show) => !show);
        if (!showOldPassword) setTimeout(() => setShowOldPassword(false), 2000);
    }
    const handleClickShowNewPassword = () => {
        setShowNewPassword((show) => !show);
        if (!showNewPassword) setTimeout(() => setShowNewPassword(false), 2000);
    }
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
        if (!showConfirmPassword) setTimeout(() => setShowConfirmPassword(false), 2000);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOldPasswd = (e) => {
        setOldPassword(e.target.value);
        if (e.target.value) {
            if (oldpassword === password) {

            }
        }
    }

    const handleNewPasswd = (e) => {
        setPassword(e.target.value);
        if (e.target.value) {
            setNewpassins('');
        } else {
            setNewpassins('hide');
        }
    }

    const handleConfirmPasswd = (e) => {
        setPasswordAgain(e.target.value)
        if (e.target.value) {
            setConfirmpassins('');
        } else {
            setConfirmpassins('hide');
        }
    }

    return (
        <>
            {resetToken && alertStatus === 'success' ? alertMessage : <>
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
                <Box component="form" id="change-password-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-6 m-auto">
                                {(!resetPassword || resetPassword === 'N') &&
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="old-password">Old Password *</InputLabel>
                                        <OutlinedInput
                                            id="old-password"
                                            type={showOldPassword ? 'text' : 'password'}
                                            {...register("old_password", { required: "Old Password is required" })}
                                            /* inputRef={input => {
                                                inputRef = input;
                                            }} */
                                            onChange={handleOldPasswd}
                                            inputProps={{ maxLength: 15, value: oldpassword, autoComplete: 'off' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowOldPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Old Password *"
                                            className="bg-white"
                                        />
                                        <label className="error text-red text-sm text-start">{errors.old_password?.message}</label>
                                    </FormControl>
                                }
                                {_redirect === 'reset' && <p className="text-smoke text-start">🚨 Safety Ninja is waiting for your next step and requires you to update your password in order to secure your data access! <img src={ninja32icon} alt="ninja32icon" width='20px' style={{ verticalAlign: 'baseline' }} /> To keep your ninja abilities sharp and unstoppable, don't let it escape — ⚔️reset your password!⚔️ 🚨</p>}
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="new-password">New Password *</InputLabel>
                                    <OutlinedInput
                                        id="new-password"
                                        type={showNewPassword ? 'text' : 'password'}
                                        {...register("new_password", {
                                            required: "New Password is required", validate: (value) => {
                                                return oldpassword !== value || "You used an old password. To protect your account, choose a new password.";
                                            }
                                        })}
                                        inputProps={{ maxLength: 15, value: password, autoComplete: 'off' }}
                                        error={errors.new_password ? true : false}
                                        onChange={handleNewPasswd}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password *"
                                        className="bg-white"
                                    />
                                    <label className="error text-red text-sm text-start">{errors.new_password?.message}</label>
                                </FormControl>
                                <PasswordChecklist fullWidth className={"text-start " + newpassins}
                                    rules={["capital", "specialChar", "number", "minLength"]}
                                    style={{ width: '35ch' }}
                                    iconSize={12}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    onChange={(isValid, failedRules) => { }}
                                    messages={{
                                        capital: "New Password has a capital letter.",
                                        specialChar: "New Password has special characters.",
                                        number: "New Password has a number.",
                                        minLength: "New Password has 8 caracteres."
                                    }}
                                />
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="confirm-password">Confirm Password *</InputLabel>
                                    <OutlinedInput
                                        id="confirm-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        {...register("confirm_password", {
                                            required: "Confirm Password is required", validate: (value) => {
                                                return password === value || "New Password and Confirm Password should be the same!";
                                            }
                                        })}
                                        inputProps={{ maxLength: 15, value: passwordAgain, autoComplete: 'off' }}
                                        error={errors.confirm_password ? true : false}
                                        onChange={handleConfirmPasswd}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password *"
                                        className="bg-white"
                                    />
                                    <label className="error text-red text-sm text-start">{errors.confirm_password?.message}</label>
                                </FormControl>

                                <PasswordChecklist fullWidth className={"text-start " + confirmpassins}
                                    rules={["match"]}
                                    iconSize={12}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    messages={{
                                        match: "New Password has match with Confirm Password.",
                                    }}
                                />
                            </div>
                        </div>
                        <Button type="submit" className="themeholy-btn btn-primary btn-fw" disabled={loader ? true : false}>{(loader) ? 'Processing...' : (resetPassword === 'Y' ? 'Submit' : 'Change Password')}</Button>
                    </div>
                </Box>
            </>}
        </>
    );
}
export default ChangePassword;