import { useState, useEffect, useContext, forwardRef, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, TextField, Alert, IconButton, Collapse, Autocomplete, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { axiosFunction, getSessionItem, clearSession, setSessionItem } from '../../common';
import { AccountContext } from "../../pages/MyAccount";
import { useNavigate } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';

//const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Placeholder = () => {
    return (
        <div className="row">
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>

            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
            <div className="col-md-12 col-lg-6"><span className="skeleton-box" style={{ width: '100%', height: '40px' }}></span></div>
        </div >
    )
}

const Profile = () => {
    const _role = getSessionItem("_role");
    let _profile_id = getSessionItem("_profile_id");
    const _is_landlord = getSessionItem("_is_landlord");
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [currentVal, setCurrentVal] = useState({ 'email': '', 'mobile': '' });
    const buttonRef = useRef();
    const formRef = useRef();
    const [formField, setFormField] = useState({
        prefix: "",
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        address1: "",
        address2: "",
        suburb: "",
        state: "",
        postcode: "",
        agents: []
    });
    const { register, control, handleSubmit, formState: { errors }, reset } = useForm();
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(false);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const { setBackDropOpen } = useContext(AccountContext);
    const [pcodeValue, setPcodeValue] = useState([]);
    const [showProfileForm, setShowProfileForm] = useState(false);
    const [prefixOptions, setPrefixOptions] = useState(null);
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    // const [userOptions, setUserOptions] = useState(null);
    //let inputRef;

    const loadData = async () => {
        try {
            const prefixDataList = await axiosFunction('auth/prefix', 'get');
            const postCodeDataList = await axiosFunction('auth/postcodes', 'get', {});
            //const userDataList = await axiosFunction('auth/users', 'post', {});
            if (prefixDataList.status !== 'success') {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(prefixDataList.message);
                setBackDropOpen(false);
                /* if (prefixDataList?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                } */
            }

            if (prefixDataList.status === 'success' && postCodeDataList.status === 'success') {
                setPrefixOptions(prefixDataList.data);
                //setUserOptions(userDataList.data);
                setPostcodeOptions(postCodeDataList.data);
                setShowProfileForm(true);
            }
            try {
                const response = await axiosFunction('individuals/' + _profile_id, 'get');
                if (response.status === 'success') {
                    response.data.email = response.data.individualuser.email;
                    response.data.mobile = response.data.individualuser.mobile;
                    setFormField(response.data);
                    setCurrentVal({ 'email': response.data.individualuser.email, 'mobile': response.data.individualuser.mobile })
                    setPcodeValue({ code: response.data.postcode, suburb: response.data.suburb, state_shortname: response.data.state });
                    reset(response.data);
                    setBackDropOpen(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(response.message);
                    setBackDropOpen(false);
                    /* if (response?.response?.status === 401) {
                        clearSession();
                        navigate('/login');
                    } */
                }
            } catch (e) {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(e.message);
                setBackDropOpen(false);
                if (e?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                }
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            if (e?.response?.status === 401) {
                clearSession();
                navigate('/login');
            }
        }
    }
    useEffect(() => {
        setBackDropOpen(true);
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //console.log(prefixOptions);

    const getSelectedItem = (prefix) => {
        // eslint-disable-next-line
        const item = prefixOptions.find((opt) => {
            if (opt && opt.description === prefix)
                return opt;
        })
        return item || null;
    }

    /* const getSelectedAgents = (agents) => {
        // return  [agentData[0], agentData[5]];
        let agentItems = [];
        if (agents) {
            let i = 0;
            // eslint-disable-next-line   
            userOptions.filter(a => a.code === "AGENT").find((opt) => {
                // eslint-disable-next-line 
                agents.map((item) => {
                    if (opt.id === item) {
                        agentItems[i] = opt;
                        i++;
                    }
                });
            });
        }
        //console.log(agentItems);

        return agentItems || [];
    } */

    const onSubmit = async (data) => {
        // console.log(data);       
        //console.log(confirmSubmit);
        if (data.email && (confirmSubmit || (!confirmSubmit && (currentVal.email === data.email && currentVal.mobile === data.mobile)))) {
            try {
                setLoader(true);
                setConfirmOpen(false);
                let response = null;

                if (_is_landlord === 'Y') data.agents = formField.agents;

                if (_profile_id) { //First time
                    data._method = 'PUT';
                    response = await axiosFunction('individuals/' + _profile_id, 'post', data);
                } else {
                    response = await axiosFunction('individuals', 'post', data);
                }

                setAlertOpen(true);
                if (response.status === 'success') {
                    if (!_profile_id) {
                        setSessionItem('_profile_id', response.item.id);
                        _profile_id = response.item.id;
                    }
                    setCurrentVal({ 'email': response.item.email, 'mobile': response.item.mobile })
                    setAlertStatus('success');
                    setAlertMessage(response.message);
                    handleAutoClose();
                    setLoader(false);
                    setConfirmSubmit(false);
                } else {
                    setAlertStatus('error');
                    setAlertMessage(response.message);
                    setLoader(false);
                    setConfirmSubmit(false);
                    document.querySelector('#profile-form').scrollIntoView({ behavior: 'smooth' });
                    /* if (response?.response?.status === 401) {
                        clearSession();
                        navigate('/login');
                    } */
                }
            } catch (e) {
                setAlertStatus('error');
                setAlertMessage(e.message);
                setLoader(false);
                setConfirmSubmit(false);
                document.querySelector('#profile-form').scrollIntoView({ behavior: 'smooth' });
                if (e?.response?.status === 401) {
                    clearSession();
                    navigate('/login');
                }
            }
        } else if (!confirmSubmit) {
            setConfirmOpen(true);
        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }

    const handleConfirmClose = () => {
        setFormField((prev) => ({ ...prev, email: currentVal.email, mobile: currentVal.mobile }));
        setConfirmOpen(false);
    };

    const handleFormSubmit = () => {
        setConfirmSubmit(true);
        setTimeout(() => {
            buttonRef?.current?.click();
        }, 500);
    }

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            {showProfileForm ? (
                <Box ref={formRef} component="form" id="profile-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <h5 className="mb-3" style={{ textAlign: 'left' }}>Personal Info:</h5>
                                <Controller
                                    name="prefix"
                                    control={control}
                                    rules={{
                                        required: "Prefix is required"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            id="prefix"
                                            options={prefixOptions}
                                            getOptionLabel={(option) => option.description}

                                            sx={{ width: '100%' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.description}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                            renderInput={(params) => <TextField {...params} label="Prefix" variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            /* inputRef={input => {
                                                inputRef = input;
                                            }} */
                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.description : '')
                                                setFormField((prev) => ({ ...prev, prefix: data ? data.description : '' }))
                                            }}

                                            value={getSelectedItem(formField.prefix)}
                                        />
                                    )}
                                />

                                <TextField fullWidth id="firstname" name="firstname" variant="outlined" error={errors.firstname ? true : false} label="First Name *" helperText={errors.firstname?.message} {...register("firstname", { required: "First Name is required" })} inputProps={{ maxLength: 40, value: formField.firstname ? formField.firstname : '' }} onChange={(e) => { setFormField((prev) => ({ ...prev, firstname: e.target.value })) }} />

                                <TextField fullWidth id="lastname" name="lastname" variant="outlined" error={errors.lastname ? true : false} label="Last Name *" helperText={errors.lastname?.message} {...register("lastname", { required: "Last Name is required" })} inputProps={{ maxLength: 40, value: formField.lastname ? formField.lastname : '' }} onChange={(e) => { setFormField((prev) => ({ ...prev, lastname: e.target.value })) }} />

                                <TextField fullWidth id="email" name="email" variant="outlined" error={errors.email ? true : false} label="Email Address *" helperText={errors.email?.message} {...register("email", {
                                    required: "Email Address is required", pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    }
                                })} inputProps={{ maxLength: 40, value: formField.email ? formField.email : '' }} onChange={(e) => { setFormField((prev) => ({ ...prev, email: e.target.value })) }} />

                                <Controller
                                    name="mobile"
                                    control={control}

                                    rules={{
                                        required: "A valid mobile number is required",
                                        pattern: {
                                            value: /^04\d{8}$/,
                                            message: 'Please enter a valid mobile number',
                                        }
                                    }}

                                    render={({ field, fieldState }) => (

                                        <PatternFormat
                                            label="Mobile *"
                                            customInput={TextField}
                                            type="tel"
                                            format="04########"
                                            allowEmptyFormatting
                                            fullWidth
                                            mask="_"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            value={formField.mobile ? formField.mobile : ''}
                                            onChange={(e) => { field.onChange(handleMobileChange(e)) }}
                                        />
                                    )}
                                />

                                {/* _is_landlord === 'Y' && (
                                    <Controller
                                        render={(props) => (
                                            <Autocomplete
                                                multiple
                                                disableClearable
                                                disableCloseOnSelect
                                                options={userOptions.filter(a => a.code === "AGENT")}
                                                getOptionLabel={(option) => option.label}
                                                value={getSelectedAgents(formField.agents)}
                                                // filterOptions={filterOptions}
                                                renderOption={(args, option, { selected }) => (
                                                    <Box component="li" {...args} key={option.id}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderTags={(tagValue, getTagProps) => {
                                                    return tagValue.map((option, index) => (
                                                        <Chip {...getTagProps({ index })} key={option.id} label={option.label} />
                                                    ))
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Agents of my properties"
                                                        variant="outlined"
                                                        placeholder="choose agents"
                                                    />
                                                )}
                                                onChange={(_, data) => {
                                                    props.field.onChange(data.id);
                                                    let agentArr = [];
                                                    data.map((item, index) => agentArr[index] = item.id);
                                                    //console.log(agentArr);
                                                    setFormField((prev) => ({ ...prev, agents: agentArr }));
                                                }}

                                            />
                                        )}


                                        name="agents[]"
                                        control={control}
                                    />
                                ) */}
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <h5 className="mb-3" style={{ textAlign: 'left' }}>Address Info:</h5>
                                {_role === 'AGENT' && (<>
                                    <TextField
                                        fullWidth
                                        id="agency_name" name="agency_name" variant="outlined" error={errors.agency_name ? true : false} label="My Agency Name *"
                                        helperText={errors.agency_name?.message}

                                        inputProps={{ maxLength: 100, value: formField.agency_name ? formField.agency_name : '' }}

                                        {...register("agency_name", { required: "Agency Name is required" })}

                                        onChange={(e) => setFormField((prev) => ({ ...prev, agency_name: e.target.value }))}

                                    />
                                </>)}
                                <TextField fullWidth id="address1" name="address1" variant="outlined" error={errors.address1 ? true : false} label="Address 1 *" helperText={errors.address1?.message} {...register("address1", { required: "Address 1 is required" })} inputProps={{ maxLength: 40, value: formField.address1 ? formField.address1 : '' }} onChange={(e) => { setFormField((prev) => ({ ...prev, address1: e.target.value })) }} />
                                <TextField fullWidth id="address2" name="address2" variant="outlined" label="Address 2" inputProps={{ maxLength: 40, value: (formField.address2 ? formField.address2 : '') }} {...register("address2")} onChange={(e) => { setFormField((prev) => ({ ...prev, address2: e.target.value })) }} />
                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    id="suburb"
                                    name="suburb"
                                    value={pcodeValue}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                            setFormField((prev) => ({ ...prev, suburb: newValue?.suburb, postcode: newValue?.code, state: newValue?.state_shortname }));
                                            //setPcodeInput(newValue.suburb);
                                        } else {
                                            setPcodeValue({ code: '', suburb: '', state_shortname: '' });
                                            setFormField((prev) => ({ ...prev, suburb: '', state: '', postcode: '' }));
                                        }
                                    }}
                                    /* inputValue={pcodeInput}
                                    onInputChange={(event, newInputValue, eventType) => {
                                        if (eventType === 'reset') {
                                            setPcodeInput(pcodeInput);
                                        } else {
                                            setPcodeInput(newInputValue);
                                        }
                                    }} */
                                    getOptionLabel={(option) => option.suburb ? option.suburb : ''}
                                    options={postcodeOptions}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.suburb} - {option.code}, {option.state_shortname}.
                                        </Box>
                                    )}
                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Suburb *"
                                            error={errors.suburb ? true : false}
                                            helperText={errors.suburb?.message}
                                            {...register("suburb", { required: "Suburb is required" })}
                                        />
                                    )}
                                />
                                <TextField fullWidth id="state" name="state" variant="outlined" error={errors.state ? true : false} label="State *" helperText={errors.state?.message} {...register("state", { required: "State is required" })} inputProps={{ maxLength: 4, value: formField.state ? formField.state : '' }} onChange={(e) => { setFormField((prev) => ({ ...prev, state: e.target.value })) }} />
                                <TextField fullWidth id="postcode" name="postcode" variant="outlined" error={errors.postcode ? true : false} label="Postcode *" helperText={errors.postcode?.message} {...register("postcode", { required: "Postal Code is required" })} inputProps={{ maxLength: 4, value: formField.postcode ? formField.postcode : '' }} onChange={(e) => { setFormField((prev) => ({ ...prev, postcode: e.target.value })) }} />
                            </div>
                        </div>
                        <Button type="submit" ref={buttonRef} className="themeholy-btn btn-primary" variant="contained" disabled={loader ? true : false}>{(loader) ? 'Updating...' : 'Update Profile'}</Button>
                    </div>
                    <Dialog
                        open={confirmOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleConfirmClose}
                    >
                        <h1 className="text-theme text-center"><i className="fa fa-warning"></i> </h1>
                        <DialogTitle>
                            Are you sure you want to change email address/mobile number?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                If you change your email address/mobile number, the new email address/mobile number will be used for upcoming login and communication of this system instead the current email address/mobile number as this will no longer exist. Do you still want to change email address/mobile number.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                            <Button type="submit" className="themeholy-btn btn-primary" onClick={handleFormSubmit}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            ) : <Placeholder />}
        </>
    );
}
export default Profile;