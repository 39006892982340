import { useState, useEffect } from 'react';

function Footer() {
    const [showScroll, setScroll] = useState('');

    useEffect(() => {
        window.addEventListener("scroll", (function () {
            this.scrollY > 50 ? setScroll('show') : setScroll('');
            var s = document.querySelector(".scroll-top path"),
                a = s ? s.getTotalLength() : 0,
                y = window.scrollY,
                o = document.body.scrollHeight - window.innerHeight;
            if (s) s.style.strokeDashoffset = a - y * a / o;
        }))

        var t = document.querySelector(".scroll-top");

        t.addEventListener("click", (function (e) {
            window.scrollTo(0, 0);
        }));
    }, []);


    return (
        <>
            <footer className="footer-wrapper">

                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-12 text-center">
                                <p className="copyright-text">Copyright <i className="fal fa-copyright"></i>{(new Date().getFullYear())} GeRam Data Solutions Pty Ltd. All Rights Reserved.</p>
                            </div>

                        </div>
                    </div>
                </div>

            </footer>
            <div className={"scroll-top " + showScroll}>
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{ 'transition': 'stroke-dashoffset 10ms linear 0s', 'strokeDasharray': '307.919, 307.919', 'strokeDashoffset': '307.919' }}></path>
                </svg>
            </div>
        </>
    );
}

export default Footer;